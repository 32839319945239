import React from "react";
import { FC } from 'react';
import { Modal } from 'antd';
import TranscriptionSpeakersTableComponent from "./TranscriptionSpeakersTableComponent";

import {
    Edition,
    Speaker,
} from "./../types/index";


type SpeakersListModalProps = {
    edition: Edition | undefined,
    title: string | undefined,
    transcriptionId: number,

    speakers: Speaker[],
    visible: boolean,
    onOk: () => void,
    onCancel: () => void
    createSpeaker: (speaker: Speaker, onSuccess: () => void) => void
    handleUpdateSpeakerForRegions: (
        transcription_id: number,
        speaker_id: number | undefined,
        edition_id: number[] | undefined,
    ) => void
}

export const SpeakersListModal: FC<SpeakersListModalProps> = ({
    edition,
    title,
    transcriptionId,
    speakers,
    visible,
    onOk,
    onCancel,
    createSpeaker,
    handleUpdateSpeakerForRegions,
}) => {

    return (
        <Modal
            style={{ top: 20 }}
            closable={true}
            visible={visible}
            title={`Speakers para tx: ${transcriptionId}`}
            onOk={onOk}
            onCancel={onCancel}
            footer={[]}
            width={800}
        >
            <TranscriptionSpeakersTableComponent
                speakers={speakers}
                currentRegionSpeakerId={edition?.speaker_id}
                transcription_id={transcriptionId}
                regionId={edition?.id}
                handleUpdateSpeakerForRegions={handleUpdateSpeakerForRegions}
                createSpeaker={createSpeaker}
            />
        </Modal >
    );
}

// function areEqual(previousProps: SpeakersListModalProps, nextProps: SpeakersListModalProps) {
//     return previousProps.edition === nextProps.edition &&
//         previousProps.speakers === nextProps.speakers &&
//         previousProps.visible === nextProps.visible &&
//         previousProps.speakers === nextProps.speakers &&
//         previousProps.transcriptionId === nextProps.transcriptionId
// }

export default SpeakersListModal;
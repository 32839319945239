import React from "react";

import { Table, Button } from "antd";
import { DownloadOutlined } from '@ant-design/icons';

import { UserTranscriptionsList, PendingFile, Project } from "../types";
import UserTranscriptionJobsClient from "./UserTranscriptionJobsClient";
import UserTranscriptionJobsAdmin from "./UserTranscriptionJobsAdmin";
import UploadComponent from "./UploadComponent"

import "./UserTranscriptionJobsAdmin.css";

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { Strings } from "../strings";

type UserTranscriptionsProjectsProps = {
    userTranscriptionsList: UserTranscriptionsList,
    isAdmin: boolean;
    canProcessFiles: boolean;
    handlGetBatchTranscriptionJobs: () => void;
    handleDeleteBatchTranscriptionJob: (id: number) => void;
    handleTranscriptionClick: (id: number) => void;
    handleTranscriptionUsersClick: (id: string) => void;
    handleProjectUsersClick: (id: number) => void;
    handleDownloadAllProjectDocx: (id: number, filename: string) => void;
    handleUpdateProjectStatus: (projectStatusTypeId: number, note: string, transcriptionId: number) => void;
    handleChangeTranscriptionProject: (transcriptionId: number, projectId: number) => void;
    handleProcessItem: (item: PendingFile, withAsr: boolean) => void;
};

const UserTranscriptionsProjects: React.FC<UserTranscriptionsProjectsProps> = props => {


    const dataSource: any = [...props.userTranscriptionsList.projects]
        .sort((a: Project, b: Project) => {
            return b.id - a.id
        })
        .map((project) => {
            return ({
                key: project.id,
                id: project.id,
                name: project.name,
                transcriptions: project.transcriptions,
                configure: project.id,
                downloadAll: project,
            })
        })
    const columns: any = [
        {
            width: 80,
            title: Strings.strings.id,
            dataIndex: "id",
            key: "id",
            sorter: (a: Project, b: Project) => { return b.id - a.id },
        },
        {
            title: "",
            dataIndex: "configure",
            key: "configure",
            visible: props.isAdmin,
            width: 130,
            render: (projectId: number) => (
                props.isAdmin &&
                <Button
                    style={{ background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR }}
                    type="primary"
                    size="small"
                    onClick={() => props.handleProjectUsersClick(projectId)}
                >
                    Configuración
                </Button>
            )
        },
        {
            title: Strings.strings.name,
            dataIndex: "name",
            key: "name",
            sorter: (a: Project, b: Project) => a.name.localeCompare(b.name)
        },
        {
            title: Strings.strings.download,
            dataIndex: "downloadAll",
            key: "downloadAll",
            render: (project: Project) => {
                let filename = `${project.id}_${project.name}`
                return (
                    <Button
                        style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                        type="primary"
                        size="small"
                        onClick={() => props.handleDownloadAllProjectDocx(project.id, filename)}
                    >
                        <DownloadOutlined /> {Strings.strings.downloadAllDocx}
                    </Button>
                )
            }
        },
    ].filter(e => e.visible !== false);

    return (
        <div>
            <Table
                pagination={{ position: ['bottomLeft'], defaultPageSize: 100 }}
                dataSource={dataSource}
                columns={columns}
                size="small"
                scroll={{ "x": true }}
                expandable={{
                    expandedRowRender: project => {
                        const filteredArray = (props.userTranscriptionsList.batch_jobs ?? []).filter(batchJob => project.transcriptions.includes(batchJob.transcription_id));
                        if (props.canProcessFiles) {
                            return (
                                <div>

                                    <UploadComponent handleProcessItem={props.handleProcessItem} project_id={project.id} />

                                    <UserTranscriptionJobsAdmin
                                        isAdmin={props.isAdmin}
                                        handleDeleteBatchTranscriptionJob={props.handleDeleteBatchTranscriptionJob}
                                        handleTranscriptionClick={props.handleTranscriptionClick}
                                        handleTranscriptionUsersClick={props.handleTranscriptionUsersClick}
                                        projectStatusTypes={props.userTranscriptionsList.project_status_types ?? []}
                                        userTranscriptionsList={Object.assign({}, props.userTranscriptionsList, {
                                            batch_jobs: filteredArray
                                        })}
                                        handleUpdateProjectStatus={props.handleUpdateProjectStatus}
                                        handleChangeTranscriptionProject={props.handleChangeTranscriptionProject}
                                    />
                                </div>
                            )
                        } else {
                            return (

                                <UserTranscriptionJobsClient
                                    batchJobs={filteredArray ?? []}
                                    handlGetBatchTranscriptionJobs={props.handlGetBatchTranscriptionJobs}
                                    handleTranscriptionClick={props.handleTranscriptionClick}
                                    handleTranscriptionUsersClick={props.handleTranscriptionUsersClick}

                                />

                            )
                        }
                    },
                    rowExpandable: record => record.name !== "Not Expandable"
                }}
            />
        </div>
    );

}

export default UserTranscriptionsProjects;
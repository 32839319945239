import React, { Ref } from "react";

import TextEditorRow from "./TextEditorRow"

import {
    Edition,
    Speaker
} from "./../types/index";

export type TextEditorProps = {
    editorRef: Ref<HTMLDivElement>,
    editions: Edition[],
    editorScrollRef: Ref<HTMLDivElement>,
    activeWordRef: Ref<HTMLDivElement>;
    activeWord: Edition | null,
    speakers: Speaker[],
    editionIdsSaving: number[]

    editorToolsEnabled: boolean,
    transcriptionId: number,
    editingEnabled: boolean,
    showFindAndReplace: boolean,
    itemsForFindAndReplace: number[],
    handleAddOrCreateEdition: (edition: Edition) => void,
    handleSaveWordEdition: (edition: Edition) => void,
    handleOnFocusTextEditorItem: (word: Edition) => void,
    handleSplitEdition: (splitIndex: number, edition: Edition) => void,
    onMouseUp: (word: Edition) => void,
    onBlur: (e: any) => void,
    handleInsertNewEdition: (edition: Edition) => void;
    handleDeleteEdition: (edition: Edition) => void;
    handleMergeEdition: (edition: Edition) => void;
    handleGoToWord: (edition: Edition) => void;
    handleShowSpeakers: (edition: Edition) => void;
    handleAddEditionsForFindAndReplace: (editionIds: number[]) => void
    handleRemoveEditionsForFindAndReplace: (editionIds: number[]) => void
};

const TextEditorBody: React.FC<TextEditorProps> = props => {

    const editorRows = (
        props.editions.map(edition => {
            let speaker = props.speakers.find(speaker => edition.speaker_id === speaker.id);

            let editionIsSaving = false
            if (edition.id) {
                editionIsSaving = props.editionIdsSaving.includes(edition.id)
            }

            let includeForRename = false
            if (edition.id) {
                includeForRename = props.itemsForFindAndReplace.indexOf(edition.id) > -1
            }
            return <TextEditorRow
                key={edition.id}
                activeWordRef={props.activeWordRef}
                edition={edition}
                isActiveWord={props.activeWord?.id === edition.id}
                speaker={speaker}
                speakers={props.speakers}
                editorToolsEnabled={props.editorToolsEnabled}
                transcriptionId={props.transcriptionId}
                editingEnabled={props.editingEnabled}
                showFindAndReplace={props.showFindAndReplace}
                handleAddOrCreateEdition={props.handleAddOrCreateEdition}
                includeForFindAndReplace={includeForRename}
                handleSaveEdition={props.handleSaveWordEdition}
                handleOnFocusTextEditorItem={props.handleOnFocusTextEditorItem}
                handleSplitEdition={props.handleSplitEdition}
                onMouseUp={props.onMouseUp}
                handleInsertNewEdition={props.handleInsertNewEdition}
                handleDeleteEdition={props.handleDeleteEdition}
                handleMergeEdition={props.handleMergeEdition}
                handleGoToWord={props.handleGoToWord}
                handleShowSpeakers={props.handleShowSpeakers}
                isSaving={editionIsSaving}
                handleAddEditionsForFindAndReplace={props.handleAddEditionsForFindAndReplace}
                handleRemoveEditionsForFindAndReplace={props.handleRemoveEditionsForFindAndReplace}
            />

        })
    )

    return (
        <div
            style={{
                height: "10%",
                borderRadius: "3px",
                // width: "auto"
            }}
        >
            <div
                style={{
                    height: "calc(100vh - 220px)",
                    position: "relative",
                    width: "800px",
                }}
            >
                <div>
                    <div className="editorGutterBackground"></div>
                    <div className="editorBackground"></div>
                </div>

                <div
                    className="editor"
                    key="textEditorAreaContainer"
                    ref={props.editorScrollRef}
                    style={{
                        overflow: "scroll"
                    }}
                >
                    <div
                        className="textEditorTextArea"
                        ref={props.editorRef}
                        key="textEditorArea"
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                        placeholder="Esperando..."
                        onBlur={props.onBlur}
                        style={{ display: "table" }}
                    >
                        {editorRows}
                    </div>
                </div>
            </div>
        </div>
    );
};


function shouldUpdate(previousProps: TextEditorProps, nextProps: TextEditorProps) {

    return previousProps.editorRef === nextProps.editorRef &&
        previousProps.editions === nextProps.editions &&
        previousProps.editorScrollRef === nextProps.editorScrollRef &&
        previousProps.activeWordRef === nextProps.activeWordRef &&
        previousProps.activeWord === nextProps.activeWord &&
        previousProps.speakers === nextProps.speakers &&
        previousProps.editionIdsSaving === nextProps.editionIdsSaving &&
        previousProps.showFindAndReplace === nextProps.showFindAndReplace &&
        previousProps.itemsForFindAndReplace === nextProps.itemsForFindAndReplace
}

export default React.memo(TextEditorBody, shouldUpdate)
import React, { createRef } from "react";
import { Redirect } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { Button, Progress, Menu, Dropdown, Skeleton } from "antd";

import { DownloadOutlined, UploadOutlined, DownOutlined, CheckCircleOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';

import FetchAudioHelper from '../helpers/FetchAudioHelper';

import ShortCutKeysModal from '../components/ShortCutKeysModal';
import SpeakersListModal from '../components/SpeakersListModal';
import EditTranscriptionDocsDetailsModal from '../components/EditTranscriptionDocsDetailsModal';
import TranscriptionInfoModal from '../components/TranscriptionInfoModal';

import { UserProfile } from "../types/index";

import { sessionService } from "../services";
import { store } from '../app/store'
import { updateUserProfile } from "../features/UserProfileSlice"

import {
    Transcription,
    Edition,
    Speaker,
    PeaksSegment,
    WaveFormData,
    FindAndReplaceInEditionsRequest,
    UserMarkTxAsDone,
    TemporaryAudioAndWaveformLinks
} from "../types/index";

import {
    AutoScrollState,
    AudioLoadingState,
} from "../types/enums";


import { PeaksComponent } from "../components/PeaksComponent";
import { TextEditor } from "../components/TextEditor";
import { TranscriptionWebsocket } from "../components/TranscriptionWebsocket";
import { UploadSRTFile } from "../components/UploadSRTFile";
import { UploadSMJson } from "../components/UploadSMJson";
import { UploadTPJson } from "../components/UploadTPJson";
import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";

import API from "../api/API";

import "./TranscriptionReview.css";
import { Strings } from "../strings";

type TranscriptionReviewProps = {
};

type TranscriptionReviewState = {
    playing: boolean;
    currentTime: number;
    duration: number;
    currentZoom: number;
    autoCenter: boolean;
    transcription?: Transcription;
    transcriptionId: number;
    sortedEditions: Edition[]
    activeWord: Edition | null;
    shouldJumpToActiveWord: boolean;
    audioSourceUrl: string | null;
    waveformSourceUrl: string | null;
    partial: string;
    transcriptionMetaDataModalIsVisible: boolean;
    shortcutsModalVisible: boolean;
    showNotes: boolean;
    peaksSegments: PeaksSegment[];
    readyForClient: boolean;
    userCanEdit: boolean;
    showImportSRTModal: boolean;
    showImportSMModal: boolean;
    showImportTPModal: boolean;
    autoScrollState: AutoScrollState;
    editorToolsEnabled: boolean;
    audioLoadingState: AudioLoadingState;
    audioBlob?: Blob;
    audioPeakdata?: WaveFormData;
    currentAudioDownloadState: number;
    currentWaveformDownloadState: number;
    currentTranscriptionDownloadState: number;
    speakersListVisible: boolean;
    editionsForSpeakerUpdate: Edition | undefined;
    editionIdsSaving: number[];
    showFindAndReplace: boolean;
    editionsSelectedForFindAndReplace: Set<number>
    currentSearchTerm?: string;
    userProfile?: UserProfile;
    redirect: string | null;
    isBufferingAudio: boolean
};

class TranscriptionReview extends React.Component<
    TranscriptionReviewProps,
    TranscriptionReviewState
> {
    currentWordInput = createRef<HTMLTextAreaElement>();

    peaksComponentRef = createRef<PeaksComponent>();
    textEditorRef = createRef<TextEditor>();

    ws: TranscriptionWebsocket | null = null;

    shouldScrollActiveWordIntoView = false;

    activeWordIsEditing = false;

    rawEditionsFromApi: Edition[] = []

    constructor(props: TranscriptionReviewProps) {
        super(props);

        //@ts-ignore
        const id = parseInt(this.props.computedMatch.params.id, 10);

        this.state = {
            playing: false,
            currentTime: 0,
            duration: 0,
            currentZoom: 100,
            autoCenter: true,
            transcription: undefined,
            transcriptionId: id,
            sortedEditions: [],
            activeWord: null,
            shouldJumpToActiveWord: false,
            audioSourceUrl: null,
            waveformSourceUrl: null,
            partial: "",
            shortcutsModalVisible: false,
            transcriptionMetaDataModalIsVisible: false,
            showNotes: false,
            peaksSegments: [],
            readyForClient: false,
            userCanEdit: false,
            showImportSRTModal: false,
            showImportSMModal: false,
            showImportTPModal: false,
            autoScrollState: AutoScrollState.scrollToActive,
            editorToolsEnabled: false,
            audioLoadingState: AudioLoadingState.waiting,
            audioBlob: undefined,
            audioPeakdata: undefined,
            currentAudioDownloadState: 0,
            currentWaveformDownloadState: 0,
            currentTranscriptionDownloadState: 0,
            speakersListVisible: false,
            editionsForSpeakerUpdate: undefined,
            editionIdsSaving: [],
            showFindAndReplace: false,
            editionsSelectedForFindAndReplace: new Set<number>(),
            userProfile: undefined,
            redirect: null,
            isBufferingAudio: false
        };

        this.rawEditionsFromApi = []
    }


    componentDidMount = async () => {

        // window.onbeforeunload = (e: BeforeUnloadEvent) => {
        //     console.log('Stop this');
        //     e.preventDefault()
        //     e.returnValue = '';
        // };
        window.onkeydown = this.handleKeydown;

        await this.initialLoad()
    }

    componentDidUpdate = async (previousProps: TranscriptionReviewProps, previousState: TranscriptionReviewState) => {
        if (previousState.readyForClient !== this.state.readyForClient) {
            if (this.state.readyForClient) {
                if (this.state.audioLoadingState === AudioLoadingState.waiting) {
                    // await this.loadAudio();
                }
            }
        }
    }

    sortEnabledTranscriptionEditions = () => {
        if (this.state.transcription !== undefined) {

            let filteredEditionIdentifiers: { [index: string]: Edition[] } = {};

            this.rawEditionsFromApi
                .filter(edition => (
                    edition.editionidentifier_id !== null) &&
                    edition.is_normalised_sentence === true)
                .forEach(edition => {
                    if (edition.editionidentifier_id) {
                        if (filteredEditionIdentifiers[edition.editionidentifier_id] === undefined) {
                            filteredEditionIdentifiers[edition.editionidentifier_id] = []
                        }
                        filteredEditionIdentifiers[edition.editionidentifier_id].push(edition)
                    }
                })

            // sort based on ID
            let sortedEditions: Edition[] = Object.keys(filteredEditionIdentifiers).map((key) => {
                return filteredEditionIdentifiers[key]
                    .filter(edition => edition.time_updated !== undefined)
                    .reduce((lhs, rhs) => {
                        if (lhs.time_updated !== undefined && rhs.time_updated !== undefined) {
                            let lhsTime = new Date(lhs.time_updated);
                            let rhsTime = new Date(rhs.time_updated);
                            return (lhsTime > rhsTime) ? lhs : rhs
                        }
                        // if (lhs.id !== undefined && rhs.id !== undefined) {
                        //     return lhs > rhs ? lhs : rhs
                        // }
                        return lhs
                    })
            })
                .filter(edition => edition.enabled === true)
                .sort((a, b) => a.time - b.time)
                .map((edition, index, thisArray) => {
                    edition.speakerMatchesPreviousItem = false
                    if (index > 0) {
                        if (thisArray[index - 1].speaker_id === edition.speaker_id) {
                            edition.speakerMatchesPreviousItem = true
                        }
                    }
                    edition.time = this.roundFloat(edition.time)
                    edition.duration = this.roundFloat(edition.duration ?? 0)
                    return edition
                })

            let peaksSegments = sortedEditions.map((word) => {
                let start = Number(word.time);
                let end = Number(word.time) + Number(word.duration);
                end = end > start ? end : start + Number(0.1);

                let speaker = this.state.transcription?.speakers.find(speaker => { return speaker.id === word.speaker_id })

                let segment: PeaksSegment = {
                    startTime: start,
                    endTime: end,
                    editable: this.state.userCanEdit,
                    color: speaker?.colour ?? "rgba(200, 200, 200, 1)",
                    labelText: String(word.text),
                    id: String(word.id)
                }

                return segment;
            });

            this.setState({
                sortedEditions: sortedEditions,
                peaksSegments: peaksSegments,
            })

        }
    }

    handleDownloadPeaksProgress = (progress: number, total: number) => {
        this.setState({
            currentWaveformDownloadState: (progress / total) * 100,
        })
    }

    handleDownloadTranscriptionProgress = (progress: number, total: number) => {
        this.setState({
            currentTranscriptionDownloadState: (progress / total) * 100,
        })
    }

    handleDownloadAudioProgress = (progress: number, total: number) => {
        this.setState({
            currentAudioDownloadState: (progress / total) * 100,
        })
    }

    initialLoad = async () => {
        await Promise.allSettled([
            this.loadAudio(),
            this.loadTranscriptionFromServer()
        ]);
    }

    loadAudio = async () => {
        let thisthis = this;
        API.getTranscriptionAudio(this.state.transcriptionId)
            .then((temporaryAudioAndWaveformLinks: TemporaryAudioAndWaveformLinks) => {
                this.setState({
                    audioSourceUrl: temporaryAudioAndWaveformLinks.url,
                    waveformSourceUrl: temporaryAudioAndWaveformLinks.waveform
                })
                if (temporaryAudioAndWaveformLinks.url && temporaryAudioAndWaveformLinks.waveform) {
                    FetchAudioHelper.fetchAudioAndWaveform(
                        temporaryAudioAndWaveformLinks.waveform,
                        this.state.transcriptionId,
                        (audioLoadingState: AudioLoadingState) => {
                            thisthis.setState({
                                audioLoadingState: audioLoadingState
                            })
                        },
                        (blob?: Blob | undefined) => {
                            this.setState({
                                audioBlob: blob,
                            })
                        },
                        (peakdata?: WaveFormData) => {
                            this.setState({
                                audioPeakdata: peakdata
                            })
                        },
                        this.handleDownloadPeaksProgress,
                        this.handleDownloadAudioProgress
                    )
                } else {
                    console.log("loadAudio error -  no audiosrc")
                }

            })
            .catch(error => console.log(" getTranscriptionAudioerror:", error))

    }

    handleMarkTranscriptionAsDone = async () => {
        if (this.state.userProfile?.id) {
            API.markTranscriptionAsDone(this.state.transcriptionId)
                .then((userMarkTxAsDone: UserMarkTxAsDone) => {
                    console.log(userMarkTxAsDone)
                })
        }
    }

    loadTranscriptionFromServer = async () => {
        //@ts-ignore
        const id = parseInt(this.props.computedMatch.params.id, 10);

        API.getCurrentUsersProfile(sessionService.getSessionToken()).then((userProfile: UserProfile | undefined) => {
            this.setState({
                userProfile: userProfile
            });
            if (userProfile !== undefined) {
                store.dispatch(updateUserProfile(userProfile))
                API.getTranscription(
                    id,
                    this.handleDownloadTranscriptionProgress
                ).then((data: Transcription) => {
                    if (data !== undefined) {
                        let userCanEdit = false;
                        console.log("loadTranscriptionFromServer done")
                        data.user_permissions.forEach(permission => {
                            if (permission.user_id === this.state.userProfile?.id) {
                                userCanEdit = permission.can_edit
                            }
                        })
                        if (this.state.userProfile?.admin || this.state.userProfile?.can_process_files) {
                            userCanEdit = true
                        }

                        this.rawEditionsFromApi = data.editions;
                        this.setState({
                            transcription: data,
                            userCanEdit: userCanEdit,
                            audioSourceUrl: data.audioSourceUrl,
                            waveformSourceUrl: data.waveformSourceUrl,
                        });

                        this.prepareAudio();

                        this.sortEnabledTranscriptionEditions();

                        this.ws = new TranscriptionWebsocket({
                            transcriptionId: this.state.transcriptionId,
                            handleReceivedWebsocketMessage: this.handleReceivedWebsocketMessage,
                        });


                    }
                }).catch((e) => {
                    // redirect user to tx list
                    this.setState({
                        redirect: "/"
                    })
                });
            } else {
                this.setState({
                    redirect: "/"
                })
            }
        });

    };

    handleOnSegmentEntered = (segmentId: string | undefined, shouldJumpToActiveWord: boolean) => {
        let activeWord = this.state.sortedEditions.find(word => {
            return String(word.id) === String(segmentId);
        })
        if (activeWord) {
            if (activeWord.id !== this.state.activeWord?.id) {
                this.handleActivateWord(activeWord, shouldJumpToActiveWord);
                return
            }
        } else {
            this.handleActivateWord(null, false);
        }
    };

    handleKeydown = (e: KeyboardEvent) => {

        if (!this.state.speakersListVisible) {
            let globalKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

            if (globalKeys.indexOf(e.key) > -1) {
                if (e.key === 'Tab' || e.altKey) {
                    e.preventDefault();
                    this.peaksComponentRef.current?.handleKeyPressed(e);
                } else if (!this.activeWordIsEditing) {
                    e.preventDefault();
                    this.peaksComponentRef.current?.handleKeyPressed(e);
                }
            }
            else if (this.peaksComponentRef.current?.audioWaveFormActive) {
                if (!this.activeWordIsEditing) {
                    if (e.key === 'i' || e.key === 'o') {
                        this.peaksComponentRef.current?.handleKeyPressed(e);
                        e.preventDefault();
                    }
                }
            }
        }
    }

    prepareAudio = () => {
        let token = localStorage.getItem("token");

        if (this.state.transcriptionId && token) {
            this.setState({
                readyForClient: true,
            });
        }
    };

    handleGoToPreviousWord = (time: number) => {
        if (time === 0) { return }

        let previousEditionIndex = this.binarySearchEditionByTime(time) - 1
        // console.log("handleGoToPreviousWord currentEditionReverseIndex:", previousEditionIndex)

        if (previousEditionIndex === -1 || previousEditionIndex === this.state.sortedEditions.length - 1) {
            // console.log("handleGoToPreviousWord 1")
            this.handleActivateWord(null, true)
            this.peaksComponentRef.current?.goToTime(0)
        } else if (previousEditionIndex + 1 < this.state.sortedEditions.length) {
            // console.log("handleGoToPreviousWord 2")
            let previousEdition = this.state.sortedEditions[previousEditionIndex]
            this.handleActivateWord(previousEdition, true)
        } else {
            // console.log("handleGoToPreviousWord 3")
            let currentEdition = this.state.sortedEditions[previousEditionIndex]
            this.handleActivateWord(currentEdition, true)
        }
    }

    private roundFloat = (value: number) => {
        // round time due to float limitation in peaks
        return Math.floor(value * 1000000) / 1000000
    }

    private getNextWord = (time: number, includeActiveWord: boolean = false): (Edition | undefined) => {
        // consolelog("getNextWord")
        return this.state.sortedEditions.find(edition => {
            return this.roundFloat(edition.time) > this.roundFloat(time)
        })
    }

    handleGoToNextWord = (time: number) => {
        // consolelog("handleGoToNextWord")
        if (this.state.activeWord) {
            let index = this.state.sortedEditions.findIndex(edition => edition.id === this.state.activeWord?.id)
            if (index > -1) {
                let nextWord = this.state.sortedEditions[index + 1]
                // consolelog("handleGoToNextWord based on active word", nextWord?.time, nextWord?.text)
                if (nextWord !== undefined) {
                    this.handleActivateWord(nextWord, true)
                    return
                }
            }
        }

        // fallback by time
        let nextWord = this.getNextWord(time);
        // consolelog("handleGoToNextWord by TIME:", time, nextWord?.time)
        if (nextWord !== undefined) {
            this.handleActivateWord(nextWord, true)
        }

    }

    handleActivateWord = (
        word: Edition | null | undefined,
        shouldJumpToActiveWord: boolean = false
    ) => {
        // consolelog("handleActivateWord activeWordIsEditing", this.activeWordIsEditing)
        if (!this.activeWordIsEditing) {
            if (shouldJumpToActiveWord && word?.time) {
                this.peaksComponentRef.current?.goToTime(word?.time)
            }
            this.setState({
                activeWord: word ?? null,
            });
            // consolelog("handleActivateWord", word?.time, word?.text)

        }
    };

    handleDeleteEdition = () => {
        // consolelog("handleDeleteEdition")
        let edition = this.state.activeWord;
        this.handleActivateWord(null, false)
        if (edition) {
            this.deleteWord(edition)
        }

    };

    deleteWord = (edition: Edition) => {
        // consolelog("deleteWord")
        edition.enabled = false
        if (this.state.userCanEdit === true) {
            this.createOrUpdateEdition(edition)
        };
    }

    handleUpdateSpeakerForRegions = (
        transcription_id: number,
        speaker_id: number | undefined,
        edition_ids: number[] | undefined,
    ) => {
        if (transcription_id !== undefined && edition_ids !== undefined) {
            let regionSpeaker = {
                transcription_id: this.state.transcriptionId,
                speaker_id: speaker_id,
                edition_ids: edition_ids
            }

            API.updateSpeakerForEditions(regionSpeaker).then((data: [Edition]) => {
                console.log("speakers updated", data)
                data.forEach(edition => {
                    let existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === edition.id)
                    // // consolelog("handleReceivedEditions - existingEditionIndex:", item.id, item.enabled)
                    if (existingEditionIndex === -1) {
                        // consolelog("doesnt exist", edition.id, edition.text);
                        this.rawEditionsFromApi.push(edition)
                    } else {
                        // consolelog("already exists", existingEditionIndex, edition.id, edition.text);
                        this.rawEditionsFromApi[existingEditionIndex] = edition;
                    }
                    this.sortEnabledTranscriptionEditions();
                })
                this.setState({
                    speakersListVisible: false,
                    editionsForSpeakerUpdate: undefined
                })

            });
        } else {
            // consolelog("invalid edition id")
            alert("issue sending speaker")
        }

    };
    createSpeaker = (speaker: Speaker, onSuccess: () => void) => {

        API.createSpeaker(speaker, this.state.transcriptionId)
            .then((newSpeakers: Speaker[]) => {
                onSuccess()
                if (this.state.transcription) {
                    this.setState({
                        transcription: Object.assign({}, this.state.transcription, {
                            speakers: newSpeakers
                        }),
                    })
                    // console.log("speaker RESPONSE", newSpeakers)
                    this.sortEnabledTranscriptionEditions();
                }
            }).catch((error: Error) => {
                alert(`error ${error.message}`,);
            });

    }

    private handleUpdateSegment = (startTime: number, endTime: number, id: number) => {

        let editionToUpdate = this.state.sortedEditions.find(word => {
            return String(word.id) === String(id);
        })

        // check for potential conflicts from overlaps
        let overlap = this.state.sortedEditions.find(word => {
            if (word.time > word.time + (word.duration ?? 0)) {
                console.log("CONFLICT in greater than out:", word.time, word.time + (word.duration ?? 0), word.text)
                return true
            }
            else if (word.id !== id) {
                // console.log("startTime:", startTime, "endTime", word.time, word.text)
                let intime = word.time < startTime && startTime < (word.time + (word.duration ?? 0));
                let outtime = startTime < word.time && endTime > word.time;
                return intime || outtime;
            }
            return false
        })

        let inOutProblem = startTime > endTime

        if (overlap !== undefined) {
            alert("OVERLAP!")
            console.log("Overlap:", overlap?.time)
            this.peaksComponentRef.current?.addSegmentsToPeaks()
        } else if (inOutProblem) {
            alert("OVERLAP!")
            console.log("Overlap: inpoint more than output")
            this.peaksComponentRef.current?.addSegmentsToPeaks()
        } else {
            if (editionToUpdate) {
                editionToUpdate = Object.assign(
                    {},
                    editionToUpdate,
                    {
                        ...editionToUpdate,
                        time: startTime,
                        duration: endTime - startTime
                    }
                )
                this.createOrUpdateEdition(editionToUpdate)
            }
        }
    }

    private updateEditionIsSaving(editionId: number) {
        // consolelog("updateEditionIsSaving")
        if (!this.state.editionIdsSaving.includes(editionId)) {
            let editionIdsSaving = this.state.editionIdsSaving
            editionIdsSaving.push(editionId)
            this.setState({
                editionIdsSaving: editionIdsSaving
            })
        }
    }

    private updateEditionDidSave(editionId: number) {
        // consolelog("updateEditionDidSave")
        let editionIdsSaving = this.state.editionIdsSaving
        let editionIdIndex = this.state.editionIdsSaving.indexOf(editionId)
        if (editionIdIndex > -1) {
            editionIdsSaving.splice(editionIdIndex, 1)
            this.setState({
                editionIdsSaving: editionIdsSaving
            })
        }

    }

    private createOrUpdateEdition = (edition: Edition) => {
        // consolelog("createOrUpdateEdition")
        if (this.state.userCanEdit === true) {

            // consolelog("------createOrUpdateEdition REQUEST")
            if (edition.id) {
                this.updateEditionIsSaving(edition.id)
            }

            API.createOrUpdateEdition(edition).then((data: Edition) => {
                // consolelog("------createOrUpdateEdition RESPONSE")
                if (this.state.transcription !== undefined) {

                    if (edition.id) {
                        this.updateEditionDidSave(edition.id)
                    }

                    // update existing or add
                    let existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === data.id);

                    if (existingEditionIndex !== -1) {
                        this.rawEditionsFromApi[existingEditionIndex] = data;
                    } else {
                        this.rawEditionsFromApi.push(data)
                    }
                    this.sortEnabledTranscriptionEditions();

                    let activeRegion = this.state.sortedEditions.find(sortedEdition => sortedEdition.id === data.id)
                    this.handleActivateWord(activeRegion)
                }
            });
        };
    }

    private handleSplitEdition = (splitIndex: number, edition: Edition) => {
        // consolelog("handleSplitEdition")
        if (this.state.userCanEdit === true) {

            if (edition.id) {
                this.updateEditionIsSaving(edition.id)
            }

            API.splitEdition(splitIndex, edition).then((data: [Edition]) => {

                // consolelog("API.splitEdition response");
                // // consolelog("API.splitEdition", data);

                if (edition.id) {
                    this.updateEditionDidSave(edition.id)
                }

                if (this.state.transcription !== undefined) {

                    // update existing or add
                    data.forEach(updatedEdition => {
                        let existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === updatedEdition.id);

                        if (existingEditionIndex !== -1) {
                            this.rawEditionsFromApi[existingEditionIndex] = updatedEdition;
                        } else {
                            this.rawEditionsFromApi.push(updatedEdition)
                        }
                    })

                    this.sortEnabledTranscriptionEditions();

                    let activeRegion = this.state.sortedEditions.find(sortedEdition => sortedEdition.id === data[0].id)
                    this.handleActivateWord(activeRegion)

                }
            });
        };
    }

    private handleMergeEdition = (edition: Edition) => {
        if (this.state.userCanEdit === true) {
            // let nextEdition = this.getNextWord(edition.time)
            let nextEditionIndex = this.state.sortedEditions.findIndex(sortedEdition => sortedEdition.id === edition.id)
            if (nextEditionIndex < this.state.sortedEditions.length - 1) {

                let nextEdition = this.state.sortedEditions[nextEditionIndex + 1]
                // console.log("MERGE:")
                // console.log("edition 1", edition.time, edition.text)
                // console.log("edition 2", nextEdition.time, nextEdition.text)

                if (edition.id) {
                    this.updateEditionIsSaving(edition.id)
                }

                API.mergeEditions(edition, nextEdition, true, true).then((data: [Edition]) => {
                    if (edition.id) {
                        this.updateEditionDidSave(edition.id)
                    }

                    // update existing or add
                    data.forEach(updatedEdition => {
                        let existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === updatedEdition.id);

                        if (existingEditionIndex !== -1) {
                            this.rawEditionsFromApi[existingEditionIndex] = updatedEdition;
                        } else {
                            this.rawEditionsFromApi.push(updatedEdition)
                        }
                    })

                    this.sortEnabledTranscriptionEditions();

                    let activeRegion = this.state.sortedEditions.find(sortedEdition => sortedEdition.id === data[0].id)
                    this.handleActivateWord(activeRegion)
                });
            }
        };
    }

    private handleInsertNewEdition = (baseEdition: Edition) => {
        if (this.state.transcription?.id) {
            if (this.state.userCanEdit === true) {
                let nextEdition = this.getNextWord(baseEdition.time)
                if (nextEdition) {
                    let timein = baseEdition.time + (baseEdition.duration ?? 0)
                    let duration = nextEdition.time - timein

                    let edition: Edition = {
                        time: timein,
                        transcription_id: this.state.transcription.id,
                        duration: duration
                    }
                    this.handleAddOrCreateEdition(edition)
                }
            }
        };
    }

    handleFindAndReplace = (searchTerm: string, replaceTerm: string) => {
        // console.log("find:", searchTerm,);
        // console.log("replace with:", replaceTerm);
        // console.log("edition ids:", this.state.editionsSelectedForFindAndReplace);

        let request: FindAndReplaceInEditionsRequest = {
            transcription_id: this.state.transcriptionId,
            search_term: searchTerm,
            replace_text: replaceTerm,
            edition_ids: Array.from(this.state.editionsSelectedForFindAndReplace.values())
        }
        console.log(request)
        API.findAndReplaceInEditions(this.state.transcriptionId, request).then((data: [Edition]) => {
            // console.log("findAndReplaceInEditions success")
            this.setState({
                showFindAndReplace: false,
                editionsSelectedForFindAndReplace: new Set<number>(),
                currentSearchTerm: undefined
            })
            data.forEach(edition => {
                let existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === edition.id)
                if (existingEditionIndex === -1) {
                    this.rawEditionsFromApi.push(edition)
                } else {
                    this.rawEditionsFromApi[existingEditionIndex] = edition;
                }
                this.sortEnabledTranscriptionEditions();
            })
        });
    }

    handleAddEditionsForFindAndReplace = (editionIds: number[]) => {
        let newEditionsForFindAndReplace = this.state.editionsSelectedForFindAndReplace
        editionIds.forEach(editionId => {
            newEditionsForFindAndReplace.add(editionId)
        })
        this.setState({
            editionsSelectedForFindAndReplace: newEditionsForFindAndReplace
        })
    }

    handleRemoveEditionsForFindAndReplace = (editionIds: number[]) => {
        // console.log("handleRemoveEditionsForFindAndReplace", editionIds)
        let newEditionsForFindAndReplace = this.state.editionsSelectedForFindAndReplace
        editionIds.forEach(editionId => {
            newEditionsForFindAndReplace.delete(editionId)
        })
        this.setState({
            editionsSelectedForFindAndReplace: newEditionsForFindAndReplace
        })
    }

    handleUpdateCurrentSearchTerm = (searchTerm: string | undefined) => {
        this.setState({
            editionsSelectedForFindAndReplace: new Set<number>(),
            currentSearchTerm: searchTerm
        })
        if (searchTerm === undefined || searchTerm.length === 0) {
            this.handleDeselectAllForFindAndReplace()
        } else {
            this.handleSelectAllForFindAndReplace()
        }
    }

    handleSelectAllForFindAndReplace = () => {
        let set = new Set<number>()

        let editions = this.state.sortedEditions

        if (this.state.showFindAndReplace) {
            if (this.state.currentSearchTerm) {
                let currentSearchTerm = this.state.currentSearchTerm
                editions = editions.filter(edition => {
                    let k = edition.text?.indexOf(currentSearchTerm) ?? -1
                    return k > -1
                })
            }
        }

        editions.filter(e => e.id !== undefined).map(e => e.id).forEach(e => {
            if (e) {
                set.add(e)
            }
        })
        this.setState({
            editionsSelectedForFindAndReplace: set
        })

    }
    handleDeselectAllForFindAndReplace = () => {
        this.setState({
            editionsSelectedForFindAndReplace: new Set<number>()
        })
    }

    handleAddOrCreateEdition = (edition: Edition) => {
        if (edition.id) {
            this.updateEditionIsSaving(edition.id)
        }

        let editionToSend = edition;
        let existingEditionIndex: number | undefined = -1

        edition.is_normalised_sentence = true
        if (this.state.transcription !== undefined) {

            if (edition.id) {
                this.updateEditionDidSave(edition.id)
            }

            if (edition.id !== undefined) {
                existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === edition.id)
            }

            if (existingEditionIndex !== undefined && existingEditionIndex >= 0) {

                let existingEdition = this.rawEditionsFromApi[existingEditionIndex];
                editionToSend = Object.assign({}, existingEdition, { ...edition, edition_type: 1 })

                this.createOrUpdateEdition(editionToSend);

                this.activeWordIsEditing = false;

                this.setState({
                    activeWord: null,
                });

            } else {

                this.activeWordIsEditing = false;

                this.currentWordInput.current?.focus();
                editionToSend.edition_type = 0;
                delete editionToSend.id; // remove wavesurfer temp ID

                // make sure that the edition out (time + duration) doesn't overlap with any existing words
                let inTimeConflicts = this.state.sortedEditions.filter(e => {
                    let inpointConflict = e.time < edition.time && e.time + (e.duration ?? 0) > edition.time;
                    // let outpointConflict = (e.time + (e.duration ?? 0) > edition.time + (edition.duration ?? 0));
                    return inpointConflict;
                })

                if (inTimeConflicts?.length === 0) {

                    // Get next possible edition/region and adjust our new edition's out time to be less that the next in if necessary
                    let nextWord = this.getNextWord(editionToSend.time, true);

                    if (nextWord !== undefined) {
                        //adjust edition out to less
                        editionToSend.duration = Math.min((editionToSend.duration ?? 1), nextWord.time - (editionToSend.time))
                    }

                    if (editionToSend.duration !== undefined && editionToSend.duration > 0) {
                        this.createOrUpdateEdition(editionToSend);
                    } else {
                        console.log("duration conflict");
                    }
                } else {
                    console.log("conflicts");
                }
            }
        }
    };

    handleSaveWordEdition = (edition: Edition) => {
        // console.log("handleSaveWordEdition", this.state.activeWord)
        let activeWordEdition = this.state.activeWord;
        if (activeWordEdition !== null) {
            this.activeWordIsEditing = false
            if (activeWordEdition.text !== edition.text) {
                this.handleAddOrCreateEdition(edition);
            } else {
                this.activeWordIsEditing = false
            }
        }
    };

    handleReceivedWebsocketMessage = () => (somestuff: string) => {
        if (somestuff === "NEWWORDS") {
            var lastWordId: number | null = null;
            this.state.transcription?.words.forEach(word => {
                if (word.id) {
                    if (lastWordId) {
                        if (word.id > lastWordId) {
                            lastWordId = word.id;
                        }
                    } else {
                        lastWordId = word.id;
                    }
                }
            });

            try {
                this.ws?.ws.send(JSON.stringify({ lastid: lastWordId }));
            } catch (error) {
                console.log("send fail", this.ws?.ws.url); // catch error
            }
        } else {
            let jsonData = JSON.parse(somestuff);
            if (jsonData["partial"]) {
                this.setState({
                    partial: jsonData["partial"],
                });
            }
            else if (jsonData["msgtype"] === "EDITIONS" && jsonData["content"]) {
                // console.log("received WS editions:", jsonData["content"]);
                try {
                    let editions = jsonData["content"] as Array<Edition>;
                    this.handleReceivedEditions(editions)
                } catch (error) {
                    // console.log("not array of words:", somestuff);
                }
            }
            else if (jsonData["msgtype"] === "EDITION" && jsonData["content"]) {
                let edition = jsonData["content"] as Edition;
                if (edition.id !== undefined) {
                    this.handleReceivedEditions([edition])
                } else {
                    try {
                        let editions = jsonData["content"] as Array<Edition>;
                        this.handleReceivedEditions(editions)
                    } catch (error) {
                        // console.log("not array of words:", somestuff);
                    }
                }
            }
            else if (jsonData["msgtype"] === "SPEAKERS" && jsonData["content"] && Array.isArray(jsonData["content"])) {
                let speakers = jsonData["content"] as Speaker[];
                // console.log("new speakers", speakers)
                let transcription = Object.assign({}, this.state.transcription, {
                    speakers: speakers,
                });
                this.setState({
                    transcription: transcription
                })
                this.sortEnabledTranscriptionEditions();
            }
        }
    };

    handleReceivedEditions = (receivedEditions: Edition[]) => {
        // console.log("handleReceivedEditions")
        if (this.state.transcription !== undefined) {
            receivedEditions.forEach(item => {
                let existingEditionIndex = this.rawEditionsFromApi.findIndex(value => value.id === item.id)
                if (existingEditionIndex === -1) {
                    this.rawEditionsFromApi.push(item)
                } else {
                    this.rawEditionsFromApi[existingEditionIndex] = item;
                }
            })

            this.sortEnabledTranscriptionEditions();
        }
    }

    handleDownloadDocx = () => {
        if (this.state.transcription) {
            let metadata = this.state.transcription?.transcriptionmetadataentries;
            let filename = `tx${this.state.transcription.id}_${this.state.transcription.title}`
            if (metadata) {
                let audienceType = metadata.find(entry => entry.key === "Tipo audiencia")
                let declarante = metadata.find(entry => entry.key === "Declarante")
                if (audienceType?.text && declarante?.text) {
                    filename = `tx${this.state.transcription.id}_${audienceType.text}_${declarante.text}`
                }
            }
            API.getTranscriptionDocx(
                this.state.transcription.id,
                `${filename}.docx`
            );
        }
    };

    handleDownloadM4a = () => {
        if (this.state.transcription) {
            let metadata = this.state.transcription?.transcriptionmetadataentries;
            let filename = `tx${this.state.transcription.id}_${this.state.transcription.title}`
            if (metadata) {
                let audienceType = metadata.find(entry => entry.key === "Tipo audiencia")
                let declarante = metadata.find(entry => entry.key === "Declarante")
                if (audienceType?.text && declarante?.text) {
                    filename = `tx${this.state.transcription.id}_${audienceType.text}_${declarante.text}`
                }
            }
            API.getTranscriptionM4aForDownload(
                this.state.transcription.id,
                `${filename}.m4a`
            );
        }
    };

    handleDownloadSrt = () => {
        if (this.state.transcription) {
            let metadata = this.state.transcription?.transcriptionmetadataentries;
            let filename = `tx${this.state.transcription.id}_${this.state.transcription.title}`
            if (metadata) {
                let audienceType = metadata.find(entry => entry.key === "Tipo audiencia")
                let declarante = metadata.find(entry => entry.key === "Declarante")
                if (audienceType?.text && declarante?.text) {
                    filename = `tx${this.state.transcription.id}_${audienceType.text}_${declarante.text}`
                }
            }
            API.getTranscriptionSrt(
                this.state.transcription.id,
                `${filename}.srt`
            );
        }
    };

    handleDownloadTpJson = () => {
        API.getTranscription(
            this.state.transcriptionId,
            this.handleDownloadTranscriptionProgress
        ).then((data: Transcription) => {
            if (data !== undefined) {
                let theJSON = JSON.stringify(data);
                let blob = new Blob([theJSON], { type: 'text/json' });
                let url = window.URL.createObjectURL(blob);
                console.log(data)


                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = `${this.state.transcriptionId}_tp.json`;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();

            }
        });
    };

    handleImportSRT = () => {
        // event.preventDefault();
        if (this.state.transcription) {
            API.importTranscriptionSrt(
                this.state.transcription.id
            );
        }
    };

    handlePlayingSate = (isPlaying: boolean) => {
        this.setState({ playing: isPlaying })
    }

    handleOnFocusTextEditorItem = (word: Edition) => {
        // console.log("handleOnFocusTextEditorItem", word)
        this.activeWordIsEditing = false;
        this.handleActivateWord(word, false)
        this.activeWordIsEditing = true;
    }

    handleShowImportSRTModal = () => {
        this.setState({
            showImportSRTModal: true
        })
    }
    handleShowImportSMModal = () => {
        this.setState({
            showImportSMModal: true
        })
    }
    handleShowImportTPModal = () => {
        this.setState({
            showImportTPModal: true
        })
    }
    handleHideImportSRTModal = () => {
        this.setState({
            showImportSRTModal: false
        })
    }

    handleHideImportSMModal = () => {
        this.setState({
            showImportSMModal: false
        })
    }
    handleHideImportTPModal = () => {
        this.setState({
            showImportTPModal: false
        })
    }

    timeWithinEdition = (edition: Edition, time: number) => {
        // console.log("timeWithinEdition T:", edition.text)
        // console.log("timeWithinEdition A:", edition.time, time, edition.time + (edition.duration ?? 0))
        // console.log("timeWithinEdition B:", this.roundFloat(edition.time), time, this.roundFloat(edition.time + (edition.duration ?? 0)))

        return this.roundFloat(edition.time) <= time && time < this.roundFloat(edition.time + (edition.duration ?? 0))
    }

    binarySearchEditionByTime = (time: number) => {
        let start = 0;
        let end = this.state.sortedEditions.length - 1;

        while (start <= end) {
            let middle = Math.floor((start + end) / 2);

            if (this.timeWithinEdition(this.state.sortedEditions[middle], time)) {
                return middle;
            } else if (this.state.sortedEditions[middle].time < time) {
                start = middle + 1;
            } else {
                end = middle - 1;
            }
        }
        return -1;
    }

    getEditionAtTime = (time: number) => {
        let possibleEditionIndex = this.binarySearchEditionByTime(time)
        // console.log("getEditionAtTime possibleEditionIndex", possibleEditionIndex)

        if (possibleEditionIndex > 0) {
            if (this.timeWithinEdition(this.state.sortedEditions[possibleEditionIndex - 1], time)) {
                possibleEditionIndex -= 1
            }
        }

        if (possibleEditionIndex >= 0) {
            return this.state.sortedEditions[possibleEditionIndex]
        }
        return undefined
    }

    private updateActiveWord = (atTime: number, fromWaveformInteraction: boolean, jumpToActiveWord: boolean) => {
        // console.log("updateActiveWord", atTime)
        let editionAtTime = this.getEditionAtTime(atTime)
        // console.log("updateActiveWord editionAtTime", editionAtTime?.id, editionAtTime?.time)

        this.setState({
            activeWord: editionAtTime ?? null,
            shouldJumpToActiveWord: editionAtTime ? jumpToActiveWord : false
        })

        if (editionAtTime) {
            if (jumpToActiveWord && !fromWaveformInteraction && this.activeWordIsEditing === false) {
                this.peaksComponentRef.current?.goToTime(atTime)
            }
        }
    }


    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        let waveform;
        let textEditor;
        let autoscroll;

        if (this.state.audioPeakdata && this.state.audioSourceUrl) {
            waveform = (
                <PeaksComponent
                    ref={this.peaksComponentRef}
                    // audioFile={this.state.audioBlob}
                    audioFile={this.state.audioSourceUrl}
                    peakdata={this.state.audioPeakdata}
                    peaksSegments={this.state.peaksSegments}
                    userCanEdit={this.state.userCanEdit}
                    txid={this.state.transcriptionId}
                    handleActivateWord={this.handleActivateWord}
                    handleExitedSegment={(editionId: number) => {
                        if (this.state.activeWord?.id === editionId) {
                            if (!this.activeWordIsEditing) {
                                this.handleActivateWord(null, false)
                            }
                        }
                    }}
                    handleGoToPreviousWord={this.handleGoToPreviousWord}
                    handleGoToNextWord={this.handleGoToNextWord}
                    currentTime={this.state.currentTime}
                    handleOnSegmentEntered={this.handleOnSegmentEntered}
                    handleUpdateSegment={this.handleUpdateSegment}
                    handleGoToTime={(time: number, activateWord: boolean) => {
                        // console.log("handleGoToTime PEAKS", time, activateWord)
                        if (activateWord) {
                            this.updateActiveWord(time, true, activateWord)
                        }
                    }}
                    handleBufferingAudio={(isBuffering) => {
                        console.log("buffering...")
                        this.setState({
                            isBufferingAudio: isBuffering
                        })
                    }}
                />
            );
        }
        else {
            waveform = (
                <div style={{ "height": "144px", "width": "300px", "paddingRight": "10px" }}>
                    {Strings.strings.downloadingWaveform}:<Progress percent={this.state.currentWaveformDownloadState} format={percent => `${Number(percent).toPrecision(3)}%`} />
                </div>
            );
        }

        let speakersButton;
        let findAndReplaceButton;
        let bufferingMessage;

        let editions = this.state.sortedEditions
        if (this.state.showFindAndReplace) {
            if (this.state.currentSearchTerm?.length) {
                let currentSearchTerm = this.state.currentSearchTerm
                if (currentSearchTerm.length > 0) {
                    editions = editions.filter(edition => {
                        let k = edition.text?.indexOf(currentSearchTerm) ?? -1
                        return k > -1
                    })
                }
            }
        }

        if (this.state.isBufferingAudio) {
            bufferingMessage = (
                <div style={{ paddingLeft: "20px", display: "inline" }}>
                    <div style={{ paddingLeft: "20px", display: "inline" }}>
                        <SyncOutlined spin />
                    </div>
                    <div style={{ paddingLeft: "8px", display: "inline", fontSize: "0.8em" }}>
                        {Strings.strings.buffering}
                    </div>
                </div>
            )
        }
        if (this.state.transcription !== undefined) {
            textEditor = (
                <div style={{ marginBottom: "30px", marginLeft: "-40px" }}>
                    <TextEditor
                        ref={this.textEditorRef}
                        transcriptionId={this.state.transcriptionId}
                        editions={editions}
                        speakers={this.state.transcription.speakers}
                        activeWord={this.state.activeWord}
                        shouldScrollActiveWordIntoView={this.shouldScrollActiveWordIntoView}
                        editingEnabled={this.state.userCanEdit}
                        editionIdsSaving={this.state.editionIdsSaving}
                        shouldJumpToActiveWord={this.state.shouldJumpToActiveWord}
                        showFindAndReplace={this.state.showFindAndReplace}
                        itemsSelectedForFindAndReplace={Array.from(this.state.editionsSelectedForFindAndReplace.values())}
                        handleAddOrCreateEdition={this.handleAddOrCreateEdition}
                        handleActivateWord={this.handleActivateWord}
                        handleGoToTime={(time: number, activateWord: boolean) => {
                            // console.log("handleGoToTime TextEditor", time, activateWord)
                            if (activateWord) {
                                this.updateActiveWord(time, false, activateWord)
                            }
                        }}
                        handleSaveEdition={this.handleSaveWordEdition}
                        handleOnFocusTextEditorItem={this.handleOnFocusTextEditorItem}
                        autoScrollState={this.state.autoScrollState}
                        editorToolsEnabled={this.state.userCanEdit}
                        handleDeleteEdition={this.deleteWord}
                        handleSplitEdition={this.handleSplitEdition}
                        handleMergeEdition={this.handleMergeEdition}
                        handleInsertNewEdition={this.handleInsertNewEdition}
                        handleShowSpeakers={edition => {
                            this.setState({
                                editionsForSpeakerUpdate: edition,
                                speakersListVisible: true
                            })
                        }}
                        handleAddEditionsForFindAndReplace={this.handleAddEditionsForFindAndReplace}
                        handleRemoveEditionsForFindAndReplace={this.handleRemoveEditionsForFindAndReplace}
                        handleFindAndReplace={this.handleFindAndReplace}
                        handleSelectAll={this.handleSelectAllForFindAndReplace}
                        handleDeselectAll={this.handleDeselectAllForFindAndReplace}
                        handlUpdateCurrentSearchTerm={this.handleUpdateCurrentSearchTerm}
                    />

                </div>
            );

            autoscroll = (

                <div style={{ display: "inline" }}>
                    <div style={{ paddingRight: "8px", display: "inline" }}>
                        {Strings.strings.autoScrollMode}
                    </div>
                    <Button
                        style={{
                            background: `${this.state.autoScrollState === AutoScrollState.nothing ? PUDU_SECONDARY_COLOUR : "#999"}`,
                            borderColor: `${this.state.autoScrollState === AutoScrollState.nothing ? PUDU_SECONDARY_COLOUR : "#999"}`,
                            color: "#fff",
                            marginRight: "6px"
                        }}
                        type="primary"
                        disabled={this.state.autoScrollState === AutoScrollState.nothing}
                        size="small"
                        onClick={() => {
                            this.setState({
                                autoScrollState: AutoScrollState.nothing
                            })
                        }}
                    >
                        {Strings.strings.off}
                    </Button>
                    <Button
                        style={{
                            background: `${this.state.autoScrollState === AutoScrollState.scrollToActive ? PUDU_SECONDARY_COLOUR : "#999"}`,
                            borderColor: `${this.state.autoScrollState === AutoScrollState.scrollToActive ? PUDU_SECONDARY_COLOUR : "#999"}`,
                            color: "#fff",
                            marginRight: "12px"
                        }}
                        type="primary"
                        size="small"
                        disabled={this.state.autoScrollState === AutoScrollState.scrollToActive}
                        onClick={() => {
                            this.setState({
                                autoScrollState: AutoScrollState.scrollToActive
                            })
                        }}
                    >
                        {Strings.strings.activeRegion}
                    </Button>
                    {/* <Button
                            style={{
                                background: `${this.state.autoScrollState === AutoScrollState.scrollToNew ? PUDU_SECONDARY_COLOUR : "#999"}`,
                                borderColor: `${this.state.autoScrollState === AutoScrollState.scrollToNew ? PUDU_SECONDARY_COLOUR : "#999"}`,
                                color: "#fff",
                                paddingLeft: "12px"
                            }}
                            disabled={this.state.autoScrollState === AutoScrollState.scrollToNew}
                            type="primary"
                            size="small"
                            onClick={() => {
                                this.setState({
                                    autoScrollState: AutoScrollState.scrollToNew
                                })
                            }}
                        >
                            Región nueva
                        </Button> */}
                </div>

            )

            if (this.state.userCanEdit) {
                speakersButton = (
                    <div style={{ paddingLeft: "20px", display: "inline" }}>
                        <Button
                            style={{
                                background: PUDU_PRIMARY_COLOUR,
                                borderColor: PUDU_PRIMARY_COLOUR,
                                color: "#fff",
                            }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                this.setState({
                                    speakersListVisible: true
                                })
                            }}
                        >
                            {Strings.strings.speakers}
                        </Button>
                        <SpeakersListModal
                            edition={this.state.editionsForSpeakerUpdate}
                            title="speakers"
                            transcriptionId={this.state.transcriptionId}
                            speakers={this.state.transcription.speakers}
                            visible={this.state.speakersListVisible}
                            onOk={() => this.setState({ speakersListVisible: false })}
                            onCancel={() => this.setState({
                                speakersListVisible: false,
                                editionsForSpeakerUpdate: undefined
                            })}
                            createSpeaker={this.createSpeaker}
                            handleUpdateSpeakerForRegions={this.handleUpdateSpeakerForRegions}
                        />

                    </div>
                )

                findAndReplaceButton = (
                    <Button
                        style={{
                            background: this.state.showFindAndReplace ? PUDU_SECONDARY_COLOUR : PUDU_PRIMARY_COLOUR,
                            borderColor: this.state.showFindAndReplace ? PUDU_SECONDARY_COLOUR : PUDU_PRIMARY_COLOUR,
                            color: "#fff",
                            marginLeft: "12px"
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            this.setState({
                                showFindAndReplace: !this.state.showFindAndReplace
                            })
                        }}
                    >
                        {Strings.strings.findAndReplace}
                    </Button>
                )
            }
        } else {
            textEditor = (

                <div>
                    <div style={{ marginBottom: "30px", marginLeft: "-40px" }}>
                        <div style={{
                            backgroundColor: "rgb(230, 230, 230)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <div
                                style={{
                                    height: "calc(100vh - 220px)",
                                    position: "relative",
                                    width: "800px",
                                    backgroundColor: "#fff"
                                }}
                            >
                                <div style={{ padding: "10px", maxWidth: "400px", marginRight: "10px" }}>
                                    {Strings.strings.downloadingTranscription}: <Progress percent={this.state.currentTranscriptionDownloadState} format={percent => `${Number(percent)?.toPrecision(3)}%`} />
                                </div>
                                <div className="editorGutterBackground" style={{ padding: "10px" }}>
                                    <Skeleton.Input active={true} size={"small"} />
                                </div>
                                <Skeleton.Button active={true} size={"small"} shape={"round"} />
                                <div className="editorBackground" style={{ padding: "10px" }}>
                                    <Skeleton loading={true} active paragraph={{ rows: 30 }} />
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            );

        }

        let transcriptionWebsocket;
        if (this.state.transcription) {
            transcriptionWebsocket = (
                <TranscriptionWebsocket
                    transcriptionId={this.state.transcriptionId}
                    handleReceivedWebsocketMessage={this.handleReceivedWebsocketMessage()}
                />
            );
        } else {
            transcriptionWebsocket = "";
        }

        let isAdmin = this.state.userProfile?.admin;
        let isEditor = this.state.userProfile?.editor;

        let hideWhileLoading = this.state.readyForClient ? "" : "none";
        let showTextEditor = "";

        let editorMenu;
        let importMenu;

        let editorMenuItems = (<Menu />)
        let importMenuItems = (<Menu />)
        let downloadMenuItems = (<Menu />)

        if (isEditor && this.state.transcription) {
            editorMenuItems = (
                <Menu >
                    <Menu.Item key="1">
                        <div onClick={this.handleMarkTranscriptionAsDone}>
                            <CheckCircleOutlined />{Strings.strings.markAsDone}
                        </div>
                    </Menu.Item>
                </Menu>
            )
        }
        if (isAdmin && this.state.transcription) {
            importMenuItems = (
                <Menu >
                    <Menu.Item key="1">
                        <div onClick={this.handleShowImportSRTModal}>
                            <UploadOutlined /> Import SRT
                            <UploadSRTFile handleReload={this.loadTranscriptionFromServer} transcriptionId={this.state.transcription.id} visible={this.state.showImportSRTModal} handleClose={this.handleHideImportSRTModal} />
                        </div>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <div onClick={this.handleShowImportSMModal}>
                            <UploadOutlined /> Import SM json
                            <UploadSMJson handleReload={this.loadTranscriptionFromServer} transcriptionId={this.state.transcription.id} visible={this.state.showImportSMModal} handleClose={this.handleHideImportSMModal} />
                        </div>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <div onClick={this.handleShowImportTPModal}>
                            <UploadOutlined /> Import TP json
                            <UploadTPJson handleReload={this.loadTranscriptionFromServer} transcriptionId={this.state.transcription.id} visible={this.state.showImportTPModal} handleClose={this.handleHideImportTPModal} />
                        </div>
                    </Menu.Item>
                </Menu>
            );
        }

        let tpjsonDownload;
        if (isAdmin) {
            tpjsonDownload = (
                <Menu.Item key="4">
                    <div onClick={() => this.handleDownloadTpJson()}>
                        <DownloadOutlined /> {Strings.strings.tjpson}
                    </div>
                </Menu.Item>
            )
        }
        downloadMenuItems = (
            <Menu >
                <Menu.Item key="1">
                    <div onClick={() => this.handleDownloadM4a()}>
                        <DownloadOutlined /> {Strings.strings.m4a}
                    </div>
                </Menu.Item>
                <Menu.Item key="2">
                    <div onClick={() => this.handleDownloadDocx()}>
                        <DownloadOutlined /> {Strings.strings.docx}
                    </div>
                </Menu.Item>
                {/* <Menu.Item key="3">
                    <div onClick={() => this.handleDownloadSrt()}>
                        <DownloadOutlined /> srt
                    </div>
                </Menu.Item> */}
                {tpjsonDownload}
            </Menu>
        )

        editorMenu = (isEditor && this.state.transcription &&
            <Dropdown overlay={editorMenuItems}>
                <a href="#editorMenu" className="ant-dropdown-link" style={{ "paddingRight": "10px", "display": hideWhileLoading, color: PUDU_PRIMARY_COLOUR }} onClick={e => e.preventDefault()}>{Strings.strings.editor}<DownOutlined /></a>
            </Dropdown>
        )
        importMenu = (isAdmin &&
            <Dropdown overlay={importMenuItems}>
                <a href="#importMenu" className="ant-dropdown-link" style={{ "paddingRight": "10px", "display": hideWhileLoading, color: PUDU_PRIMARY_COLOUR }} onClick={e => e.preventDefault()}>{Strings.strings.import}<DownOutlined /></a>
            </Dropdown>
        )

        let downloadMenu = (
            <Dropdown overlay={downloadMenuItems}>
                <a href="#downloadMenu" className="ant-dropdown-link" style={{ "paddingRight": "10px", "display": hideWhileLoading, color: PUDU_PRIMARY_COLOUR }} onClick={e => e.preventDefault()}>{Strings.strings.downloads}<DownOutlined /></a>
            </Dropdown>
        )

        let transcriptionMetaDataModal;

        if (this.state.transcription) {

            let metadataView;
            if (this.state.userCanEdit) {
                metadataView = (
                    <EditTranscriptionDocsDetailsModal
                        transcription={this.state.transcription}
                        visible={this.state.transcriptionMetaDataModalIsVisible}
                        onOk={() => this.setState({ transcriptionMetaDataModalIsVisible: false })}
                        onCancel={() => this.setState({ transcriptionMetaDataModalIsVisible: false })}
                        tmpMetadataItems={undefined}
                        tmpFilename={undefined}
                        tmpTitle={undefined}
                        tmpDescription={undefined}
                    />
                )
            } else {
                metadataView = (
                    <TranscriptionInfoModal
                        transcription={this.state.transcription}
                        visible={this.state.transcriptionMetaDataModalIsVisible}
                        onOk={() => this.setState({ transcriptionMetaDataModalIsVisible: false })}
                        onCancel={() => this.setState({ transcriptionMetaDataModalIsVisible: false })}
                    />
                )
            }
            transcriptionMetaDataModal = (

                <div>
                    <InfoCircleOutlined
                        onClick={() => {
                            this.setState({
                                transcriptionMetaDataModalIsVisible: true
                            })
                        }}
                    />
                    {metadataView}
                </div>
            )
        }

        return (
            <div>
                <Prompt
                    when={true}
                    message={location =>
                        // `Are you sure you want to go to ${location.pathname}`
                        `Cerrar la transcipción?`
                    }
                />
                <div style={{ display: `${showTextEditor}` }}>

                    <div style={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div>
                            <div>
                                <div style={{ display: "inline-flex", color: PUDU_PRIMARY_COLOUR, paddingRight: "10px", }}>
                                    {transcriptionMetaDataModal}
                                </div>
                                <div style={{ display: "inline-flex", color: PUDU_PRIMARY_COLOUR }}>
                                    {this.state.transcription?.title} | {this.state.transcription?.description}
                                </div>
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }}></div>

                        {editorMenu}
                        {importMenu}
                        {downloadMenu}

                        {this.state.userCanEdit
                            &&
                            <div style={{ marginLeft: "4px" }}>
                                <Button
                                    style={{
                                        background: PUDU_PRIMARY_COLOUR,
                                        borderColor: PUDU_PRIMARY_COLOUR
                                    }}
                                    type="primary"
                                    size="small"
                                    onClick={() => { this.setState({ shortcutsModalVisible: true }) }}>
                                    {Strings.strings.shortcuts}
                                </Button>
                                <ShortCutKeysModal
                                    visible={this.state.shortcutsModalVisible}
                                    onOk={() => this.setState({ shortcutsModalVisible: false })}
                                    onCancel={() => this.setState({ shortcutsModalVisible: false })}
                                />
                            </div>
                        }
                    </div>

                    {waveform}
                    <div style={{ padding: "16px 0 8px 0" }}>
                        {autoscroll}
                        {speakersButton}
                        {findAndReplaceButton}
                        {bufferingMessage}
                    </div>
                    {textEditor}

                    {transcriptionWebsocket}
                </div >
            </div >
        );

    }

}

export default TranscriptionReview;


import { sessionService } from "../services";
import { Language } from "../types/enums";
import {
    Edition,
    TranscriptionBase,
    NewUser,
    UserUpdate,
    Speaker,
    UpdateSpeakerForEditionsEntity,
    TranscriptionMeta,
    M4aDownload,
    FindAndReplaceInEditionsRequest,
    UserLogEntryRequest,
    ProjectOut,
    ProjectStatusEntryOut,
    ProjectStatusTypeOut,
    TranscriptionMetadataToReorder,
    TranscriptionMetadataEntry,
    CustomVocabContent,
    CustomVocabContentOut,
    CustomVocabSoundsLikeOut,
    CustomVocabContentBase,
    CustomVocabSoundsLikeBase,
    SpeechmaticsConfig,
    CustomDictionaryBase,
    Client,
    Group
} from "../types/index";

export default {

    otpVerifyAccount: (email: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/otp/send/verifyaccount`;
        console.log(JSON.stringify({
            "email": email
        }))
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "X-Client": "web"
            },
            body: JSON.stringify({
                "email": email
            })
        })
            .then(resp => resp)
            .catch((error: Error) => {
                throw error
            });
    },

    updatedPasswordWithOTP: (email: string, password: string, otp: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/user/password/update`;
        console.log(JSON.stringify({
            "password": password,
            "otp": otp,
            "email": email
        }))
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "X-Client": "web"
            },
            body: JSON.stringify({
                "password": password,
                "otp": otp,
                "email": email
            })
        })
            .then(resp => resp)
            .catch((error: Error) => {
                throw error
            });
    },

    recoverPassword: (email: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/user/password/recover`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "X-Client": "web"
            },
            body: JSON.stringify({
                "email": email,
            })
        })
            .then(resp => resp)
            .catch((error: Error) => {
                throw error
            });
    },

    verifyEmail: (email: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/email/verify`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "X-Client": "web"
            },
            body: JSON.stringify({
                "email": email,
            })
        })
            .then(resp => resp)
            .catch((error: Error) => {
                throw error
            });
    },


    verifyOTP: (username: string, code: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/otp/verify/${username}/${code}`;

        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "X-Client": "web"
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    throw new Error(resp.statusText);
                }
                return resp.json();
            })
            .catch((error: Error) => {
                console.log("no access");
            });
    },

    getCurrentUsersProfile: (jwtToken: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/me`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    throw new Error(resp.statusText);
                }
                return resp.json();
            })
            .then(data => {
                return data;
            })
            .catch((error: Error) => {
                console.log("Session expired.");
                sessionService.finishAppSession()
                return null;
            });
    },

    createRTTranscription: (
        rt_transcription_out: TranscriptionBase,
        language: Language
    ) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/rt`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "transcription": rt_transcription_out,
                "language": language,
            })
        })
            .then(resp => {
                if (!resp.ok) {
                    throw new Error(resp.statusText);
                } else {
                    return resp.json();
                }
            })
            .catch(error => {
                return Error(error);
            });
    },

    getTranscription: (
        id: number,
        handleDownloadTranscriptionProgress: (progress: number, total: number) => void,

    ) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}`;

        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(async response => {
                if (response.status === 404) {
                    console.log("404")
                    return Promise.reject('error 404')
                }
                else if (!response.ok) {
                    throw new Error(response.statusText);
                }

                let reader = response.clone().body?.getReader();
                if (reader) {
                    const contentLength = response.headers.get("content-length");
                    if (contentLength) {
                        const chunks = new Uint8Array(Number(contentLength));
                        let at = 0;
                        for (; ;) {
                            const { done, value } = await reader.read();
                            handleDownloadTranscriptionProgress(at, Number(contentLength))
                            if (done) {
                                break;
                            }
                            if (value) {
                                chunks.set(value, at);
                                at += value.length;
                            }
                        }
                    }
                } else {
                    console.log("FETCH error - no reader")
                }

                return response.json();
            })
            .catch((error: Error) => {
                return error;
            });
    },

    deleteTranscription: (
        id: number
    ) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}`;

        return fetch(endpoint, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(async response => {
                if (response.status === 404) {
                    console.log("404")
                    return Promise.reject('error 404')
                }
                else if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .catch((error: Error) => {
                return error;
            });
    },

    getTranscriptionAudio: async (
        id: number
    ) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/m4a`;

        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                }
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return await response.json();
        } catch (error) {
            console.log("Session expired");
        }
    },

    getTranscriptionRTAudio: async (
        id: number
    ) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/audio/rt.m3u8`;

        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                }
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return await response.blob();
        } catch (error) {
            console.log("Session expired");
        }
    },

    importTranscriptionSrt: (id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/importsrt`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => {
                return { "status": response.json }
            })
            .catch((error: Error) => {
                console.log("no access");
            });
    },

    getTranscriptionSrt: (id: number, filename: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/srt`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            })
            .catch((error: Error) => {
                console.log("no access");
            });
    },


    getTranscriptionTpJson: (id: number, filename: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/tpjson`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => {
                response.blob().then(blob => {
                    console.log(response)
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            })
            .catch((error: Error) => {
                console.log("no access");
            });
    },

    getTranscriptionDocx: (id: number, filename: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/docx`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            })
            .catch((error: Error) => {
                console.log("no access");
            });
    },


    getTranscriptionM4aForDownload: (id: number, filename: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${id}/m4a`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => response.json())
            .then((n4adownload: M4aDownload) => {
                fetch(n4adownload.url)
                    .then(async response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement("a");
                            a.href = url;
                            a.download = filename;
                            a.click();
                        });
                    })
                    .catch((error: Error) => {
                        console.log("no access");
                    });

            })
    },

    createOrUpdateEdition: (edition: Edition) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/edition`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(edition)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    deleteEdition: (edition: Edition) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/edition/remove`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(edition)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    splitEdition: (splitIndex: number, edition: Edition) => {

        let editionOut = {
            "id": edition.id,
            "editionidentifier_id": edition.editionidentifier_id,
            "enabled": edition.enabled,
            "transcription_id": edition.transcription_id,
            "duration": edition.duration,
            "confidence": edition.confidence,
            "text": edition.text,
            "time": edition.time,
            "speaker_id": edition.speaker_id,
            "previous_word": edition.previousword,
            "next_word": edition.nextword,
            "edition_type": edition.edition_type,
            "word_id": edition.word_id,
            "word": null,
            "is_normalised_sentence": edition.is_normalised_sentence,
            "paragraph_start": edition.paragraph_start,
        }

        let splitEditionRequest = {
            edition: editionOut,
            split_index: splitIndex
        }

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/edition/split`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(splitEditionRequest)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    mergeEditions: (
        edition_a: Edition,
        edition_b: Edition,
        replace_a_fullstop: boolean,
        replace_b_capitalisation: boolean
    ) => {

        let request = {
            edition_a: edition_a,
            edition_b: edition_b,
            replace_a_fullstop: replace_a_fullstop,
            replace_b_capitalisation: replace_b_capitalisation
        }

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/edition/merge`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(request)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    getBatchTranscriptionJobs: async () => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/batch/all`;
        try {
            const res = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    getUsers: async () => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users`;
        try {
            const res = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    getUser: (userId: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/profile/${userId}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getUserOTPSecret: () => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/secret_otp`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getTranscriptionsForUser: (userId: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/transcriptions/${userId}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getTranscriptionsSubmittedByUsers: (page: number, size: number, userIds: string[], clientId?: number, startDate?: string, endDate?: string) => {

        let queryParams = userIds.map(userid => `user_id=${userid}`).join("&")
        queryParams = queryParams + `&page=${page}&size=${size}`;

        if (startDate && endDate) {
            let startDateOut = `start_date=${startDate}`;
            let endDateOut = `end_date=${endDate}`;
            queryParams = [queryParams, startDateOut, endDateOut].join("&")
        }

        if (clientId) {
            queryParams += `&client_id=${clientId}`
        }
        console.log("queryParams", queryParams)

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/batch/users/?${queryParams}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getTranscriptionsSubmittedByUsersMatchingPattern: (page: number, size: number, pattern: string, clientId?: number, startDate?: string, endDate?: string) => {

        let queryParams = `pattern=${pattern}&page=${page}&size=${size}`;

        if (startDate && endDate) {
            let startDateOut = `start_date=${startDate}`;
            let endDateOut = `end_date=${endDate}`;
            queryParams = [queryParams, startDateOut, endDateOut].join("&")
        }
        if (clientId) {
            queryParams += `&client_id=${clientId}`
        }

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/batch/users?${queryParams}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getBatchTranscriptionJobsInDataRange: (startDate?: string, endDate?: string) => {

        // let queryParams = userIds.map(userid => `user_id=${userid}`).join("&")
        // queryParams = queryParams + `&page=${page}&size=${size}`;

        // if (startDate && endDate) {
        //     let startDateOut = `start_date=${startDate}`;
        //     let endDateOut = `end_date=${endDate}`;
        //     queryParams = [queryParams, startDateOut, endDateOut].join("&")
        // }

        // let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/batch/users/?${queryParams}`;
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/jobs/report`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    usersMatchingPattern: (pattern: string, clientId?: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/matching?pattern=${pattern}`;
        if (clientId) {
            endpoint += `&client_id=${clientId}`
        }
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },
    createUser: (user: NewUser) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/new`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    updateUserCredentials: (user: UserUpdate) => {
        console.log(user)
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/update`;
        return fetch(endpoint, {
            method: "PATCH",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getUsersForTranscription: (transcriptionId: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/transcription/${transcriptionId}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    addUserToTranscription: (user_id: number, transcription_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/user/add`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "user_id": user_id,
                "transcription_id": transcription_id,
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },

    removeUserFromTranscription: (user_id: number, transcription_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/user/remove`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "user_id": user_id,
                "transcription_id": transcription_id,
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },

    removeTranscriptionFromUser: (user_id: number, transcription_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/transcriptions/remove`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "user_id": user_id,
                "transcription_id": transcription_id,
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },


    updateUserCanEditTranscription: (user_id: number, can_edit: boolean, transcription_id: number, id: number | null) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/user/permissions`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "id": id,
                "user_id": user_id,
                "transcription_id": transcription_id,
                "can_edit": can_edit
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },

    createSpeaker: (speaker: Speaker, transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/speaker/new`;
        // console.log(endpoint)
        // console.log(speaker)
        // console.log(transcription_id)
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(speaker)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    updateSpeakerForEditions: (speaker: UpdateSpeakerForEditionsEntity) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${speaker.transcription_id}/speaker/update`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(speaker)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    findAndReplaceInEditions: (
        transcriptionId: number,
        requestEntity: FindAndReplaceInEditionsRequest
    ) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcriptionId}/findreplace`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(requestEntity)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getRecentBatchJobs: () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/batch/recent`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    throw new Error(resp.statusText);
                }
                return resp.json();
            })
            .then(data => {
                return data;
            })
            .catch((error: Error) => {
                console.log("invalid login");
                sessionService.finishAppSession()
                return null;
            });
    },

    updateTranscription: (transcription: TranscriptionMeta) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/edit`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(transcription)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getSmTranscriptionJson: (smtx_id: string, transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/batch/job/${smtx_id}/${transcription_id}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `tp_sm_asr_${transcription_id}_${smtx_id}.json`
                    a.click();
                });
            })
            .catch((error: Error) => {
                console.log("no access");
            });
    },

    logUserAction: (request: UserLogEntryRequest) => {

        // let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/useraction`;
        // return fetch(endpoint, {
        //     method: "POST",
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem("token")}`,
        //         "Content-Type": "application/json",
        //         "Access-Control-Allow-Origin": "*",
        //         "Access-Control-Allow-Headers": "*",
        //         "X-Client": "web"
        //     },
        //     body: JSON.stringify(request)
        // })
        //     .then(resp => resp)
        //     .catch((error: Error) => {
        //         throw error
        //     });
    },

    getUserActions: () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/useractions`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(response => response.json())
            .catch((error: Error) => {
                console.log("no access");
            });
    },

    addProjectStatusEntry: (projectStatusEntryOut: ProjectStatusEntryOut, transcription_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/projectstatus/new`;
        console.log("projectStatusEntryOut", projectStatusEntryOut)
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(projectStatusEntryOut)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },


    addProjectStatusType: (newProjectStatusType: ProjectStatusTypeOut) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/projectstatus`;
        console.log("projectStatusEntryOut", newProjectStatusType)
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(newProjectStatusType)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    getProjectStatusTypes: () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/projectstatus`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    updateTranscriptionMetadataItemOrder: async (items: TranscriptionMetadataToReorder[], transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/metadata/reorder`;
        console.log(endpoint)
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(items)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    addNewMetadataItem: async (transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/metadata/new`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    deleteMetadataItem: async (transcription_id: number, metadataentry_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/metadata/delete/${metadataentry_id}`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    updateMetadataItem: async (transcription_id: number, metadataentry: TranscriptionMetadataEntry) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/metadata`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(metadataentry)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    addDefaultMetadataItems: async (transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/metadata/default`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },


    getTranscriptionMetadataItems: async (transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/metadata`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    updateProjectForTranscription: async (transcription_id: number, project_id: number) => {
        let project_for_transcription = {
            "transcription_id": transcription_id,
            "project_id": project_id < 0 ? undefined : project_id
        }
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/project`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(project_for_transcription)
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    createProject: (new_project: ProjectOut) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/project/new`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(new_project)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getProjectsForUser: async () => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/projects`;
        try {
            const res = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    removeProjectFromUser: (user_id: number, project_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/projects/remove`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "user_id": user_id,
                "project_id": project_id,
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getUsersForProject: async (transcriptionId: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/project/${transcriptionId}/users`;
        try {
            const res = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    addUserToProject: async (user_id: number, project_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/project/${project_id}/user/add`;
        let projectUser = {
            "user_id": user_id,
            "project_id": project_id,
        }

        console.log("addUserToProject api ", projectUser)

        try {
            const res = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
                body: JSON.stringify(projectUser)
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    removeUserFromProject: (user_id: number, project_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/project/${project_id}/user/remove`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                "user_id": user_id,
                "project_id": project_id,
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getProjectDocx: async (id: number, filename: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/project/${id}/docx`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                }
            });
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = `proyecto_${filename}.zip`;
                a.click();
            });
        } catch (error) {
            console.log("no access");
        }
    },

    markTranscriptionAsDone: async (transcription_id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/user/done`;

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getDefaultMetadataItems: async () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/metadata/defaults`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(console.log);
    },

    getTutorialVideo: () => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/tutorial-video`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getCustomDictionaries: () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/dictionaries`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    createCustomDictionaries: (customVocabDict: CustomDictionaryBase) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/dictionaries`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(customVocabDict)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    cloneCustomDictionary: (id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/dictionaries/${id}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getCustomVocabulary: (dictId: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/content/${dictId}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    updateCustomVocabContent: (customVocabContent: CustomVocabContentOut) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/content`;
        return fetch(endpoint, {
            method: "PATCH",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(customVocabContent)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    updateCustomSoundsLike: (customSoundsLike: CustomVocabSoundsLikeOut) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/soundslike`;
        return fetch(endpoint, {
            method: "PATCH",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(customSoundsLike)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    addCustomVocabContent: (customVocabContent: CustomVocabContentBase) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/content`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(customVocabContent)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    addCustomSoundsLike: (customSoundsLike: CustomVocabSoundsLikeBase) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/soundslike`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(customSoundsLike)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    deleteCustomVocabContent: (id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/content/${id}`;
        return fetch(endpoint, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res.json())
            .catch(console.log);
    },

    deleteCustomSoundsLike: (id: number) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/custom-vocab/soundslike/${id}`;
        return fetch(endpoint, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getSMConfig: () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/smconfig`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res.json())
            .catch(console.log);
    },

    updateSMConfig: (speechmaticsConfig: SpeechmaticsConfig) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/smconfig`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify(speechmaticsConfig)
        })
            .then(res => res.json())
            .catch(console.log);
    },

    reportIssue: (text: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/report`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                text: text
            })
        })
            .then(res => res.json())
            .catch(console.log);
    },

    updateReceiveTxEmailNotifications: (is_enabled: boolean) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/users/notifications/email/tx/${is_enabled}`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
        })
            .then(res => res.json())
            .catch(console.log);
    },

    getTestUploadLink: () => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/info/test/upload`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res.json())
            .catch((error: Error) => {
                throw error
            });
    },

    decodeEmailFromToken: (token: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/user/password/recover/${token}`;
        return fetch(endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
            .then(res => res)
            .catch((error: Error) => {
                throw error
            });
    },

    updatePassword: (password: string, token: string) => {
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/user/password/recover/${token}`;
        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            },
            body: JSON.stringify({
                password: password
            })
        })
            .then(res => res)
            .catch((error: Error) => {
                throw error
            });
    },

    getClients: async () => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/clients`;
        try {
            const res = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    getClientDetail: async (id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/clients/${id}`;
        try {
            const res = await fetch(endpoint, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    createClient: async (new_client: Client) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/clients`;
        try {
            const res = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
                body: JSON.stringify(new_client)
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    updateClient: async (client: Client) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/clients`;
        try {
            const res = await fetch(endpoint, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
                body: JSON.stringify(client)
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    createGroup: async (new_group: Group) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/groups`;
        try {
            const res = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
                body: JSON.stringify(new_group)
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    updateGroup: async (client: Group) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/groups`;
        try {
            const res = await fetch(endpoint, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
                body: JSON.stringify(client)
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    addUserToGroup: async (group_id: number, user_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/groups/${group_id}/users/${user_id}`;
        try {
            const res = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    updateDictionariesForGroup: async (group_id: number, dictionary_ids: number[]) => {
        let queryParams = dictionary_ids.map(id => `dictionary_id=${id}`).join("&")
        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/groups/${group_id}/dictionaries?${queryParams}`;
        try {
            const res = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    removeUserFromGroup: async (group_id: number, user_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/groups/${group_id}/users/${user_id}`;
        try {
            const res = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    addUserToClient: async (client_id: number, user_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/clients/${client_id}/users/${user_id}`;
        try {
            const res = await fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },

    removeUserFromClient: async (client_id: number, user_id: number) => {

        let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/clients/${client_id}/users/${user_id}`;
        try {
            const res = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*"
                },
            });
            return await res.json();
        } catch (message) {
            return console.log(message);
        }
    },
};

// db.ts
import Dexie, { Table } from 'dexie';

export interface DBTranscription {
    txid: number;
    audiofile: Blob | undefined;
    peaks: string | undefined
}

export class TeampuduDexie extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    transcriptions!: Table<DBTranscription>;

    constructor() {
        super('teampudu-v2');
        this.version(5).stores({
            transcriptions: 'txid, audiofile, peaks' // Primary key and indexed props
        });
    }
}

export const db = new TeampuduDexie();

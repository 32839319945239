import React, { useState, useEffect } from "react";

import API from "../api/API";

import { Table } from "antd";
import { Input } from 'antd';

import { Redirect } from "react-router-dom";

import { Client } from "../types/index";

import CreateClientComponent from "./CreateClientComponent"

type ClientListComponentProps = {
};

const ClientListComponent: React.FC<ClientListComponentProps> = props => {

    const [redirect, setRedirect] = useState<string | null>(null);
    const [clientSearchTerm, setClientSearchTerm] = useState<string | null>(null);
    const [clients, setClients] = useState<Client[]>([]);

    useEffect(() => {
        handleGetClients()
    }, []);


    const handleGetClients = () => {
        API.getClients().then((data: Client[]) => {
            console.log("clients", data)
            setClients(data)
        });
    };

    const handleEditClient = (id: number) => {
        let url = `/clients/profile/${id}`;
        setRedirect(url)
    };

    const dataSource: any = clients
        .sort((a: Client, b: Client) => { return (b.id ?? 0) - (a.id ?? 0) })
        .map((client) => {
            return ({
                key: client.id,
                id: client.id,
                name: client.name,
                company_real_name: client.company_real_name,
                contact: client,
                address: client.address,
                taxid: client.tax_id
            })
        })

    const columns: any = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: (a: any, b: any) => a.id - b.id
        },
        {
            title: "name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "taxid",
            dataIndex: "taxid",
            key: "taxid",
        },
        {
            title: "company_real_name",
            dataIndex: "company_real_name",
            key: "company_real_name",
        },
        {
            title: "address",
            dataIndex: "address",
            key: "adressd",
        }, {
            title: "contact",
            dataIndex: "contact",
            key: "contact",
            render: (client: Client) => (
                <div>
                    <div>{client.contact_name}</div>
                    <div>{client.contact_phone}</div>
                    <div>{client.contact_email}</div>
                </div>
            )
        },

    ];

    const handleOnUpdateClient = (client: Client): Promise<Client> => {
        return new Promise((resolve, reject) => {
            API.createClient(client).
                then((data: Client) => {
                    resolve(data)
                }).catch(e => reject(e))
                .finally(handleGetClients)
        })
    }

    if (redirect) {
        return (<Redirect to={redirect} />)
    }

    let newClient = {
        name: "",
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                paddingRight: "12px"
            }}
        >

            <div className="contentWrapper" style={{ marginTop: "2px" }}>
                <div>
                    <div style={{ paddingBottom: "10px" }}>
                        <CreateClientComponent client={newClient} onSubmit={handleOnUpdateClient} />
                    </div>
                    <Table
                        pagination={{ position: ['bottomLeft'], defaultPageSize: 20 }}
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    handleEditClient(record.id)
                                },
                            };
                        }}
                    />
                </div>
            </div>
        </div>

    );
}

export default ClientListComponent;
import React from "react";
import { Route, RouteComponentProps, RouteProps, Redirect } from "react-router";

type AuthenticatedType = {
  isAuthenticated: boolean;
};

type AuthenticatedRouteProps = RouteProps & AuthenticatedType;

const AuthenticatedRoute: React.SFC<AuthenticatedRouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        return isAuthenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/" push={true} />
        );
      }}
    />
  );
};

export default AuthenticatedRoute;

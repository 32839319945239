import React from "react";
import API from "../api/API";

//@ts-ignore

import { Table, Button } from "antd";
import { Input } from 'antd';
import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";

import {
    User,
    Project
} from "../types/index";

type TranscriptionUsersProps = {
    project: Project;
};

type TranscriptionUsersState = {
    redirect: string | null;
    project: Project;
    projectId: number;
    allUsers: User[];
    id: number | null;
    searchTerm: string | null;
};

type TranscriptionUser = {
    id: number;
    username: string;
    included: boolean
}

export class ProjectUsersComponent extends React.Component<
    TranscriptionUsersProps,
    TranscriptionUsersState
> {

    constructor(props: TranscriptionUsersProps) {
        super(props);

        //@ts-ignore
        console.log("this.props.computedMatch.params", parseInt(this.props.computedMatch.params.id, 10))
        //@ts-ignore
        let projectId = parseInt(this.props.computedMatch.params.id, 10);
        this.state = {
            redirect: null,
            project: props.project,
            projectId: projectId,
            allUsers: [],
            id: null,
            searchTerm: null
        };

        this.handleGetUsers();
    }
    handleGetUsers = () => {
        API.getUsers().then((data: User[]) => {
            console.log("handleGetUsers done", data);
            this.setState({
                allUsers: data
            });
            this.handleGetUsersForProject();
        });
    };

    handleGetUsersForProject = () => {
        console.log("handleGetUsersForProject", this.state.projectId);
        API.getUsersForProject(this.state.projectId).then((data: Project) => {
            console.log("getUsersForTranscription done", data);
            this.setState({
                project: data,
                id: this.state.projectId
            })
        });
    };

    handleRemoveUserFromProject = (userid: number) => {
        if (this.state.id) {
            API.removeUserFromProject(userid, this.state.id).then((data: User[]) => {
                this.setState({
                    project: Object.assign({}, this.state.project, {
                        users: data.map(e => e.id)
                    }),
                })
            });
        }
    };

    handleAddUserToProject = (userid: number) => {
        if (this.state.id) {
            API.addUserToProject(userid, this.state.projectId).then((data: User[]) => {
                this.setState({
                    project: Object.assign({}, this.state.project, {
                        users: data.map(e => e.id)
                    }),
                })
            });
        }
    };

    render() {

        let users: TranscriptionUser[] = []
        if (this.state.allUsers !== undefined) {
            users = this.state.allUsers.map((user) => {
                let included = -1
                if (this.state.project !== undefined) {
                    if (this.state.project.users !== undefined) {
                        included = this.state.project.users.findIndex(incUser => incUser === user.id)
                    }
                }
                return {
                    id: user.id,
                    username: user.username,
                    included: included === -1 ? false : true
                }
            })
        }

        const dataSource: any = users
            .filter(a => a.username.includes(this.state.searchTerm ?? ""))
            .sort((a, b) => a.id - b.id).map((user) => {
                return ({
                    key: user.id,
                    id: user.id,
                    include: user,
                    canEdit: user,
                    username: user.username,
                })
            })

        const columns: any = [
            {
                title: "Id",
                dataIndex: "id",
                key: "id",
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
            },
            {
                title: "Include User",
                dataIndex: "include",
                key: "include",
                render: (user: TranscriptionUser) => {
                    let buttonColour = user.included === true ? PUDU_SECONDARY_COLOUR : PUDU_PRIMARY_COLOUR
                    return (
                        <Button
                            type="primary"
                            style={{
                                background: `${buttonColour}`, borderColor: `${buttonColour}`
                            }}
                            danger={user.included === true}
                            onClick={() => {
                                user.included === true ? this.handleRemoveUserFromProject(user.id) : this.handleAddUserToProject(user.id)
                            }}
                        >
                            {user.included === true ? "Eliminar" : "Agregar"}
                        </Button>
                    )
                }
            },
        ];
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    paddingRight: "12px"
                }}
            >
                <div className="contentWrapper" style={{ marginTop: "2px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            overflow: "hidden",
                            paddingRight: "12px"
                        }}
                    >
                        <div style={{ paddingTop: "6px", paddingLeft: "6px" }}>
                            <h3>Users for project: {this.state.projectId}</h3>
                        </div>
                        <div style={{ flexGrow: 1 }}></div>
                    </div>
                    <div style={{ paddingBottom: "10px" }}>
                        <Input
                            style={{
                                width: 240,
                            }}
                            placeholder="username..."
                            onChange={(text) => {
                                this.setState({ "searchTerm": text.target.value })
                            }}
                            allowClear
                        />
                    </div>
                    <div>
                        <Table
                            pagination={{ position: ['bottomLeft'], pageSize: 20 }}
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

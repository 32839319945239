import React, { useState, useEffect } from "react";
import API from "../api/API";

import { Form, Input, Button, Alert } from "antd";

import "antd/dist/antd.css";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

import { Strings } from "../strings"

import {
    Client,
} from "../types";


const FormItem = Form.Item;

type CreateClientComponentProps = {
    client: Client,
    onSubmit(client: Client): Promise<Client>
};

const CreateClientComponent: React.FC<CreateClientComponentProps> = props => {

    const [client, setClient] = useState<Client>(props.client)

    const [saving, setSaving] = useState<boolean>(false)
    const [showClientCreatedAlert, setShowClientCreatedAlert] = useState<boolean>(false)

    const fieldWidth = 200
    const margin = 2
    const defaultClient = {
        name: ""
    }

    const handleSubmit = (event: React.FormEvent) => {
        setSaving(true)
        if (client) {
            props.onSubmit(client).then(savedClient => {
                console.log("saved client")
                setClient(savedClient)
                setSaving(false)
                setShowClientCreatedAlert(true)
                if (client.id === undefined) {
                    clearInput()
                }
            })
        }
    };

    const clearInput = () => {
        setClient(defaultClient)
    }

    let clientCreatedAlert;

    if (showClientCreatedAlert) {
        clientCreatedAlert = (
            <Alert
                message={client.id === undefined ? `${Strings.strings.clientCreated}` : `${Strings.strings.clientUpdated}`}
                type="success"
                showIcon
                closable
                onClose={() => { setShowClientCreatedAlert(false) }}
                style={{ fontSize: "0.8em" }}
            />
        )
    }

    return (
        <div style={{ "maxWidth": "200px" }}>
            {clientCreatedAlert}
            <Form onFinish={handleSubmit} >
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="nombre"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.name = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.name}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="companyRealName"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.company_real_name = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.company_real_name}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="taxId"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.tax_id = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.tax_id}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="address"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.address = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.address}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="contactName"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.contact_name = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.contact_name}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="contactEmail"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.contact_email = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.contact_email}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="contactPhone"
                        onChange={(e) => {
                            let updated = { ...client }
                            updated.contact_phone = e.currentTarget.value
                            setClient(updated)
                        }}
                        value={client.contact_phone}
                        width={fieldWidth}
                    />
                </FormItem>

                <FormItem style={{ display: "inline-flex", marginTop: margin }}>
                    <Button
                        disabled={saving}
                        type="primary"
                        htmlType="submit"
                        style={{
                            background: PUDU_PRIMARY_COLOUR,
                            borderColor: PUDU_PRIMARY_COLOUR,
                        }}
                        className="login-form-button"
                    >
                        {client.id === undefined ? "Crear cliente" : "Actualizar"}
                    </Button>
                </FormItem>

            </Form>
        </div>
    );

}

export default CreateClientComponent;
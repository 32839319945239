import React, { useState, useEffect } from "react";
import API from "../api/API";

import { Form, Input, Button } from "antd";

import "antd/dist/antd.css";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

import {
    Group,
} from "../types";
import { idText } from "typescript";

const FormItem = Form.Item;

type CreateGroupComponentProps = {
    client_id: number,
    group?: Group,
    onSubmit(group: Group): Promise<Group>
};

const CreateGroupComponent: React.FC<CreateGroupComponentProps> = props => {

    const defaultGroup = {
        name: "",
        client_id: props.client_id,
        custom_dictionaries: []
    }

    const [group, setGroup] = useState<Group>(defaultGroup)
    const [saving, setSaving] = useState<boolean>(false)

    const fieldWidth = 200
    const margin = 2


    useEffect(() => {
        if (props.group !== undefined) {
            setGroup(props.group)
        }
    }, [props.group]);

    const handleSubmit = (event: React.FormEvent) => {
        setSaving(true)
        if (group) {
            props.onSubmit(group).then(group => {
                setGroup(group)
                setSaving(false)
                if (group.id === undefined) {
                    clearInput()
                }
            })
        }
    };

    const clearInput = () => {
        setGroup(defaultGroup)
    }

    return (
        <div style={{ "maxWidth": "200px" }}>
            <Form onFinish={handleSubmit} >
                <FormItem style={{ display: "inline-flex", marginBottom: margin }}>
                    <Input
                        type="name"
                        placeholder="nombre"
                        onChange={(e) => {
                            let updated = { ...group }
                            updated.name = e.currentTarget.value
                            setGroup(updated)
                        }}
                        value={group.name}
                        width={fieldWidth}
                    />
                </FormItem>
                <FormItem style={{ display: "inline-flex", marginTop: margin }}>
                    <Button
                        disabled={saving}
                        type="primary"
                        htmlType="submit"
                        style={{
                            background: PUDU_PRIMARY_COLOUR,
                            borderColor: PUDU_PRIMARY_COLOUR,
                        }}
                        className="login-form-button"
                    >
                        {group.id === undefined ? "Crear grupo" : "Actualizar"}
                    </Button>

                </FormItem>

            </Form>
        </div>
    );

}

export default CreateGroupComponent;
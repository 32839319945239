import React, { useState, useEffect } from "react";

import API from "../api/API";

import { Table, Button } from "antd";
import { Select, Tag } from "antd";
import { Tooltip } from 'antd';
import { Popconfirm, message } from "antd";

import {
    FileOutlined,
    DownloadOutlined,
    InfoCircleOutlined,
    ClockCircleOutlined,
    SyncOutlined,
    DeleteOutlined

} from '@ant-design/icons';

import {
    UserTranscriptionsList,
    BatchTranscriptionJob,
    TranscriptionMetadataEntry,
    ProjectStatusEntry,
    ProjectStatusType,
    Project
} from "../types";

import { Strings } from "../strings"

import UpdateTranscriptionProjectStatus from "./UpdateTranscriptionProjectStatus";

import DataSizesHelpers from "../helpers/DataSizesHelpers";
import TimeHelper from "../helpers/TimeHelper";

import "./UserTranscriptionJobsAdmin.css";

import { BatchTranscriptionJobState } from "../types/enums";

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";

const { Option } = Select;

type UserTranscriptionJobsAdminProps = {
    isAdmin: boolean;
    projectStatusTypes: ProjectStatusType[];
    userTranscriptionsList: UserTranscriptionsList | undefined
    handleDeleteBatchTranscriptionJob: (id: number) => void;
    handleTranscriptionClick: (id: number) => void;
    handleTranscriptionUsersClick: (id: string) => void;
    handleUpdateProjectStatus: (projectStatusTypeId: number, note: string, transcriptionId: number) => void;
    handleChangeTranscriptionProject: (transcriptionId: number, projectId: number) => void;
};

type TranscriptionWithProject = {
    transcription: number,
    project: Project
}

const UserTranscriptionJobsAdmin: React.FC<UserTranscriptionJobsAdminProps> = props => {

    const [selectedProjectStatus, setSelectedProjectStatus] = useState<ProjectStatusEntry | undefined>(undefined)
    const [selectedProjectStatusTranscriptionId, setSelectedProjectStatusTranscriptionId] = useState<number | undefined>(undefined)
    const [userTranscriptionsList, setUserTranscriptionsList] = useState<UserTranscriptionsList | undefined>(undefined)
    const [statusModalVisible, setStatusModalVisible] = useState<boolean>(false)

    useEffect(() => {
        let show = userTranscriptionsList?.batch_jobs?.findIndex(e => e.status?.id === selectedProjectStatus?.id)
        if (show === undefined) {
            setStatusModalVisible(false)
        } else {
            setStatusModalVisible((show ?? -1) >= 0)
        }
    }, [props.userTranscriptionsList]);

    const batchJobs = props.userTranscriptionsList?.batch_jobs ?? []
    const dataSource: any = [...batchJobs]
        .sort((a: BatchTranscriptionJob, b: BatchTranscriptionJob) => {
            return b.transcription_id - a.transcription_id
        })
        .map((batchJob) => {
            let project = props.userTranscriptionsList?.projects.find(project => project.transcriptions.includes(batchJob.transcription_id))
            return ({
                key: batchJob.id,
                // id: batchJob.id,
                // batchid: batchJob.id,
                batchid: batchJob,
                transcriptionId: batchJob,
                filename: batchJob,
                filesize: batchJob.filesize,
                // filehash: batchJob.filehash,
                status: batchJob.status,
                dateCreated: batchJob,
                language: batchJob.language,
                download: batchJob,
                // latestWordTime: TimeHelper.fancyTimeFormat(batchJob.latestWordTime ?? 0),
                duration: TimeHelper.hoursMinutesFormat(batchJob.original_file_duration),
                durationRaw: batchJob.original_file_duration,
                users: batchJob.transcription_id,
                project_status: batchJob,
                project: { "project": project, "transcription": batchJob.transcription_id }
            })
        })

    const columns: any = [
        {
            title: "",
            dataIndex: "transcriptionId",
            key: "transcriptionId",
            render: (batchJob: any) => {
                return (
                    <Popconfirm
                        title={Strings.strings.deleteTranscription}
                        onConfirm={(e: any) => {
                            props.handleDeleteBatchTranscriptionJob(batchJob.id)
                        }}
                        okText={Strings.strings.delete}
                        cancelText={Strings.strings.cancel}
                    >
                        <DeleteOutlined style={{ fontSize: '12px', color: PUDU_PRIMARY_COLOUR }} />
                    </Popconfirm>

                )
            }
        },
        {
            title: "",
            dataIndex: "transcriptionId",
            key: "transcriptionId",
            render: (batchJob: any) => {
                let icon = batchJob.status === BatchTranscriptionJobState.pending ? <ClockCircleOutlined /> : <FileOutlined />
                return (
                    <button
                        style={{ marginRight: "4px", background: "none", border: "none", cursor: "pointer" }}
                        disabled={batchJob.status === BatchTranscriptionJobState.pending}
                        onClick={() => {

                            if (batchJob.status === BatchTranscriptionJobState.pending) {
                                alert("La transcripción se está preparando")
                            } else {
                                props.handleTranscriptionClick(batchJob.transcription_id)
                            }
                        }}
                    >
                        {icon}
                    </button>
                )
            }
        },
        {
            title: Strings.strings.id,
            dataIndex: "batchid",
            key: "batchid",
            sorter: (a: any, b: any) => {
                return b.transcriptionId.transcription_id - a.transcriptionId.transcription_id
            },
            render: (batch: BatchTranscriptionJob) => {
                return (
                    <Tooltip placement="topLeft" title={batch.transcription_id}>
                        <div>
                            {batch.transcription_id}
                        </div>
                    </Tooltip>
                )
            },
        },
        {
            title: "",
            dataIndex: "users",
            key: "users",
            visible: props.isAdmin,
            render: (transcriptionId: string) => (
                <Button
                    style={{ background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR }}
                    type="primary"
                    size="small"
                    onClick={() => props.handleTranscriptionUsersClick(transcriptionId)}
                >
                    Configuración
                </Button>
            )
        },
        {
            title: Strings.strings.file,
            dataIndex: "filename",
            key: "filename",
            sorter: (a: any, b: any) => a.filename.original_file_name.localeCompare(b.filename.original_file_name),
            render: (record: BatchTranscriptionJob) => {
                return (
                    <div>
                        <div style={{ fontSize: "0.8em" }}>{record.original_file_name}</div>
                        <div style={{ fontSize: "0.8em" }}>Título: {record.batchjob_transcription.title}</div>
                        <div style={{ fontSize: "0.8em" }}>Descripción: {record.batchjob_transcription.description}</div>
                    </div>
                )
            }
        },
        {
            title: Strings.strings.size,
            dataIndex: "filesize",
            key: "filesize",
            sorter: (a: any, b: any) => {
                return b.filesize - a.filesize
            },
            render: (filesize: number) => DataSizesHelpers.bytesToSize(filesize)
        },
        {
            title: Strings.strings.processingState,
            dataIndex: "status",
            key: "status",
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
            render: (record: BatchTranscriptionJobState) => {
                switch (record) {
                    case BatchTranscriptionJobState.processing:
                        return (<Tag icon={<SyncOutlined spin />} color={PUDU_SECONDARY_COLOUR}>
                            {Strings.strings.processing}
                        </Tag>)
                    case BatchTranscriptionJobState.done:
                        return (<Tag color={PUDU_PRIMARY_COLOUR}>
                            {Strings.strings.done}
                        </Tag>)
                    case BatchTranscriptionJobState.pending:
                        return (<Tag>
                            {Strings.strings.generating}
                        </Tag>)
                    case BatchTranscriptionJobState.postReview:
                        return (<Tag>
                            {Strings.strings.inReview}
                        </Tag>)

                }
            }
        },
        {
            title: Strings.strings.duration,
            dataIndex: "duration",
            key: "duration",
            sorter: (a: any, b: any) => {
                return b.durationRaw - a.durationRaw
            }
        },
        // {
        //     title: "Progreso",
        //     dataIndex: "latestWordTime",
        //     key: "latestWordTime",
        //     sorter: (a: any, b: any) => a.latestWordTime.localeCompare(b.latestWordTime),
        // },
        {
            title: Strings.strings.language,
            dataIndex: "language",
            key: "language",
            sorter: (a: any, b: any) => a.language.localeCompare(b.language),
        },
        {
            title: Strings.strings.project,
            dataIndex: "project",
            key: "project",
            render: (project: TranscriptionWithProject | undefined) => {
                let options = props.userTranscriptionsList?.projects.map(aproject => {
                    return (<Option key={aproject.id} value={aproject.id}>{aproject.id}: {aproject.name}</Option>)
                }).reverse()
                let nothing = (<Option value="-1" key="-1">------</Option>);
                options?.splice(0, 0, nothing)
                return (
                    <Select
                        style={{ width: 200 }}
                        defaultValue={project?.project?.id ?? "-1"}
                        onChange={(e: any) => {
                            if (project?.transcription) {
                                props.handleChangeTranscriptionProject(project.transcription, e)
                            }
                        }}
                    >
                        {options}
                    </Select>
                )
            }
        },
        {
            title: Strings.strings.creationDate,
            dataIndex: "dateCreated",
            key: "dateCreated",
            sorter: (a: any, b: any) => a.dateCreated.time_created.localeCompare(b.dateCreated.time_created),
            render: (record: BatchTranscriptionJob) => {
                let dateString = record.time_created.replace(/ /g, "T") //fix for Safari
                let dateItem = new Date(dateString);
                dateItem.setHours(dateItem.getHours() - dateItem.getTimezoneOffset() / 60);
                const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/Santiago' };
                let localDate = dateItem.toLocaleDateString('es-CL', options)
                return localDate
            },
        },
        // {
        //     title: Strings.strings.status,
        //     dataIndex: "project_status",
        //     key: "project_status",
        //     render: (job: BatchTranscriptionJob | undefined) => {
        //         let status: ProjectStatusEntry | undefined = job?.project_status

        //         let statusDiv = (
        //             <div>
        //                 <div>
        //                     status: {status?.status.name ?? "-"}
        //                 </div>
        //                 <div>
        //                     notes: {status?.note ?? "-"}
        //                 </div>
        //                 <div>
        //                     last updated: {status?.time_created ?? "-"}
        //                 </div>
        //                 <div>
        //                     user: {status?.user_id ?? "-"}
        //                 </div>
        //             </div>
        //         )

        //         let statusButton = (
        //             <Popover content={statusDiv} title={null}>
        //                 <div
        //                     style={{ cursor: "pointer" }}
        //                     onClick={() => {
        //                         setSelectedProjectStatus(status)
        //                         setSelectedProjectStatusTranscriptionId(job?.transcription_id)
        //                         setStatusModalVisible(true)
        //                     }}
        //                 >
        //                     {status?.status?.name ?? "-"}
        //                 </div>
        //             </Popover>
        //         )
        //         return statusButton
        //     }
        // },
        {
            title: Strings.strings.download,
            dataIndex: "download",
            key: "download",
            render: (batchTranscriptionJob: BatchTranscriptionJob) => {
                let metadata = batchTranscriptionJob.metadata;
                let filename = `tx${batchTranscriptionJob.transcription_id}_${batchTranscriptionJob.batchjob_transcription.title}`
                if (metadata) {
                    let audienceType = metadata.find(entry => entry.key === "Tipo audiencia")
                    let declarante = metadata.find(entry => entry.key === "Declarante")
                    if (audienceType?.text && declarante?.text) {
                        filename = `tx${batchTranscriptionJob.transcription_id}_${audienceType.text ?? ""}_${declarante.text ?? ""}`
                    }
                }
                let srt;
                if (props.isAdmin) {
                    srt = (
                        <Button
                            style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                API.getTranscriptionSrt(
                                    batchTranscriptionJob.transcription_id,
                                    `${filename}.srt`
                                );
                            }}>
                            <DownloadOutlined /> srt
                        </Button>
                    )
                }
                let downloadButtons = (
                    <div>
                        <Button
                            style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                            type="primary"
                            size="small"
                            disabled={batchTranscriptionJob.status !== BatchTranscriptionJobState.done}
                            onClick={() => {
                                API.getTranscriptionDocx(
                                    batchTranscriptionJob.transcription_id,
                                    `${filename}.docx`
                                );
                            }}>
                            <DownloadOutlined /> {Strings.strings.docx}
                        </Button>
                        <Button
                            style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                API.getTranscriptionM4aForDownload(
                                    batchTranscriptionJob.transcription_id,
                                    `${filename}.m4a`
                                );
                            }}>
                            <DownloadOutlined /> {Strings.strings.m4a}
                        </Button>
                        {srt}
                    </div>
                )
                return downloadButtons
            }
        },
    ].filter(e => e.visible !== false);

    return (
        <div>
            <Table
                pagination={{ position: ['bottomLeft'], defaultPageSize: 20 }}
                dataSource={dataSource}
                columns={columns}
                scroll={{ "x": true }}
                size="small"
                expandable={{
                    expandedRowRender: tx => {
                        let metadataItems = [...tx.batchid.batchjob_transcription.transcriptionmetadataentries]
                            .sort((a: TranscriptionMetadataEntry, b: TranscriptionMetadataEntry) => {
                                return a.order - b.order
                            })
                            .map((item: TranscriptionMetadataEntry) => {
                                return (
                                    <div key={item.id}>
                                        <div style={{ display: "inline-block", width: "200px" }}>{item.key}:</div>
                                        <div style={{ display: "inline-block", width: "200px" }}>{item.text}</div>
                                    </div>
                                )
                            })
                        return (
                            <div>
                                {metadataItems}
                            </div>
                        )
                    },
                    rowExpandable: record => record.name !== "Not Expandable",
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <InfoCircleOutlined onClick={e => onExpand(record, e)} />
                        ) : (
                            <InfoCircleOutlined onClick={e => onExpand(record, e)} />
                        )

                }}
            />
            <UpdateTranscriptionProjectStatus
                projectStatusEntry={selectedProjectStatus}
                projectStatusTypes={props.projectStatusTypes}
                transcription_id={selectedProjectStatusTranscriptionId ?? -1}
                onOk={props.handleUpdateProjectStatus}
                onCancel={() => {
                    setStatusModalVisible(false)
                    setSelectedProjectStatus(undefined)
                }}
                visible={statusModalVisible}
            />
        </div>
    );

}

export default UserTranscriptionJobsAdmin;
class SimpleSegmentMarker {
    constructor(options) {
        this._options = options;
    }

    init(group) {
        this._group = group;
        this.fitToView();
    };
}

SimpleSegmentMarker.prototype.fitToView = function () {
    const height = this._options.layer.getHeight();

    this._line.points([0.5, 0, 0.5, height]);
};

export default SimpleSegmentMarker;

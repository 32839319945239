import React, { useState, useEffect } from "react";

import { FC } from 'react';
import { PUDU_PRIMARY_COLOUR } from "../config/css";
import { Checkbox, Button } from "antd";
import QRModal from "../components/QRModal"
import { UserProfile, UserOTPSecret, VideoLink } from "../types";
import API from "../api/API";
import { sessionService } from "../services";
import { Strings } from "../strings";
import { Card } from 'antd';

import { store } from '../app/store'
import { updateUserProfile } from "../features/UserProfileSlice"

type AccountConfigurationProps = {
    userProfile: UserProfile | null;
}

type UpdateReceiveTxEmailNotifications = {
    enabled: boolean;
}

export const AccountConfiguration: FC<AccountConfigurationProps> = ({ userProfile }) => {

    const [otpSecret, setOtpSecret] = useState<string>("not ready");
    const [otpModalVisible, setOtpModalVisible] = useState<boolean>(false);
    const [receiveTXEmailNotifications, setReceiveTXEmailNotifications] = useState<boolean>(userProfile?.receive_tx_email_notifications ?? false);

    useEffect(() => {
        doGetMe();
    }, []);

    const doGetMe = () => {
        let token = sessionService.getSessionToken();
        API.getCurrentUsersProfile(token).then((userProfile: UserProfile | null) => {
            if (userProfile) {
                store.dispatch(updateUserProfile(userProfile))
                setReceiveTXEmailNotifications(userProfile.receive_tx_email_notifications)
            } else {
            }
        });
    };

    const handleGetOTPSecret = () => {
        API.getUserOTPSecret().then((data: UserOTPSecret) => {
            if (data !== undefined) {
                setOtpSecret(data.otp_secret)
            } else {
                setOtpSecret("")
            }
            setOtpModalVisible(true)
        });
    };

    return (
        <div
            style={{
                paddingLeft: "12px",
                paddingTop: "12px"
            }}
        >
            <h1>{Strings.strings.account}: {userProfile?.username}</h1>
            <div style={{ paddingTop: "10px" }}>
                <Card title="Notificaciones" style={{ width: 400 }}>
                    <div style={{ display: "inline-block" }}>
                        <Checkbox
                            style={{ paddingRight: "16px" }}
                            checked={receiveTXEmailNotifications}
                            onChange={(e) => {
                                API.updateReceiveTxEmailNotifications(e.target.checked)
                                    .then((isEnabled: UpdateReceiveTxEmailNotifications) => {
                                        console.log("isEnabled.is_enabled", isEnabled.enabled)
                                        setReceiveTXEmailNotifications(isEnabled.enabled)
                                    })
                            }}
                        >
                        </Checkbox>
                    </div>
                    <div style={{ display: "inline-block" }}>
                        Recibir un correo electrónico cuando mis transcripciones estén listas.
                    </div>
                </Card>
            </div>

            <div style={{ paddingTop: "10px" }}>

                <Card title="Código de verificación" style={{ width: 400 }}>
                    <div>

                        <p>Sugerimos configurar la aplicación Authy o Google Authenticator para obtener el código de verificación directamente desde tu celular y hacer mas expedito tu ingreso.</p>

                        <p>Para configurarlo, solo debes escanear el código QR desde tu app de autenticación.</p>

                        <Button
                            style={{
                                background: PUDU_PRIMARY_COLOUR,
                                borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            type="primary"
                            size="small"
                            onClick={() => { handleGetOTPSecret() }}
                        >
                            Configuración OTP
                        </Button>

                        <QRModal
                            visible={otpModalVisible}
                            title="OTP QR"
                            otpSecret={otpSecret}
                            onClose={() => setOtpModalVisible(false)}
                        />
                    </div>
                </Card>
            </div>
        </div>



    );
}

export default AccountConfiguration;
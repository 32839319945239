import React, { useState, useEffect } from "react";

import API from "../api/API";

import { Table } from "antd";
import { Input } from 'antd';

import { Redirect } from "react-router-dom";

import {
    User,
} from "../types/index";

type UserListComponentProps = {
    users?: User[]
};

const UserListComponent: React.FC<UserListComponentProps> = props => {

    const [redirect, setRedirect] = useState<string | null>(null);
    const [usernameSearchTerm, setUsernameSearchTerm] = useState<string | null>(null);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        handleGetUsers()
    }, []);

    const handleGetUsers = () => {
        console.log("handleGetUsers")
        API.getUsers().then((data: User[]) => {
            setUsers(data)
        });
    };

    const handleEditUser = (id: number) => {
        let url = `/users/profile/${id}`;
        setRedirect(url)
    };

    const dataSource: any = users
        .filter(a => a.username.includes(usernameSearchTerm ?? "") || a.email.includes(usernameSearchTerm ?? ""))
        .sort((a: User, b: User) => {
            return a.id - b.id
        })
        .map((user) => {
            return ({
                key: user.id,
                id: user.id,
                username: user.username,
                email: user.email,
                admin: user.admin,
                editor: user.editor,
                enabled: user.enabled,
                verified: user.verified
            })
        })
    const columns: any = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: (a: any, b: any) => a.id - b.id,
            render: (recordid: any) => (
                <div style={{ cursor: "pointer" }}>
                    {recordid}
                </div>
            )
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
            sorter: (a: any, b: any) => a.username.localeCompare(b.username),
            render: (record: any) => (
                <div style={{ cursor: "pointer" }}>
                    {record}
                </div>
            )
        },
        {
            title: "email",
            dataIndex: "email",
            key: "email",
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),
        },
        {
            title: "verified",
            dataIndex: "verified",
            key: "verified",
            sorter: (a: any, b: any) => a.verified - b.verified,
            render: (verified: any) => (
                verified === true ? "true" : "false"
            )
        },
        {
            title: "enabled",
            dataIndex: "enabled",
            key: "enabled",
            sorter: (a: any, b: any) => a.enabled - b.enabled,
            render: (enabled: any) => (
                enabled === true ? "true" : "false"
            )
        },
        {
            title: "editor",
            dataIndex: "editor",
            key: "editor",
            sorter: (a: any, b: any) => a.editor - b.editor,
            render: (editor: any) => (
                editor === true ? "true" : "false"
            )
        },
        {
            title: "admin",
            dataIndex: "admin",
            key: "admin",
            sorter: (a: any, b: any) => a.admin - b.admin,
            render: (admin: any) => (
                admin === true ? "true" : "false"
            )
        },

    ];

    if (redirect) {
        return (<Redirect to={redirect} />)
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                paddingRight: "12px"
            }}
        >
            <div className="contentWrapper" style={{ marginTop: "2px" }}>
                <div>
                    <div style={{ paddingBottom: "10px" }}>
                        <Input
                            style={{
                                width: 240,
                            }}
                            placeholder="username..."
                            onChange={(text) => {
                                setUsernameSearchTerm(text.target.value)
                            }}
                            allowClear
                        />
                    </div>
                    <Table
                        pagination={{ position: ['bottomLeft'], defaultPageSize: 20 }}
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    handleEditUser(record.id)
                                }, // click row
                            };
                        }}
                    />
                </div>
            </div>
        </div>

    );
}

export default UserListComponent;

import React, { Component, createRef } from "react";

import { Form, Input, Button, Checkbox, FormInstance, DatePicker } from "antd";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

import { UserFormDataProps } from "../types/index"

import moment from 'moment';

type UseFormProps = {
    userFormData: UserFormDataProps;
    handleSubmitForm: (form: UserFormDataProps) => void;
    title: string;
    requiresPassword: boolean
};

interface UserFormState {
    userFormData: UserFormDataProps;
}

const dateFormat = 'YYYY/MM/DD';

export default class UserFormComponent extends Component<UseFormProps, UserFormState> {

    formRef = createRef<FormInstance>()

    constructor(props: UseFormProps) {
        super(props);
        this.state = {
            userFormData: this.props.userFormData,
        };
    }

    componentWillReceiveProps(nextProps: UseFormProps) {

        console.log("UserFormComponent componentWillReceiveProps", nextProps.userFormData)

        this.setState({
            userFormData: nextProps.userFormData,
        });
        this.formRef.current?.setFieldsValue(this.state)
    }

    handleUsernameInputChange = (event: any) => {
        this.setState({
            userFormData: {
                ...this.state.userFormData,
                username: event.currentTarget.value
            }
        })
    };

    handlePasswordInputChange = (event: any) => {
        this.setState({
            userFormData: {
                ...this.state.userFormData,
                password: event.currentTarget.value
            }
        })
    };

    handleEmailInputChange = (event: any) => {
        this.setState({
            userFormData: {
                ...this.state.userFormData,
                email: event.currentTarget.value
            }
        })
    };

    handleEnabledChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            enabled: event.target.checked
        });
        this.setState({
            userFormData: userdata
        })
    };

    handleEditorChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            editor: event.target.checked
        });
        this.setState({
            userFormData: userdata
        })

    };

    handleVerifiedChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            verified: event.target.checked
        });
        this.setState({
            userFormData: userdata
        })
    };

    handleTrialModeChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            trial_mode: event.target.checked
        });
        this.setState({
            userFormData: userdata
        })
    };

    handleDateChange = (date: moment.Moment | null, dateString: string) => {
        let userdata = { ...this.state.userFormData }
        userdata.trial_expiration_date = moment(date).utc().toISOString();
        this.setState({
            userFormData: userdata
        })
    }

    handleCanProcessFilesChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            can_process_files: event.target.checked
        });
        this.setState({
            userFormData: userdata
        })
    };

    handleCanRealtimeChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            can_realtime: event.target.checked
        });
        this.setState({
            userFormData: userdata
        })
    };

    handleAdminChange = (event: any) => {
        let userdata = Object.assign({}, this.state.userFormData, {
            admin: event.target.checked,
            editor: event.target.checked === true ? true : this.state.userFormData.editor
        });
        this.setState({
            userFormData: userdata
        })
    };

    onFinish = () => {
        this.props.handleSubmitForm(this.state.userFormData)
    }
    render() {

        let form;

        let date = moment().utc()
        if (this.state.userFormData.trial_expiration_date !== undefined && this.state.userFormData.trial_expiration_date !== null) {
            console.log("this.state.userFormData.trial_expiration_date", this.state.userFormData.trial_expiration_date)
            date = moment(this.state.userFormData.trial_expiration_date).utc()
        }

        let datePicker;
        if (this.state.userFormData.trial_mode) {
            datePicker = <DatePicker onChange={this.handleDateChange} format={dateFormat} />
        }

        if (this.state.userFormData) {
            form = (
                <Form
                    ref={this.formRef}
                    initialValues={{
                        username: this.state.userFormData.username,
                        email: this.state.userFormData.email,
                        password: null,
                        enabled: this.state.userFormData.enabled,
                        editor: this.state.userFormData.editor,
                        admin: this.state.userFormData.admin,
                        can_process_files: this.state.userFormData.can_process_files,
                        can_realtime: this.state.userFormData.can_realtime,
                        trial_mode: this.state.userFormData.trial_mode,
                        trial_expiration_date: this.state.userFormData.trial_expiration_date,
                    }}
                    onFinish={this.onFinish}
                    className="login-form"
                >
                    <Form.Item
                        name="username"
                        rules={
                            [
                                { required: true, message: 'Please enter a username' },
                                { type: 'string' }]
                        }
                        style={{ marginBottom: 3 }}
                    >
                        <Input
                            type="username"
                            placeholder="username"
                            onChange={this.handleUsernameInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={
                            [
                                { required: true, message: 'Please enter an email' },
                                { type: 'email' },
                            ]
                        }
                        style={{ marginBottom: 3 }}
                    >
                        <Input
                            type="email"
                            placeholder="email"
                            onChange={this.handleEmailInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        style={{ marginBottom: 4 }}
                        rules={
                            [
                                { required: this.props.requiresPassword, message: 'Please enter a password' },
                            ]
                        }
                    >
                        <Input
                            type="password"
                            placeholder="contraseña"
                            onChange={this.handlePasswordInputChange}
                        />
                    </Form.Item>

                    <Form.Item name="enabled" style={{ marginBottom: 0 }} label="Account enabled" >
                        <Checkbox
                            checked={this.state.userFormData.enabled}
                            onChange={this.handleEnabledChange}
                        >
                            {this.state.userFormData.enabled}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="editor" style={{ marginBottom: 0 }} label="User is Editor">
                        <Checkbox
                            checked={this.state.userFormData.editor}
                            onChange={this.handleEditorChange}
                        >
                            {this.state.userFormData.editor}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="admin" style={{ marginBottom: 0 }} label="User is Admin" >
                        <Checkbox
                            checked={this.state.userFormData.admin}
                            onChange={this.handleAdminChange}
                        >
                            {this.state.userFormData.admin}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="admin" style={{ marginBottom: 0 }} label="User is verified" >
                        <Checkbox
                            checked={this.state.userFormData.verified}
                            onChange={this.handleVerifiedChange}
                        >
                            {this.state.userFormData.verified}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="admin" style={{ marginBottom: 0 }} label="Trial mode" >
                        <Checkbox
                            checked={this.state.userFormData.trial_mode}
                            onChange={this.handleTrialModeChange}
                        >
                            {this.state.userFormData.trial_mode}
                        </Checkbox>
                    </Form.Item>

                    {datePicker}

                    <Form.Item name="admin" style={{ marginBottom: 0 }} label="User can upload & edit" >
                        <Checkbox
                            checked={this.state.userFormData.can_process_files}
                            onChange={this.handleCanProcessFilesChange}
                        >
                            {this.state.userFormData.can_process_files}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="admin" style={{ marginBottom: 0 }} label="User can RT" >
                        <Checkbox
                            checked={this.state.userFormData.can_realtime}
                            onChange={this.handleCanRealtimeChange}
                        >
                            {this.state.userFormData.can_realtime}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            className="login-form-button"
                        >
                            {this.props.title}
                        </Button>
                    </Form.Item>
                </Form>
            )
        }

        return (
            <div>
                {form}
            </div >
        );
    }
}

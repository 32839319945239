import React, { useState } from "react";

import { PendingFile } from "../types";

import { Table } from "antd";
import { Progress } from 'antd';
import { Select } from "antd";
import { Input } from "antd";
import { Result } from "antd";
import { PUDU_PRIMARY_COLOUR } from "../config/css";
import { SyncOutlined } from '@ant-design/icons';
import EditTranscriptionDocsDetailsModal from '../components/EditTranscriptionDocsDetailsModal';

import Button from './StyledComponents/AntDButton'

import {
    TranscriptionMetadataEntry,
    CustomDictionary,
    Group,
    Client,
} from "../types/index";

import "./PendingUploadsTable.css";

import { Strings } from "../strings"
import API from "../api/API";

const { Option } = Select;

type PendingUploadsTableProps = {
    items: PendingFile[];
    isAdmin: boolean;
    uploadEnabled: boolean;
    checkingConnection: boolean;
    customDictionaries: CustomDictionary[];
    groups: Group[];
    clients: Client[];
    processItem: (item: string, withAsr: boolean) => void;
    handleRemovePendingItem: (item: string) => void;
    handlePendingFileTitleUpdate: (uuid: string, title: string) => void;
    handlePendingFileDescriptionUpdate: (uuid: string, description: string) => void;
    handleChangeLanguageForItem: (uuid: string, language: string) => void;
    handleMetadataForItem: (uuid: string, metadata: TranscriptionMetadataEntry[]) => void;
    handleChangeGroupForItem: (uuid: string, groupId?: number) => void;
    handleChangeClientForItem: (uuid: string, clientId: number) => void;
};

const PendingUploadsTable: React.FC<PendingUploadsTableProps> = props => {

    const [metaDataModalVisible, setMetaDataModalVisible] = useState<string | undefined>(undefined);

    const processItem = (id: string, withAsr: boolean = true) => {
        props.processItem(id, withAsr);
    };

    const dataSource = props.items.map((item, index) => {
        return {
            key: index,
            index: index,
            name: item.file.name,
            state: "listo para procesar",
            file: item.file,
            details: item,
            metadata: item,
            uuid: item.uuid,
            language: item.language,
            uploadProgress: item.uploadProgress,
            enabledForProcessing: item.enabledForProcessing,
            title: item.title,
            description: item.description,
            project: item.project_id,
            txid: item.txid,
            uploadComplete: item.uploadComplete,
            uploadPressed: item.uploadPressed,
            client: item.client_id,
            group: item,
        };
    });

    const columns = [
        {
            title: Strings.strings.file,
            dataIndex: "name",
            key: "name",
        },
        // {
        //     title: "uuid",
        //     dataIndex: "uuid",
        //     key: "uuid",
        // },
        {
            title: Strings.strings.progress,
            dataIndex: "uploadProgress",
            key: "uploadProgress",
            render: (record: any) => {
                if (record) {
                    if (record > 0) {
                        return (
                            <Progress percent={record} size="small" />
                        )
                    }
                }
                return ("")
            },
        },
        {
            title: Strings.strings.state,
            dataIndex: "state",
            key: "state",
            render: (record: any) => {
                return (
                    <div>{record}</div>
                )
            },
        },
        {
            title: Strings.strings.project,
            dataIndex: "project",
            key: "project",
        },
        {
            title: Strings.strings.client,
            dataIndex: "",
            key: "client",
            hidden: props.clients.length < 2,
            render: (record: any) => {
                let clients = props.clients.map(client => {
                    if (client.id) {
                        return (
                            <Option key={client.id} value={client.id}>{client.name}</Option>
                        )
                    }
                    return ""
                })
                return (
                    <Select
                        disabled={record.uploadComplete || record.uploadPressed}
                        value={record.client_id}
                        style={{ width: 100 }}
                        size="small"
                        onChange={(e: any) => {
                            props.handleChangeClientForItem(record.uuid, e);
                        }}
                        defaultValue={record.client_id}
                    >
                        {clients}
                    </Select>
                )
            },
        },
        {
            title: Strings.strings.group,
            dataIndex: "",
            key: "group",
            hidden: props.groups.length < 2,
            render: (record: any) => {
                let clients = props.groups.filter(group => group.client_id === record.client).map(group => {
                    if (group.id) {
                        return (
                            <Option key={group.id} value={group.id}>{group.name}</Option>
                        )
                    }
                    return ""
                })
                return (
                    <Select
                        disabled={record.uploadComplete || record.uploadPressed || props.groups.length < 2}
                        style={{ width: 100 }}
                        size="small"
                        onChange={(e: any) => {
                            props.handleChangeGroupForItem(record.uuid, e);
                        }}
                        value={record.group.group_id ?? null}
                    >
                        {clients}
                    </Select>
                )
            },
        },
        {
            title: Strings.strings.language,
            dataIndex: "",
            key: "language",
            render: (record: any) => {
                return (
                    <Select
                        disabled={record.uploadComplete || record.uploadPressed}
                        value={record.language}
                        style={{ width: 100 }}
                        size="small"
                        onChange={(e: any) => {
                            props.handleChangeLanguageForItem(record.uuid, e);
                        }}
                    >
                        <Option value="es">{Strings.strings.spanish}</Option>
                        <Option value="en">{Strings.strings.english}</Option>
                    </Select>
                )
            },
        },
        {
            title: Strings.strings.metadata,
            dataIndex: "",
            key: "metadata",
            render: (record: any) => {

                let visible = false
                if (metaDataModalVisible !== undefined && metaDataModalVisible === record.uuid) {
                    visible = true
                }

                return (
                    <div style={{ marginLeft: "4px" }}>
                        <Button
                            $buttonType="normal"
                            style={{
                                background: PUDU_PRIMARY_COLOUR,
                                borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                setMetaDataModalVisible(record.uuid)
                            }}
                            disabled={record.uploadComplete || record.uploadPressed}
                        >
                            metadata
                        </Button>
                        <EditTranscriptionDocsDetailsModal
                            visible={visible}
                            onOk={(metadataItems) => {
                                props.handleMetadataForItem(record.uuid, metadataItems)
                                setMetaDataModalVisible(undefined)
                            }}
                            onCancel={() => setMetaDataModalVisible(undefined)}
                            tmpMetadataItems={record.tmpMetadataItems}
                            tmpFilename={record.file.name}
                            tmpTitle={record.title}
                            tmpDescription={record.description}
                        />
                    </div>
                )
            },
        },
        {
            title: Strings.strings.info,
            dataIndex: "",
            key: "details",
            render: (record: any) => {

                return (
                    <div>
                        <Input
                            disabled={record.uploadComplete || record.uploadPressed}
                            size="small"
                            placeholder={Strings.strings.titleObligatoryField}
                            onChange={(e) => {
                                props.handlePendingFileTitleUpdate(record.uuid, e.target.value)
                            }}
                            value={record.title}
                        >
                        </Input>
                        <Input
                            disabled={record.uploadComplete || record.uploadPressed}
                            size="small"
                            placeholder={Strings.strings.descriptionObligatoryField}
                            onChange={(e) => {
                                props.handlePendingFileDescriptionUpdate(record.uuid, e.target.value)
                            }}
                            value={record.description}
                        >
                        </Input>
                    </div>
                )
            },
        },
        {
            title: Strings.strings.action,
            dataIndex: "",
            key: "action",
            render: (record: any) => {
                let procesarSinButton;
                if (props.isAdmin) {
                    procesarSinButton = (
                        <Button
                            $buttonType="normal"
                            type="primary"
                            id={record.key}
                            onClick={() => processItem(record.uuid, false)}
                            disabled={!record.enabledForProcessing || record.uploadPressed}
                            style={{ "marginRight": "4px" }}
                        >
                            {Strings.strings.processWithoutASR}
                        </Button>

                    )
                }

                let removeItemButton = (
                    <Button
                        $buttonType={record.uploadComplete ? "normal" : "warning"}
                        type="primary"
                        id={record.key}
                        onClick={() => props.handleRemovePendingItem(record.uuid)}
                    >
                        {record.uploadComplete ? Strings.strings.removeFromTheList : Strings.strings.delete}
                    </Button>
                )

                let uploadButtons = (
                    <div>
                        <Button
                            $buttonType="normal"
                            type="primary"
                            id={record.key}
                            onClick={() => processItem(record.uuid, true)}
                            disabled={!record.enabledForProcessing || record.uploadPressed}
                            style={{ "marginRight": "4px" }}
                        >
                            {props.isAdmin ? Strings.strings.processWithASR : Strings.strings.process}
                        </Button>
                        {procesarSinButton}
                        {removeItemButton}
                    </div>
                )
                let previouslySubmitted = (
                    <div>
                        <div>{Strings.strings.successfullySentWithTXID} {record.txid}</div>
                        <div>{removeItemButton}</div>
                    </div >
                )

                return (
                    record.uploadComplete ? previouslySubmitted : uploadButtons
                )
            }

        },
    ].filter(column => !column.hidden);

    if (props.checkingConnection) {
        return (
            <div style={{ marginLeft: "-40px", padding: "40px" }}>
                <Result
                    status="info"
                    title={Strings.strings.checkingConnection}
                    icon={<SyncOutlined spin />}
                />
            </div>
        );
    }
    else if (props.uploadEnabled) {
        return (
            <div>
                <div style={{ padding: "10px" }}>
                    {Strings.strings.uploadFiles}
                </div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={{ className: "tpPagination" }}
                    locale={
                        {
                            emptyText: <div style={{ minHeight: 1, maxHeight: 1 }}></div>,
                        }
                    }
                />
            </div>
        );
    } else {
        return (
            <div style={{ marginLeft: "-40px", padding: "40px" }}>
                <Result
                    status="warning"
                    title={Strings.strings.uploadFilesDisabled}
                    subTitle={Strings.strings.uploadFilesDisabledSubtitle}
                    extra={
                        <Button
                            $buttonType="normal"
                            type="primary"
                            id={"support"}
                            onClick={() => {
                                API.reportIssue(`user is requesting support with file upload issue.`)
                            }}
                            style={{ "marginRight": "4px" }}
                        >
                            {Strings.strings.pleaseHaveSupportContactMe}
                        </Button>
                    }
                />
            </div>
        );

    }
}

export default PendingUploadsTable;

import React from 'react'

import styled from "styled-components";

import { Button as AntButton } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

import { PUDU_PRIMARY_COLOUR } from "../../config/css";

// enum ButtonType {
//     normal = "normal",
//     warning = "warning"
// }

export interface ButtonProps extends NativeButtonProps {
    $buttonType: string
}

const getButtonType = (props: ButtonProps) => {
    switch (props.$buttonType) {
        case "normal": {
            return PUDU_PRIMARY_COLOUR
        }
        case "warning": {
            return "#FF0000"
        }
        case "empty": {
            return "#FFF"
        }
    }
}

const Button: React.FunctionComponent<ButtonProps> = styled(AntButton)`
    background-color: ${getButtonType};
    border-color: ${getButtonType};
    &:hover {
        border-color: ${getButtonType};
        background-color: ${getButtonType};
    }
`;

export default Button

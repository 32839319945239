import React, { useState, useEffect } from "react";
import { Modal } from 'antd';

import API from "../api/API";

import {
    Transcription,
    TranscriptionMetadataEntry
} from "../types/index";

type TranscriptionInfoModalProps = {
    transcription?: Transcription
    visible: boolean,
    onOk: () => void,
    onCancel: () => void,
}

const TranscriptionInfoModal = (props: TranscriptionInfoModalProps) => {

    const [metadataitems, setMetadataitems] = useState<TranscriptionMetadataEntry[]>([])

    useEffect(() => {
        if (props.transcription?.id) {
            API.getTranscriptionMetadataItems(props.transcription.id).then((data: TranscriptionMetadataEntry[]) => {
                setMetadataitems(data)
            })
        }
    }, [props.transcription]);

    let sortedItems = metadataitems.sort((a, b) => {
        return a.order - b.order
    });

    let metadataItemsDivs = sortedItems.map((item: TranscriptionMetadataEntry) => {
        return (
            <div key={item.id}>
                <div style={{ display: "inline-block", paddingRight: "6px" }}>{item.key}:</div>
                <div style={{ display: "inline-block", fontWeight: "bold" }}>{item.text}</div>
            </div>
        )
    })

    return (
        <Modal
            style={{ top: 20 }}
            closable={false}
            visible={props.visible}
            title="Información"
            onCancel={props.onCancel}
            footer={props.transcription?.id ? [] : undefined}
            onOk={props.onOk}
        >
            <div>
                {metadataItemsDivs}
            </div>
        </Modal >
    );
}

export default TranscriptionInfoModal;
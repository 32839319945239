import React from "react";
import { FC } from 'react';
import { Modal } from 'antd';
import { useQRCode } from 'next-qrcode';
import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";

type QRModalProps = {
    visible: boolean,
    title: string,
    otpSecret: string,
    onClose: () => void,
}

export const QRModal: FC<QRModalProps> = ({
    otpSecret,
    visible,
    onClose
}) => {

    const { Canvas } = useQRCode();

    return (
        <Modal
            style={{ top: 20 }}
            closable={true}
            visible={visible}
            onOk={onClose}
            onCancel={onClose}
            footer={[]}
        // width={800}
        >
            <div>
                <h1> OTP token secreto</h1>
                <div>
                    Escanee este QR con su app de autenticación.
                </div>
            </div>
            <div
                style={{
                    "display": "flex",
                    "flexDirection": "row",
                    "flexWrap": "wrap",
                    "width": "100%",
                    "alignItems": "center",
                    "justifyContent": "center"
                }}>
                <div
                    style={{
                        "display": "flex",
                        "flexDirection": "column",
                        "flexBasis": "100%",
                        "flex": 1,
                    }}>

                    <Canvas
                        text={otpSecret}
                        options={{
                            type: 'image/jpeg',
                            quality: 0.3,
                            margin: 3,
                            scale: 1,
                            width: 260,
                            color: {
                                dark: PUDU_PRIMARY_COLOUR,
                                light: PUDU_SECONDARY_COLOUR,
                            },
                        }}
                    />
                </div>
                <div
                    style={{
                        "display": "flex",
                        "flexDirection": "column",
                        "flexBasis": "100%",
                        "flex": 1,
                        "padding": "20px"
                    }}
                >
                    <div
                        style={{
                            "paddingTop": "20px"
                        }}
                    >
                        Compatible con:
                        <h3><a href="https://authy.com/">Authy</a></h3>
                        <a href="https://authy.com/download/">Descargar</a>
                        <br />
                        iOS / Android / Desktop
                    </div>
                    <div
                        style={{
                            "paddingTop": "20px"
                        }}
                    >
                        <h3>Google Authenticator</h3>
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a>
                        <br />
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a>
                        <br />
                        <a href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en">Chrome</a>
                    </div>
                </div>
            </div>
        </Modal >


    );
}

export default QRModal;
import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";

import { PUDU_PRIMARY_COLOUR } from "../config/css";

type FindAndReplaceComponentProps = {
    itemsForFindAndReplace: number[],
    handleFindAndReplace: (searchTerm: string, replaceTerm: string) => void
    handleSelectAll: () => void
    handleDeselectAll: () => void
    handlUpdateCurrentSearchTerm: (searchTerm: string | undefined) => void
}

const FindAndReplaceComponent: React.FC<FindAndReplaceComponentProps> = props => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [replaceTerm, setReplaceTerm] = useState<string>("");
    const [enabled, setEnabled] = useState<boolean>(false);

    useEffect(() => {
        setEnabled(searchTerm.length > 0 && replaceTerm.length > 0 && props.itemsForFindAndReplace.length > 0)
    }, [searchTerm, replaceTerm, props.itemsForFindAndReplace]);

    return (
        <div
            style={{
                display: "inline-flex",
                // justifyContent: "center",
                width: "800px",
                padding: "4px"
            }}>
            <Button
                type="primary"
                style={{
                    background: PUDU_PRIMARY_COLOUR,
                    borderColor: PUDU_PRIMARY_COLOUR,
                    display: "flex",
                    marginRight: "4px"
                }}
                onClick={props.handleSelectAll}
            >
                Seleccionar todos
            </Button>
            <Button
                type="primary"
                style={{
                    background: PUDU_PRIMARY_COLOUR,
                    borderColor: PUDU_PRIMARY_COLOUR,
                    display: "flex",
                    marginRight: "4px"
                }}
                onClick={props.handleDeselectAll}
            >
                Seleccionar ninguno
            </Button>
            <Input
                style={{ display: "flex" }}
                type="searchTerm"
                placeholder="buscar"
                onChange={(t) => {
                    setSearchTerm(t.target.value)
                    props.handlUpdateCurrentSearchTerm(t.target.value)
                }}
            />
            <Input
                style={{ display: "flex" }}
                type="replaceTerm"
                placeholder="reemplazar"
                onChange={(t) => { setReplaceTerm(t.target.value) }}
            />
            <Button
                type="primary"
                style={{
                    background: PUDU_PRIMARY_COLOUR,
                    borderColor: PUDU_PRIMARY_COLOUR,
                    display: "flex",
                    marginLeft: "4px"
                }}
                disabled={!enabled}
                onClick={() => props.handleFindAndReplace(searchTerm, replaceTerm)}
            >
                Go!
            </Button>
        </div>
    )
}


export default FindAndReplaceComponent;


import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Modal } from "antd";
import { Select } from "antd";

import "antd/dist/antd.css";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

const FormItem = Form.Item;
const { Option } = Select;


type UploadSMJsonProps = {
    visible: boolean;
    handleClose: () => void;
    handleReload: () => void;
    transcriptionId: number
};

type UploadSMJsonState = {
    visible: boolean;
    title?: string;
    description?: string;
    errors?: {
        title?: string;
        description?: string;
    };
    redirectToReferrer: boolean;
    file: File | null,
    language: string
};

export class UploadSMJson extends Component<
    UploadSMJsonProps,
    UploadSMJsonState
> {
    constructor(props: UploadSMJsonProps) {
        super(props);
        this.state = {
            visible: props.visible,
            redirectToReferrer: false,
            file: null,
            language: "es"
        };
    }

    handleOnChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                file: event.target.files[0]
            });
        }
    };


    handleSubmit = (event: React.FormEvent) => {

        if (this.state.file && this.state.language) {
            let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${this.props.transcriptionId}/importsmjson`;

            console.log("submit", endpoint);

            let formData = new FormData();
            formData.append("file", this.state.file);
            formData.append("filename", this.state.file.name);
            formData.append("name", this.state.file.name);
            formData.append("content_type", this.state.file.type);
            formData.append("language", this.state.language);
            formData.append("transcription_id", this.props.transcriptionId.toString());


            var request = new XMLHttpRequest();
            request.open("POST", endpoint);
            request.setRequestHeader("Accept", "application/json");
            request.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
            request.setRequestHeader("Access-Control-Allow-Origin", "*");
            request.setRequestHeader("Access-Control-Allow-Headers", "*");

            request.upload.onloadstart = (e) => {
                console.log("onloadstart")
            }
            request.upload.onprogress = (e) => {
                var percentComplete = Math.ceil((e.loaded / e.total) * 100);
                console.log(percentComplete)
            }

            let self = this;
            request.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    console.log(this.responseText);
                    self.props.handleReload()
                    self.props.handleClose();

                }
            };
            request.send(formData);
        };
    }

    handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        this.setState({
            language: event.target.value
        })
    }

    render() {
        const redirectToReferrer = this.state.redirectToReferrer;
        if (redirectToReferrer === true) {
            return <Redirect to="/" />;
        }
        return (
            <Modal title="Import" closable={true} visible={this.props.visible} onOk={this.props.handleClose} onCancel={this.props.handleClose} >
                <div>
                    <Form onFinish={this.handleSubmit} className="new_transcription-form">
                        <FormItem>
                            <Input
                                type="file"
                                placeholder="file"
                                onChange={this.handleOnChangeFile}
                                value={this.state.title}
                                accept=".json"
                            />
                        </FormItem>

                        <FormItem>
                            <Select
                                defaultValue={"es"}
                                style={{ width: 100 }}
                                onChange={(e: any) => {
                                    this.setState({
                                        language: e
                                    })
                                }}
                            >
                                <Option value="es">Español</Option>
                                <Option value="en">Inglés</Option>
                            </Select>
                        </FormItem>

                        <FormItem>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                                }}
                                disabled={this.state.file === null || this.state.language === null}
                                className="login-form-button"
                            >
                                Import Speechmatics json
                            </Button>
                        </FormItem>

                    </Form>
                </div>
            </Modal>

        );
    }
}

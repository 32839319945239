import React, { useState, FormEvent } from "react";
import { Redirect } from 'react-router-dom'

import { Strings } from "../strings"

import { Form, Input, Button, Alert } from "antd";
import Icon from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import "./LoginComponent.scss";
import "antd/dist/antd.css";

import BrandLogo from "../components/BrandLogo"

import { httpService, sessionService } from "../services";
import API from "../api/API";

import { css } from "@emotion/core";
import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { useEffect } from "react";
import { LoginStep } from "../types/enums"
const FormItem = Form.Item;

type LoginProps = {
  isAuthenticated: boolean;
  email: string;
  loginStep: LoginStep;
  recoverPasswordToken: string | undefined;
  handleLogin: (token: string) => void;
  handleRedirectVerify: (emailIsValid: boolean, email: string) => void;
  handleRedirectToLogin: () => void;
};

const LoginComponent: React.FC<LoginProps> = (props: LoginProps, match) => {

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [otpcode, setOtpcode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showCodeSentAlert, setShowCodeSentAlert] = useState<boolean>(false);
  const [redirectToAccountActivation, setRedirectToAccountActivation] = useState<boolean>(false);
  const [loginStep, setLoginStep] = useState<LoginStep>(props.loginStep);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState<boolean>(false);

  //@ts-ignore
  const [token, setToken] = useState<string | undefined>((props.recoverPasswordToken));

  useEffect(() => {
    console.log(loginStep);
    switch (loginStep) {
      case LoginStep.email: {
        setSubmitButtonEnabled(username.length > 0)
        break
      }
      case LoginStep.password: {
        setSubmitButtonEnabled(username.length > 0 && password.length > 0)
        break
      }
      case LoginStep.otp: {
        setSubmitButtonEnabled(otpcode.length > 0 && username.length > 0 && password.length > 0)
        break
      }
      case LoginStep.verify: {
        setSubmitButtonEnabled(true)
        break
      }
    }
  }, [username, password, otpcode, loginStep])


  const handleSubmitLoginClicked = async (event: FormEvent) => {
    event.preventDefault();

    switch (loginStep) {
      case LoginStep.email:
        return handleSubmitEmail()
      case LoginStep.password:
        return handleSendOtp();
      case LoginStep.otp:
        return handleSubmitVerifyOtp()
      case LoginStep.verify:
        return handleSubmitEmail()
    }

  }

  const handleSubmitEmail = async () => {
    setLoading(true)

    await API.verifyEmail(username)
      .then(resp => {
        setLoading(false)
        if (!resp.ok) {
          alert(Strings.strings.error)
        } else {
          if (resp.status === 200) {
            setLoginStep(LoginStep.otp)
          } else if (resp.status === 201) {
            props.handleRedirectVerify(true, username)
            setRedirectToAccountActivation(true)
          } else if (resp.status === 203) {
            alert(Strings.strings.trialEnded)
          } else {
            alert(Strings.strings.error)
          }
        }
      }).catch((error: Error) => {
        setLoading(false)
        console.log("verify email error", error)
        alert(error)
      })

  };

  const handleSendOtp = async () => {

    if (password.length === 0) {
      alert(Strings.strings.pleaseEnterPassword);
    } else {
      setLoading(true)
      await httpService.loginOtpSend({
        username: username,
        password: password
      })
        .then((loginResult) => {
          setLoading(false)
          if (loginResult) {
            setShowCodeSentAlert(true)
          } else {
            // setLoginStep(LoginStep.password)
            alert(Strings.strings.incorrectInformation);
          }
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
          if (error === 401) {
            alert(Strings.strings.incorrectPassword);
          } else if (error === 403) {
            setRedirectToAccountActivation(true)
          } else {
            alert(Strings.strings.somethingWeirdHappenedTryAgain);
          }
        });
    }

  };

  // const handleSubmitVerifyOtpClicked = async (event: FormEvent) => {
  //   event.preventDefault();
  //   handleSubmitVerifyOtp()
  // };

  const handleSubmitVerifyOtp = async () => {
    setLoading(true)
    await httpService.loginOtpVerify({
      username: username,
      password: password,
      code: otpcode
    })
      .then((loginResult) => {
        setLoading(false)

        if (loginResult) {
          const token = loginResult.access_token;
          sessionService.startInAppSession(token);
          props.handleLogin(token);
        } else {
          setLoginStep(LoginStep.otp)
          setLoading(false)
          alert(Strings.strings.somethingWeirdHappenedTryAgain);
        }
      })
      .catch(error => {
        setLoginStep(LoginStep.otp)
        setLoading(false)
        setOtpcode("")
        if (error === 422) {
          alert(Strings.strings.otpInvalid);
        } else {
          alert(Strings.strings.somethingWeirdHappenedTryAgain);
        }
      });

  };

  const handleRecoverPassword = async () => {
    await API.recoverPassword(username)
      .then(resp => {
        if (!resp.ok) {
          alert(Strings.strings.error)
        } else {

          resp.json()
            .then(json => console.log(json))

          console.log("resp.status", resp.status)
          if (resp.status === 200) {
            alert(Strings.strings.pleaseCheckYourEmail)
          } else if (resp.status === 201) {
            alert(Strings.strings.pleaseCheckYourEmail)
          } else {
            alert(Strings.strings.error)
          }
        }
      }).catch((error: Error) => {
        console.log("recover email error", error)
        alert(error)
      })
  }

  const handleLoginWithDifferentUser = () => {
    setLoginStep(LoginStep.email)
    setLoading(false)
    setOtpcode("")
    setUsername("")
    setPassword("")
    props.handleRedirectToLogin()
  }

  if (props.isAuthenticated) {
    return <Redirect to="/" push={true} />
  } else if (redirectToAccountActivation) {
    return <Redirect
      to={{
        pathname: "/verify/email",
        state: {
          email: ""
        }
      }}
    />
  }

  return (
    <div className="loginContainer">
      <div className="loginCenter">
        <BrandLogo />
        <LoginForm
          showCodeSentAlert={showCodeSentAlert}
          loginStep={loginStep}
          username={username}
          password={password}
          otpcode={otpcode}
          loading={loading}
          submitButtonEnabled={submitButtonEnabled}
          handleChangeEmail={setUsername}
          handleChangePassword={setPassword}
          handleChangeOTP={setOtpcode}
          handleSendOtp={handleSendOtp}
          handleLoginWithDifferentUser={handleLoginWithDifferentUser}
          handleSubmit={handleSubmitLoginClicked}
          handleRecoverPassword={handleRecoverPassword}
          handleSetShowCodeSentAlert={() => setShowCodeSentAlert(false)}
        />
      </div>
    </div>
  );

}

export default LoginComponent;

type LoginFormProps = {
  showCodeSentAlert: boolean,
  loginStep: LoginStep,
  username: string;
  password: string;
  otpcode: string;
  loading: boolean;
  submitButtonEnabled: boolean;
  handleChangeEmail: (e: string) => void;
  handleChangePassword: (e: string) => void;
  handleChangeOTP: (e: string) => void;
  handleSendOtp: () => void;
  handleLoginWithDifferentUser: () => void;
  handleSetShowCodeSentAlert: () => void;
  handleSubmit: (e: FormEvent) => void;
  handleRecoverPassword: () => void;
};

const formBasicStyle = css`
border-radius: 8px;
height: 40px;
`;

const inputStyle = css`
${formBasicStyle};
color: ${PUDU_PRIMARY_COLOUR};
font-size: 16px;
background: #f3f3f3;

* {
  background: #f3f3f3;
}
`;

const buttonStyle = css`
${formBasicStyle};
text-transform: uppercase;
font-weight: 600;
font-size: 15px;
`;

const LoginForm: React.FC<LoginFormProps> = ({
  showCodeSentAlert,
  loginStep,
  username,
  password,
  otpcode,
  loading,
  submitButtonEnabled,
  handleChangeEmail,
  handleChangePassword,
  handleChangeOTP,
  handleSendOtp: handleSendNewOtp,
  handleSubmit,
  handleLoginWithDifferentUser: handleLoginWithDifferentUser,
  handleSetShowCodeSentAlert,
  handleRecoverPassword
}) => {


  let codeSentAlert;

  if (showCodeSentAlert) {
    codeSentAlert = (
      <Alert
        message={`${Strings.strings.theCodeWasSuccessfullySentTo}${username}`}
        type="success"
        showIcon
        closable
        onClose={() => handleSetShowCodeSentAlert()}
        style={{ fontSize: "0.8em" }}
      />
    )
  }

  return (
    <Form className="login-form">
      <FormItem>
        <Input
          css={inputStyle}
          name="username"
          prefix={<Icon type="user" style={{ fontSize: 13 }} />}
          placeholder={Strings.strings.user}
          onChange={(t) => { handleChangeEmail(t.target.value) }}
          value={username}
          disabled={loginStep !== LoginStep.email}
        />
      </FormItem>

      <FormItem
        hidden={(loginStep !== LoginStep.password) && (loginStep !== LoginStep.otp)}
      >
        <Input.Password
          css={inputStyle}
          name="password"
          prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
          type="password"
          placeholder={Strings.strings.password}
          onChange={(e) => handleChangePassword(e.target.value)}
          value={password}
          disabled={(loginStep !== LoginStep.password) && (loginStep !== LoginStep.otp)}
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

        />
      </FormItem>

      <FormItem
        hidden={loginStep !== LoginStep.otp}
      >
        <Button
          disabled={!(loginStep === LoginStep.otp)}
          hidden={loginStep < LoginStep.otp}
          style={{
            fontSize: "12px", width: "100%"
          }}
          type="link"
          onClick={() => { handleSendNewOtp() }}>{Strings.strings.sendCodeToMyEmail}
        </Button>

        <Input
          css={inputStyle}
          name="otpcode"
          prefix={<Icon type="user" style={{ fontSize: 13 }} />}
          placeholder={Strings.strings.code}
          onChange={(e) => handleChangeOTP(e.target.value)}
          value={otpcode}
          disabled={loginStep !== LoginStep.otp}
        />
        {codeSentAlert}

      </FormItem>

      <FormItem>
        <Button
          css={buttonStyle}
          disabled={!submitButtonEnabled}
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
          onClick={handleSubmit}
          loading={loading}
        >
          {Strings.strings.login}
        </Button>
      </FormItem>

      <Button
        hidden={loginStep !== LoginStep.otp}
        style={{ fontSize: "12px", width: "100%", paddingTop: "10px" }}
        type="link"
        onClick={() => { handleRecoverPassword() }}
      >
        {Strings.strings.recoverPassword}
      </Button>

      <Button
        hidden={loginStep < LoginStep.password}
        style={{ fontSize: "12px", width: "100%", paddingTop: "10px" }}
        type="link"
        onClick={() => { handleLoginWithDifferentUser() }}
      >
        {Strings.strings.enterWithAnotherAccount}
      </Button>

    </Form>
  );
};

export default {

    bytesToSize: (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) {
            return 'n/a'
        }

        let k = Math.floor(Math.log(bytes) / Math.log(1024));
        const i = parseInt(k.toString(), 10)

        if (i === 0) {
            return `${bytes} ${sizes[i]})`
        }
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }
}
  
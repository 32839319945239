import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    BatchTranscriptionJob,
    Project,
    ProjectStatusType,
    UserTranscriptionsList,
} from "../types";

interface UserTranscriptionsState {
    batch_jobs: BatchTranscriptionJob[],
    project_status_types: ProjectStatusType[],
    projects: Project[]
}

type BatchTranscriptionJobStatus = {
    id: number;
    transcription_id: number;
    status: string
}

const initialState: UserTranscriptionsState = {
    batch_jobs: [],
    project_status_types: [],
    projects: []
};

export const userTranscriptionsListSlice = createSlice({
    name: "userTranscriptionsList",
    initialState: initialState,
    reducers: {
        updateUserTranscriptionsList: (state, userTranscriptionsList: PayloadAction<UserTranscriptionsList>) => {
            if (userTranscriptionsList.payload.batch_jobs !== undefined) {
                state.batch_jobs = (userTranscriptionsList.payload.batch_jobs ?? [])
            }
            if (userTranscriptionsList.payload.project_status_types !== undefined) {
                state.project_status_types = userTranscriptionsList.payload.project_status_types ?? []
            }
            if (userTranscriptionsList.payload.projects !== undefined) {
                state.projects = userTranscriptionsList.payload.projects ?? []
            }
        },
        updateProjects: (state, projects: PayloadAction<Project[]>) => {
            state.projects = projects.payload ?? []
        },
        updateBatchJobs: (state, batchJobs: PayloadAction<BatchTranscriptionJob[]>) => {
            state.batch_jobs = (batchJobs.payload ?? [])
                .sort((a: BatchTranscriptionJob, b: BatchTranscriptionJob) => {
                    return b.transcription_id - a.transcription_id
                })
        },
        addBatchJob: (state, batchJob: PayloadAction<BatchTranscriptionJob>) => {
            let index = state.batch_jobs.findIndex(existingBatchJob => existingBatchJob.id === batchJob.payload.id)
            if (index === -1) {
                state.batch_jobs.push(batchJob.payload)
            }
        },
        updateStatusForBatchJob: (state, batchTranscriptionJobStatus: PayloadAction<BatchTranscriptionJobStatus>) => {
            let jobs = [...state.batch_jobs]
            let index = jobs.findIndex(batchJob => batchJob.id === batchTranscriptionJobStatus.payload.id)
            if (index >= 0) {
                if (jobs[index].status !== "done") {
                    jobs[index].status = batchTranscriptionJobStatus.payload.status
                    updateBatchJobs(jobs)
                }
            }
        },
        updateStatusForMultipleBatchJobs: (state, batchTranscriptionJobStatus: PayloadAction<BatchTranscriptionJobStatus[]>) => {
            let jobs = [...state.batch_jobs]
            batchTranscriptionJobStatus.payload.forEach(batchJob => {
                let index = jobs.findIndex(item => item.id === batchJob.id)
                if (index >= 0) {
                    jobs[index].status = batchJob.status
                }
            })
            updateBatchJobs(jobs)
        },
    },
});

export const {
    updateStatusForBatchJob,
    updateUserTranscriptionsList,
    updateProjects,
    updateBatchJobs,
    addBatchJob,
    updateStatusForMultipleBatchJobs
} = userTranscriptionsListSlice.actions;

const reducer = userTranscriptionsListSlice.reducer
export default reducer;
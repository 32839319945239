import React, { useState, useEffect } from "react";
import { Modal } from 'antd';

import { Form, Input, Select } from "antd";

import { ProjectStatusEntry, ProjectStatusType } from "../types";
import "antd/dist/antd.css";

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

type UpdateTranscriptionProjectStatusProps = {
    projectStatusEntry?: ProjectStatusEntry;
    projectStatusTypes: ProjectStatusType[];
    transcription_id: number
    onOk: (projectStatusTypeId: number, note: string, transcriptionId: number) => void;
    onCancel: () => void;
    visible: boolean
};


const UpdateTranscriptionProjectStatus: React.FC<UpdateTranscriptionProjectStatusProps> = props => {

    const [note, setNote] = useState<string>("")
    const [projectStatusTypeId, setProjectStatusTypeId] = useState<number>(props.projectStatusEntry?.status?.id ?? -1)
    const [transcription_id, setTranscription_id] = useState<number>(props.transcription_id ?? props.transcription_id)

    useEffect(() => {
        setProjectStatusTypeId(props.projectStatusEntry?.status?.id ?? -1)
        setTranscription_id(props.transcription_id ?? -1)
        if (!props.visible) {
            clearInput()
        }
    }, [props.projectStatusEntry, props.transcription_id, props.visible]);

    const handleSubmit = (event: React.FormEvent) => {
        if (projectStatusTypeId >= 0 && transcription_id !== undefined) {
            props.onOk(projectStatusTypeId, note, transcription_id)
        }
    };

    const clearInput = () => {
        setNote("")
    }

    let statusTypes = props.projectStatusTypes.map(projectType => {
        return (<Option key={projectType.id} value={projectType.id}>{projectType.name}</Option>)
    })

    let title = `Update status for tx: ${transcription_id}`

    return (
        <Modal
            title={title}
            visible={props.visible}
            onCancel={e => {
                clearInput()
                props.onCancel()
            }}
            onOk={handleSubmit}
        >
            <div>
                <Form
                >
                    <FormItem label="Status">
                        <Select
                            value={projectStatusTypeId}
                            onChange={typeId => setProjectStatusTypeId(typeId)}
                        >
                            {statusTypes}
                        </Select>
                    </FormItem>
                    <FormItem label="Notes">
                        <TextArea
                            placeholder="note"
                            onChange={(e) => { setNote(e.currentTarget.value) }}
                            value={note}
                        />
                    </FormItem>
                </Form>
            </div>
        </Modal >
    );

}

export default UpdateTranscriptionProjectStatus;
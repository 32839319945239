import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Select } from "antd";
import "antd/dist/antd.css";
import { SyncOutlined } from '@ant-design/icons';

import API from "../api/API";
import { Language } from "../types/enums";
import {
    Edition,
    Transcription,
    TranscriptionBase,
    Speaker
} from "../types";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

import { sessionService } from "../services";

import TranscriptionWebsocketV2 from "../components/TranscriptionWebsocketV2";
import { Strings } from "../strings";

type CreateRealtimeTranscriptionProps = {};

const { Option } = Select;

const FormItem = Form.Item;

const sampleRate = 44100

const loadPCMWorker = (audioContext: AudioContext) =>
    audioContext.audioWorklet.addModule('/pcmWorker.js')

const getMediaStream = (selectedAudioDeviceId: string) =>
    navigator.mediaDevices.getUserMedia({
        audio: {
            deviceId: selectedAudioDeviceId,
            sampleRate: sampleRate,
            sampleSize: 16,
            channelCount: 1
        },
        video: false
    })

type EditionWithName = {
    text: string,
    speakerName: string
    speakerId: number
}

export const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url: string): (() => void) => () => openInNewTab(url)

const captureAudio = (audioContext: AudioContext, stream: MediaStream, output: (data: any) => void) => {
    const source: MediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream)
    const pcmWorker = new AudioWorkletNode(audioContext, 'pcm-worker', { outputChannelCount: [1] })
    source.connect(pcmWorker)
    pcmWorker.port.onmessage = event => output(event.data)
    pcmWorker.port.start()
}

const CreateRealtimeTranscription: React.FC<CreateRealtimeTranscriptionProps> = props => {

    let timeout: number = 250;
    const languages = [Language.eng, Language.esp]

    const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string | undefined>(undefined);
    const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([]);
    const [title, setTitle] = useState<string>("");
    const [language, setLanguage] = useState<Language>(Language.esp);
    const [canProcess, setCanProcess] = useState<boolean>(false);
    const [rtSessionActive, setrRtSessionActive] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");

    const [editions, setEditions] = useState<Edition[]>([]);
    const [speakers, setSpeakers] = useState<Speaker[]>([]);

    const audioInputRef = useRef<HTMLAudioElement | null>(null)
    const waveformCanvasRef = useRef<HTMLCanvasElement | null>(null)
    const recorderForVisualisation = useRef<MediaRecorder>()

    const audioContextForVisualisation = useRef<AudioContext>()

    const [transcription, setTranscription] = useState<Transcription | undefined>(undefined);

    const websocket = useRef<WebSocket>()

    const transcriptionWebsocket = useRef<TranscriptionWebsocketV2>()

    const audioTestInputRef = useRef<HTMLAudioElement | null>(null)
    const rtSessionActiveRef = useRef<boolean>(false)
    const [websocketAvailable, setWebsocketAvailable] = useState<boolean>(false);
    const websocketAvailableRef = useRef<boolean>(false)
    const editionsRef = useRef<Edition[]>([])

    useEffect(() => {

        navigator.mediaDevices.getUserMedia({
            audio: {
                channelCount: 1
            },
            video: false
        }).then(streams => {
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    devices = devices.filter((d) => d.kind === 'audioinput');
                    setAudioInputs(devices)
                })
                .catch(e => console.log(e));
        })
    }, [])

    useEffect(() => {
        rtSessionActiveRef.current = rtSessionActive
        if (rtSessionActive) {
            setTranscription(undefined)

            if (canProcess) {
                let transcription: TranscriptionBase = {
                    title: title,
                    description: description,
                    docx_titlepage_text: "",
                    docx_header_text: ""
                };
                API.createRTTranscription(transcription, language).then(
                    (transcription: Transcription) => {
                        transcriptionWebsocket.current = new TranscriptionWebsocketV2(
                            transcription.id,
                            handleReceivedTXEditionWebsocketMessage
                        )
                        setTranscription(transcription)
                    }
                );
            }
        } else {
            console.log("stop")
            if (websocketAvailable) {
                let blob = new Blob(["done"]);
                websocket.current?.send(blob)
                websocket.current?.close()
            }
        }
    }, [rtSessionActive])

    useEffect(() => {
        console.log("websocketAvailable", websocketAvailable)
        websocketAvailableRef.current = websocketAvailable
    }, [websocketAvailable])


    useEffect(() => {
        // console.log("transcription:", transcription)
        connect()
    }, [transcription])

    useEffect(() => {
        setCanProcess(
            title.length > 0 &&
            description.length > 0 &&
            audioInputs.length > 0 &&
            selectedAudioDeviceId !== undefined &&
            language !== undefined
        )
    }, [selectedAudioDeviceId, title, description, language,])

    useEffect(() => {

        if (selectedAudioDeviceId) {

            const audioContext = new window.AudioContext({ sampleRate })
            const stream = Promise.all([loadPCMWorker(audioContext), getMediaStream(selectedAudioDeviceId)])
                .then(([_, stream]) => {
                    captureAudio(audioContext, stream, data => {
                        if (rtSessionActiveRef.current) {

                            /*
                                ? Perhaps here we could buffer up the samples into 1 seconds blocks and send them, rather than in tiny packets
                                https://stackoverflow.com/questions/22934925/custom-mediastream
                            */

                            if (websocket.current?.readyState === WebSocket.OPEN) {
                                websocket.current?.send(data)
                            } else {
                                // console.log("ws connecting...")
                            }
                        }
                    })
                    updateWaveformVisualisation(stream)
                    return stream
                })
            return () => {
                stream.then(stream => stream.getTracks().forEach(track => track.stop()))
                audioContext.close()
            }

        }
    }, [selectedAudioDeviceId])

    const handleReceivedTXEditionWebsocketMessage = (stuff: string) => {
        let jsonData = JSON.parse(stuff);
        console.log("TX WS json", jsonData)
        if ((jsonData["msgtype"] === "EDITIONS" || jsonData["msgtype"] === "EDITION") && jsonData["content"] && Array.isArray(jsonData["content"])) {
            let currentEditions = [...editionsRef.current];
            let newEditions = jsonData["content"] as Array<Edition>;
            currentEditions.push(...newEditions)
            editionsRef.current = currentEditions
            setEditions(editionsRef.current)
        } else if (jsonData["msgtype"] === "SPEAKERS" && jsonData["content"] && Array.isArray(jsonData["content"])) {
            // console.log("speakers")
            let speakers = jsonData["content"] as Speaker[];
            setSpeakers(speakers)
        }
    }

    const handleReceivedWebsocketMessage = (stuff: string) => {
        let jsonData = JSON.parse(stuff);
        console.log("jsonData", jsonData)
    }

    const connect = () => {

        if (transcription) {

            const token = sessionService.getSessionToken()
            var connectInterval: NodeJS.Timeout;
            const wsEndpoint = `${process.env.REACT_APP_WS_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/ws/rt/${transcription.id}/${token}/${language}`;

            websocket.current = new WebSocket(wsEndpoint)
            websocket.current.onopen = () => {
                timeout = 250; // reset timer to 250 on open of websocket connection
                clearTimeout(connectInterval); // clear Interval on on open of websocket connection
                setWebsocketAvailable(true)
            };
            websocket.current.onmessage = evt => {
                handleReceivedWebsocketMessage(evt.data)
            };
            websocket.current.onclose = e => {
                console.log(
                    `RT Socket is closed. Reconnect will be attempted in ${Math.min(
                        10000 / 1000,
                        (timeout + timeout) / 1000
                    )} second.`,
                    e.reason
                );

                // timeout = timeout + timeout; //increment retry interval
                // connectInterval = setTimeout(checkWebsocketConnection, Math.min(10000, timeout)); //call check function after timeout
            };
            websocket.current.onerror = err => {
                console.error("RT Socket encountered error: ", err.type, "Closing socket");
                websocket.current?.close();
            };
        } else {
            console.log("no tx")
        }
    }

    // const checkWebsocketConnection = () => {
    //     console.log("RT WS CHECK");
    //     if (!websocket || websocket.current?.readyState === WebSocket.CLOSED) {
    //         connect();
    //     }
    // };

    const updateWaveformVisualisation = (stream: MediaStream) => {
        recorderForVisualisation.current = new MediaRecorder(stream);
        recorderForVisualisation.current.start(100); //slice update freq in ms
        if (audioContextForVisualisation.current === undefined) {
            audioContextForVisualisation.current = new AudioContext();
        }
        const source = audioContextForVisualisation.current.createMediaStreamSource(stream);

        // Waveform generation
        const analyser = audioContextForVisualisation.current.createAnalyser();
        analyser.fftSize = 512;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        source.connect(analyser);

        recorderForVisualisation.current.ondataavailable = function (e) {
            drawWaveformPreview(analyser, bufferLength, dataArray)
        }
    }

    const drawWaveformPreview = (analyser: AnalyserNode, bufferLength: number, dataArray: Uint8Array) => {
        analyser.getByteTimeDomainData(dataArray);
        if (waveformCanvasRef.current !== undefined && waveformCanvasRef.current !== null) {
            let width = waveformCanvasRef.current.width;
            let height = waveformCanvasRef.current.height
            const canvasCtx = waveformCanvasRef.current.getContext("2d");
            if (canvasCtx) {
                canvasCtx.fillStyle = 'rgb(200, 200, 200)';
                canvasCtx.fillRect(0, 0, width, height);
                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
                canvasCtx.beginPath();

                let sliceWidth = width * 1.0 / bufferLength;
                let x = 0;

                for (let i = 0; i < bufferLength; i++) {
                    let v = dataArray[i] / 128.0;
                    let y = v * height / 2;
                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    } else {
                        canvasCtx.lineTo(x, y);
                    }
                    x += sliceWidth;
                }
                canvasCtx.lineTo(width, height / 2);
                canvasCtx.stroke();
            }
        }
    }

    const handleManageSession = (event: React.FormEvent) => {
        setrRtSessionActive(!rtSessionActive)
    };

    let editionsForDivs: EditionWithName[] = []

    editions.forEach(edition => {
        let speaker = speakers.find(speaker => speaker.id === edition.speaker_id)
        let i = editionsForDivs.length - 1;
        let lastItem = editionsForDivs[i];
        if (lastItem === undefined) {
            editionsForDivs.push({
                text: edition.text ?? "",
                speakerName: speaker?.name ?? "",
                speakerId: speaker?.id ?? 0
            })
        } else {
            if (lastItem.speakerId === edition.speaker_id) {
                lastItem.text += edition.text ?? ""
            } else {
                editionsForDivs.push({
                    text: edition.text ?? "",
                    speakerName: speaker?.name ?? "",
                    speakerId: speaker?.id ?? 0
                })
            }
        }

    })

    let editionsDiv = editionsForDivs.map((edition, index) => {
        return (
            <div key={`${index}-entry`}>
                <div key={`${index ?? index}-speaker`} style={{ fontWeight: "bold", fontSize: "0.8em", paddingBottom: "4px" }}>{edition.speakerName}</div>
                <div key={index} style={{ paddingBottom: "4px" }}>{edition.text}</div>
            </div>
        )
    })

    let status;
    if (websocketAvailable && rtSessionActive) {
        status = "ASR activo"
    } else if (!websocketAvailable && rtSessionActive) {
        status = "preparing connection"
    }

    return (
        <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            <h2>Realtime</h2>
            <audio ref={audioTestInputRef} id="player" autoPlay={true} controls hidden={true}></audio>

            <div style={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
                <div style={{ width: "300px" }}>

                    <div>
                        <audio ref={audioInputRef} id="player" controls hidden={true}></audio>
                        <Select
                            allowClear={true}
                            defaultActiveFirstOption={false}
                            placeholder="select audio input"
                            style={{ width: "100%" }}
                            onChange={(e: any) => {
                                setSelectedAudioDeviceId(e);
                            }}
                        >
                            {audioInputs.map((audioInput, index) => {
                                return (<Option key={audioInput.deviceId} value={audioInput.deviceId}>{audioInput.label}</Option>)
                            })}
                        </Select>
                        <canvas ref={waveformCanvasRef} width={300} height={50}></canvas>
                    </div>
                    <Form onFinish={handleManageSession} className="new_transcription-form">
                        <FormItem>
                            <Input
                                type="title"
                                placeholder="title"
                                onChange={e => setTitle(e.target.value)}
                                value={title}
                            />
                        </FormItem>

                        <FormItem>
                            <Input
                                type="description"
                                placeholder="description"
                                onChange={e => setDescription(e.target.value)}
                                value={description}
                            />
                        </FormItem>

                        <FormItem>
                            <Select
                                allowClear={true}
                                defaultActiveFirstOption={false}
                                placeholder="language"
                                style={{ width: "100%" }}
                                // value={language}
                                onChange={(e: any) => {
                                    setLanguage(e);
                                }}
                            >
                                {languages.map((language, index) => {
                                    return (<Option key={language} value={language}>{language}</Option>)
                                })}
                            </Select>
                        </FormItem>

                        <FormItem>
                            <Button
                                disabled={!canProcess}
                                type="primary"
                                htmlType="submit"
                                style={{
                                    background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                                }}
                                className="login-form-button"
                            >
                                {rtSessionActive ? "Finish" : "Create Realtime TX"}
                            </Button>
                        </FormItem>

                    </Form>
                    <div style={{ display: rtSessionActive ? "" : "None", padding: "20px" }}>
                        <div>
                            <SyncOutlined spin />
                        </div>
                        <div>
                            {status}
                        </div>
                    </div>

                </div>
                <div style={{ flex: "1" }}>
                    <h3 >{Strings.strings.transcription}</h3>
                    <div>{transcription?.id}</div>
                    <div>{transcription?.title}</div>
                    <div>{transcription?.description}</div>
                    <h3 hidden={editions.length === 0}>En vivo:</h3>
                    <div style={{
                        overflowY: "scroll",
                        maxHeight: "calc(100vh - 100px )"
                    }}>
                        {editionsDiv}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CreateRealtimeTranscription;
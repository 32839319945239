import React, { useState } from "react";
import { Form, Input, Button } from "antd";

import "antd/dist/antd.css";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

import { Speaker } from "../types";

const FormItem = Form.Item;

type CreateSpeakerProps = {
  transcription_id: number
  createSpeaker: (speaker: Speaker, onSuccess: () => void) => void
};

const CreateSpeaker: React.FC<CreateSpeakerProps> = props => {

  const [name, setName] = useState<string>("")
  const [saving, setSaving] = useState<boolean>(false)

  const handleSubmit = (event: React.FormEvent) => {
    console.log("handle submit")
    setSaving(true)
    let speaker = {
      name: name,
      transcription_id: props.transcription_id
    };
    props.createSpeaker(speaker, clearInput)
  };

  function clearInput() {
    setSaving(false)
    setName("")
  }

  return (
    <div>
      <Form onFinish={handleSubmit} >
        <FormItem style={{ display: "inline-flex" }}>
          <Input
            type="name"
            placeholder="nombre"
            onChange={(e) => { setName(e.currentTarget.value) }}
            value={name}
            suffix={saving ? "guardando..." : ""}
            width={800}
          />
        </FormItem>

        <FormItem style={{ display: "inline-flex" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
            }}
            className="login-form-button"
          >
            Agregar
          </Button>

        </FormItem>

      </Form>
    </div>

  );

}

export default CreateSpeaker;
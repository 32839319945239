class TranscriptionWebsocketV2 {

  ws: WebSocket;
  timeout: number = 250;
  handleReceivedWebsocketMessage: (somestuff: string) => void

  constructor(
    transcriptionId: number,
    handleReceivedWebsocketMessage: (somestuff: string) => void
  ) {
    this.handleReceivedWebsocketMessage = handleReceivedWebsocketMessage;

    const wsEndpoint = `${process.env.REACT_APP_WS_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/ws/transcription/${transcriptionId}`;
    this.ws = new WebSocket(wsEndpoint);
    this.connect();
  }

  connect = () => {

    var connectInterval: NodeJS.Timeout;

    this.ws.onopen = () => {
      this.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    this.ws.onmessage = evt => {
      console.log("TranscriptionWebsocketV2 onmessage")
      this.handleReceivedWebsocketMessage(evt.data);
    };

    this.ws.onclose = e => {
      console.log(
        `TranscriptionWebsocketV2  socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (this.timeout + this.timeout) / 1000
        )} second.`,
        e.reason
      );

      this.timeout = this.timeout + this.timeout; //increment retry interval
      connectInterval = setTimeout(this.checkWebsocketConnection, Math.min(10000, this.timeout)); //call check function after timeout
    };

    this.ws.onerror = err => {
      console.error("TranscriptionWebsocketV2 encountered error: ", err.type, "Closing socket");

      this.ws.close();
    };
  };

  checkWebsocketConnection = () => {
    console.log("TranscriptionWebsocketV2 ws check");
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) this.connect();
  };

}

export default TranscriptionWebsocketV2;
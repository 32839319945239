import React from "react";
import { Modal } from 'antd';


type ShortCutKeysModalProps = {
    visible: boolean,
    onOk: () => void,
    onCancel: () => void
}

function ShortCutKeysModal(props: ShortCutKeysModalProps) {

    return (
        <Modal
            style={{ top: 20 }}
            closable={false}
            visible={props.visible}
            title="Acceso rapido"
            onOk={props.onOk}
            onCancel={props.onCancel}
            footer={[]}
        >

            <p><strong>tab: </strong> play / stop</p>
            <p><strong>shift + enter: </strong> separar regiones donde está el cursor</p>
            <p><strong>enter: </strong> guardar cambios</p>
            <p><strong>flecha arriba y abajo: </strong> para navegar entre regiones</p>
            <p><strong>ctrl + flecha izquierda: </strong> retrocede 1 segundo</p>
            <p><strong>ctrl + flecha derecha: </strong> avanza 1 segundo</p>
            <p><strong>ctrl + shift + flecha izq: </strong> retrocede 5 segundos</p>
            <p><strong>ctrl + shift + flecha der: </strong> avanza 5 segundos</p>
            <p><strong>shift+click: </strong> podrás unir regiones sin necesidad de confirmar.</p>

        </Modal >
    );
}

export default ShortCutKeysModal;
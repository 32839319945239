import React, { useState } from "react";

import { HexColorPicker } from "react-colorful";
import {
    Speaker
} from "../types/index";

import "./TranscriptionSpeakerColourComponent.css"

type TranscriptionSpeakersTableProps = {
    speaker: Speaker;
    transcription_id: number
    createSpeaker: (speaker: Speaker, onSuccess: () => void) => void
};

const TranscriptionSpeakerColourComponent: React.FC<TranscriptionSpeakersTableProps> = props => {

    const [colour, setColour] = useState(props.speaker.colour ?? "#FFF");

    return (
        <div>
            <section className="custom-layout example">
                <HexColorPicker
                    style={{ width: "300px", height: "40px" }}
                    color={colour}
                    onChange={setColour}
                    onMouseUp={() => {
                        props.speaker.colour = colour;
                        console.log("change colour", props.speaker.name)
                        props.createSpeaker(props.speaker, () => { })
                    }}
                />
            </section>
        </div>
    )

};

export default TranscriptionSpeakerColourComponent;
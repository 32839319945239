import React from "react";

import API from "../api/API";

import { Table, Button } from "antd";
import { Row, Col } from "antd";

import { FileOutlined, DownloadOutlined, ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { BatchTranscriptionJob, TranscriptionMetadataEntry } from "../types";
import { PUDU_SECONDARY_COLOUR } from "../config/css";

import { BatchTranscriptionJobState } from "../types/enums";

import DataSizesHelpers from "../helpers/DataSizesHelpers";
import TimeHelper from "../helpers/TimeHelper";

import "./UserTranscriptionJobsAdmin.css";

import { Tooltip } from 'antd';

import { Strings } from "../strings"

type UserTranscriptionJobsClientProps = {
    batchJobs: BatchTranscriptionJob[],
    handlGetBatchTranscriptionJobs: () => void;
    handleTranscriptionClick: (id: number) => void;
    handleTranscriptionUsersClick: (id: string) => void;
};

const UserTranscriptionJobsClient: React.FC<UserTranscriptionJobsClientProps> = props => {

    const dataSource: any = [...props.batchJobs]
        .sort((a: BatchTranscriptionJob, b: BatchTranscriptionJob) => {
            return b.transcription_id - a.transcription_id
        })
        .map((batchJob) => {
            return ({
                key: batchJob.id,
                id: batchJob.id,
                batchid: batchJob,
                transcriptionId: batchJob,
                filename: batchJob,
                filesize: batchJob.filesize,
                dateCreated: batchJob,
                language: batchJob.language,
                download: batchJob,
                duration: TimeHelper.hoursMinutesFormat(batchJob.original_file_duration),
                durationRaw: batchJob.original_file_duration,
            })
        })
    const columns: any = [
        {
            title: "",
            dataIndex: "transcriptionId",
            key: "transcriptionId",
            render: (batchJob: any) => {
                let icon = batchJob.status === BatchTranscriptionJobState.pending ? <ClockCircleOutlined /> : <FileOutlined />
                return (

                    <button
                        style={{ marginRight: "4px", background: "none", border: "none" }}
                        disabled={batchJob.status === BatchTranscriptionJobState.pending}
                        onClick={() => {

                            if (batchJob.status === BatchTranscriptionJobState.pending) {
                                alert("La transcripción se está preparando")
                            } else {
                                props.handleTranscriptionClick(batchJob.transcription_id)
                            }
                        }}
                    >
                        {icon}
                    </button>

                )
            }
        },
        {
            title: Strings.strings.id,
            dataIndex: "batchid",
            key: "batchid",
            sorter: (a: any, b: any) => {
                return b.transcriptionId.transcription_id - a.transcriptionId.transcription_id
            },
            render: (batch: BatchTranscriptionJob) => {
                return (
                    <Tooltip placement="topLeft" title={batch.transcription_id}>
                        <div>
                            {batch.transcription_id}
                        </div>
                    </Tooltip>
                )
            },
        },
        {
            title: Strings.strings.file,
            dataIndex: "filename",
            key: "filename",
            sorter: (a: any, b: any) => a.filename.original_file_name.localeCompare(b.filename.original_file_name),
            render: (record: BatchTranscriptionJob) => {
                return (
                    <div>
                        <div style={{ fontSize: "0.8em" }}>{record.original_file_name}</div>
                        <div style={{ fontSize: "0.8em" }}>Título: {record.batchjob_transcription.title}</div>
                        <div style={{ fontSize: "0.8em" }}>Descripción: {record.batchjob_transcription.description}</div>
                    </div>
                )
            }
        },
        {
            title: Strings.strings.size,
            dataIndex: "filesize",
            key: "filesize",
            sorter: (a: any, b: any) => {
                return b.filesize - a.filesize
            },
            render: (filesize: number) => DataSizesHelpers.bytesToSize(filesize)
        },
        {
            title: Strings.strings.duration,
            dataIndex: "duration",
            key: "duration",
            sorter: (a: any, b: any) => {
                return b.durationRaw - a.durationRaw
            },
        },
        {
            title: Strings.strings.language,
            dataIndex: "language",
            key: "language",
            sorter: (a: any, b: any) => a.language.localeCompare(b.language),
        },
        {
            title: Strings.strings.creationDate,
            dataIndex: "dateCreated",
            key: "dateCreated",
            sorter: (a: any, b: any) => a.dateCreated.time_created.localeCompare(b.dateCreated.time_created),
            render: (record: BatchTranscriptionJob) => {
                let dateString = record.time_created.replace(/ /g, "T") //fix for Safari
                let dateItem = new Date(dateString);
                dateItem.setHours(dateItem.getHours() - dateItem.getTimezoneOffset() / 60);
                const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/Santiago' };
                let localDate = dateItem.toLocaleDateString('es-CL', options)
                return localDate
            },
        },
        {
            title: Strings.strings.download,
            dataIndex: "download",
            key: "download",
            render: (batchTranscriptionJob: BatchTranscriptionJob) => {
                let metadata = batchTranscriptionJob.metadata;
                let filename = `tx${batchTranscriptionJob.transcription_id}_${batchTranscriptionJob.batchjob_transcription.title}`
                if (metadata) {
                    let audienceType = metadata.find(entry => entry.key === Strings.strings.audienceType)
                    let declarante = metadata.find(entry => entry.key === Strings.strings.declarant)
                    if (audienceType?.text && declarante?.text) {
                        filename = `tx${batchTranscriptionJob.transcription_id}_${audienceType.text ?? ""}_${declarante.text ?? ""}`
                    }
                }

                let downloadButtons = (
                    <div>
                        <Button
                            style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                            type="primary"
                            size="small"
                            disabled={batchTranscriptionJob.status !== BatchTranscriptionJobState.done}
                            onClick={() => {
                                API.getTranscriptionDocx(
                                    batchTranscriptionJob.transcription_id,
                                    `${filename}.docx`
                                );
                            }}>
                            <DownloadOutlined /> {Strings.strings.docx}
                        </Button>
                        <Button
                            style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                API.getTranscriptionM4aForDownload(
                                    batchTranscriptionJob.transcription_id,
                                    `${filename}.m4a`
                                );
                            }}>
                            <DownloadOutlined /> {Strings.strings.m4a}
                        </Button>
                        {/* <Button
                            style={{ background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, margin: "2px" }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                API.getTranscriptionSrt(
                                    batchTranscriptionJob.transcription_id,
                                    `${filename}.srt`
                                );
                            }}>
                            <DownloadOutlined /> srt
                        </Button> */}
                    </div>
                )
                return downloadButtons
            }
        },

    ];

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Table
                        pagination={{ position: ['bottomLeft'], defaultPageSize: 20 }}
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                        scroll={{ "x": true }}
                        expandable={{
                            expandedRowRender: tx => {
                                let metadataItems = [...tx.batchid.metadata]
                                    .sort((a: TranscriptionMetadataEntry, b: TranscriptionMetadataEntry) => {
                                        return a.order - b.order
                                    })
                                    .map((item: TranscriptionMetadataEntry) => {
                                        return (
                                            <div key={item.id}>

                                                <div style={{ display: "inline-block", paddingRight: "6px" }}>{item.key}:</div>
                                                <div style={{ display: "inline-block", fontWeight: "bold" }}>{item.text}</div>
                                            </div>
                                        )
                                    })
                                return (
                                    <div>
                                        {metadataItems}
                                    </div>
                                )
                            },
                            rowExpandable: record => record.name !== "Not Expandable",
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <InfoCircleOutlined onClick={e => onExpand(record, e)} />
                                ) : (
                                    <InfoCircleOutlined onClick={e => onExpand(record, e)} />
                                )

                        }}
                    />
                </Col>
            </Row>
        </div>
    );

}

export default UserTranscriptionJobsClient;
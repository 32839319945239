
export enum EditorItemType {
    word = 0,
    edition = 1,
    space = 2
};

export enum EditionEnum {
    new_word = 0,
    existing_word = 1,
}

export enum Language {
    esp = "es",
    eng = "en",
}


export enum BatchTranscriptionJobState {
    pending = "pending",
    processing = "processing",
    postReview = "postReview",
    done = "done",
    failed = "failed",
}


export enum AuthenticationProviderType {
    facebook = 0,
    google = 1,
    twitter = 2,
    linkedin = 3,
};

export enum AutoScrollState {
    nothing = 0,
    scrollToActive = 1,
    scrollToNew = 2
};


export enum AudioLoadingState {
    waiting = 0,
    loading = 1,
    peaksAvailable = 2,
    audioAvailable = 3,
    errorPeaksData = 4,
    errorAudioData = 5
}

export enum EditorInteractionMode {
    readyToReceiveWS = "ready",
    editWords = "edit Words",
    editNotes = "edit notes",
    selecting = "selection"
}

export enum UserAction {
    logged_in = 0,
    logged_out = 1,
    tx_played_audio = 2,
    tx_paused_audio = 3,
    tx_edition_started = 4,
    tx_edition_ended = 5,
    tx_edition_adjusted_in = 6,
    tx_edition_adjusted_out = 7,
    tx_speaker_created = 8,
    tx_speaker_assigned = 9,
    tx_speaker_renamed = 10,
    tx_findreplace_opened = 11,
    tx_findreplace_closed = 12,
    tx_findreplace_executed = 13
}

export enum LoginStep {
    email = 0,
    verify = 1,
    password = 2,
    otp = 3
}


import React, { useCallback } from "react";
import { PendingFile } from "../types";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import { CloudUploadOutlined } from '@ant-design/icons';
import { Strings } from "../strings"

type UploadComponentProps = {
    project_id: number | null;
    handleProcessItem: (item: PendingFile, withAsr: boolean) => void;
};

const UploadComponent: React.FC<UploadComponentProps> = props => {

    const drop = (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File, index: number) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = (e) => {
                let item = {
                    file: file,
                    duration: 0,
                    uuid: uuidv4(),
                    language: "es",
                    uploadPressed: false,
                    title: "",
                    description: "",
                    project_id: props.project_id,
                    metadata: [],
                    enabledForProcessing: false,
                    uploadComplete: false,
                    txid: null,
                    custom_dict_id: null,
                    client_id: undefined,
                    group_id: undefined
                };
                props.handleProcessItem(item, true);
            };
            reader.readAsArrayBuffer(file);
        });

    }

    const onDrop = useCallback(drop, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div><CloudUploadOutlined style={{ fontSize: '48px', color: 'PUDU_PRIMARY_COLOR' }} /></div>
                <p>{Strings.strings.dragYourFilesOrClick}</p>
            </div>
        </section>
    );
}

export default UploadComponent;
export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE"
}

type HttpOptions = {
  url: string;
  body?: HttpBody;
  method: HttpMethod;
  headers?: Record<string, string>;
};

type HttpBody = object | string;

type HttpError = null;

const httpClient = <T>(options: HttpOptions): Promise<T | HttpError> => {
  return new Promise(async (res, rej) => {
    const body = transformBody(options.body);
    fetch(options.url, {
      method: options.method,
      body,
      headers: options.headers || buildHeaders(options.body)
    })
      .then((resp: Response) => {
        if (resp.status >= 200 && resp.status < 300) {
          try {
            const json = resp.json() as unknown as T;
            res(json);
          } catch (e) {
            // console.error(e);
            rej(resp.status);
          }
        } else {
          rej(resp.status);
        }
      })
      .catch((error: Error) => {
        console.error(error);
        rej(error);
      });
  });
}


const transformBody = (body: HttpBody | null | undefined): string => {
  if (typeof body === "string") {
    return body;
  }

  if (typeof body === "object") {
    return JSON.stringify(body);
  }
  return "";
};

const buildHeaders = (
  body: HttpBody | null | undefined
): Record<string, string> => {
  if (body instanceof Object) {
    return { "Content-Type": "application/json" };
  }

  return {
    "Content-Type": "application/json"
  };
};

export default httpClient;

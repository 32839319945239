import React, { useEffect, useState, useRef } from "react";

import { Table, Button, Alert } from "antd";
import { Select } from "antd";
import {
    CustomVocabContent,
    CustomVocabContentOut,
    CustomVocabSoundsLike,
    CustomVocabSoundsLikeBase,
    CustomVocabSoundsLikeOut,
    CustomVocabContentBase,
    CustomDictionaryBase,
    CustomDictionary
} from "../types";
import { DeleteOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons';

import "./UserTranscriptionJobsAdmin.css";

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { Strings } from "../strings";
import API from "../api/API"
import { Form, Input } from "antd";
import { Language } from "../types/enums";

const { Option } = Select;

const FormItem = Form.Item;


type CustomVocabComponentProps = {};

const CustomVocabComponent: React.FC<CustomVocabComponentProps> = props => {

    const [isDuplicatingDictionary, setIsDuplicatingDictionary] = useState<boolean>(false)
    const [showDictionaryDuplicatedAlert, setShowDictionaryDuplicatedAlert] = useState<boolean>(false)

    const [dictionaries, setDictionaries] = useState<CustomDictionary[]>([])
    const [selectedDictionary, setSelectedDictionary] = useState<number | undefined>(undefined)

    const [customWords, setCustomWords] = useState<CustomVocabContent[]>([])

    const [newDictName, setNewDictName] = useState<string | undefined>(undefined)
    const [newDictLanguage, setNewDictLanguage] = useState<Language>(Language.esp)
    const [newWord, setNewWord] = useState<string | undefined>(undefined)

    const [currentItemIsSaving, setCurrentItemIsSaving] = useState<number | undefined>(undefined)
    const [currentSoundsLikeItemSaving, setCurrentSoundsLikeItemSaving] = useState<number | undefined>(undefined)

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)

    const [activeInput, setActiveInput] = useState<number | undefined>();
    const activeInputRef = useRef<Input>(null);

    useEffect(() => {
        fetchCustomDictionaries()
    }, []);

    useEffect(() => {
        console.log("setSelectedDictionary", selectedDictionary)

        if (selectedDictionary) {
            getCustomWords(selectedDictionary)
        }
    }, [selectedDictionary]);

    const getCustomWords = (dictId: number) => {
        API.getCustomVocabulary(dictId)
            .then((value: CustomVocabContent[]) => {
                setCustomWords(value)
            })
    }

    const fetchCustomDictionaries = () => {
        API.getCustomDictionaries()
            .then(data => {
                setDictionaries(data)
            })
    }

    const dataSource: any = [...customWords ?? []]
        .filter(a => {
            if (a.sounds_like && a.sounds_like.length > 0) {
                return a.text.includes(searchTerm ?? "") || a.sounds_like.filter(e => e.text.includes(searchTerm ?? "")).length > 0
            } else {
                return a.text.includes(searchTerm ?? "")
            }
        }
        )
        .sort((a: CustomVocabContent, b: CustomVocabContent) => {
            return a.text.localeCompare(b.text)
        })
        .map((customVocabEntry) => {
            return ({
                id: customVocabEntry.id,
                key: customVocabEntry.id,
                text: customVocabEntry,
                soundsLike: customVocabEntry.sounds_like,
            })
        })
    const columns: any = [
        {
            title: Strings.strings.text,
            dataIndex: "text",
            key: "text",
            sorter: (a: CustomVocabContent, b: CustomVocabContent) => a.text.localeCompare(b.text),
            render: (record: CustomVocabContent) => {

                let isLoading = currentItemIsSaving !== record.id ? "None" : "";
                return (
                    <div
                        key={record.id}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                        <Input
                            ref={activeInput === record.id ? activeInputRef : undefined}
                            style={{ display: "inline", float: "left", paddingRight: "10px" }}
                            // name={metadataEntryKey}
                            onBlur={(e) => {
                                let updatedEntry: CustomVocabContentOut = {
                                    id: record.id,
                                    text: e.target.value
                                }
                                setCurrentItemIsSaving(record.id)
                                API.updateCustomVocabContent(updatedEntry)
                                    .then((updatedItem: CustomVocabContent) => {
                                        console.log("updatedItem", updatedItem)
                                        if (customWords) {
                                            let items = [...customWords];
                                            let index = items.findIndex(e => e.id === updatedEntry.id)
                                            if (index >= 0) {
                                                items[index] = updatedItem
                                                setCustomWords(items)
                                                setCurrentItemIsSaving(undefined)
                                                console.log("updatedItems")
                                            }
                                        }
                                    })
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    activeInputRef.current?.blur()
                                }
                            }}
                            defaultValue={record.text}
                            onFocus={() => setActiveInput(record.id)}
                        />
                        <DeleteOutlined
                            style={{ fontSize: '12px', color: PUDU_SECONDARY_COLOUR }}
                            onMouseUp={() => {
                                API.deleteCustomVocabContent(record.id)
                                    .then(() => {
                                        let newCustomWords = [...customWords ?? []].filter(e => e.id !== record.id);
                                        setCustomWords(newCustomWords)
                                    })
                            }}
                        />
                        <div style={{ display: isLoading, padding: "20px" }}>
                            <SyncOutlined spin />
                        </div>
                    </div>
                )
            }
        }
    ]

    let dictionariesSelect = dictionaries.map(dictionary => {
        return <Option key={dictionary.id} value={dictionary.id}>{dictionary.name}</Option>
    })

    let clientCreatedAlert;

    if (showDictionaryDuplicatedAlert) {
        clientCreatedAlert = (
            <Alert
                message={Strings.strings.dictionaryClonedSuccesfully}
                type="success"
                showIcon
                closable
                onClose={() => { setShowDictionaryDuplicatedAlert(false) }}
                style={{ fontSize: "0.8em" }}
            />
        )
    }

    let relatedToSelectedDictionary = selectedDictionary === undefined ? "" : (
        <div>
            <div>
                <Form
                    onFinish={e => {
                        if (newWord) {
                            let customVocabContent: CustomVocabContentBase = {
                                text: newWord,
                                dictionary_id: selectedDictionary
                            }
                            API.addCustomVocabContent(customVocabContent)
                                .then((newItem: CustomVocabContent) => {
                                    let newCustomWords = [...customWords ?? []];
                                    newCustomWords.push(newItem)
                                    setCustomWords(newCustomWords)
                                    setNewWord(undefined)
                                })
                        }
                    }}
                >
                    <FormItem style={{ display: "inline-flex" }}>
                        <Input
                            type="new_word"
                            placeholder="palabra nueva"
                            onChange={(e) => { setNewWord(e.currentTarget.value) }}
                            value={newWord}
                            // suffix={saving ? "guardando..." : ""}
                            width={300}
                        />
                    </FormItem>

                    <FormItem style={{ display: "inline-flex" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            className="login-form-button"
                        >
                            Agregar
                        </Button>

                    </FormItem>

                </Form>
            </div>

            <div style={{ paddingBottom: "10px" }}>
                <Input
                    style={{
                        width: 240,
                    }}
                    placeholder="buscar..."
                    onChange={(text) => {
                        setSearchTerm(text.target.value)
                    }}
                    allowClear
                />
            </div>

            <div>
                <Button
                    disabled={isDuplicatingDictionary}
                    type="primary"
                    htmlType="submit"
                    style={{
                        background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR, width: 200
                    }}
                    className="login-form-button"
                    onClick={e => {
                        setIsDuplicatingDictionary(true)
                        API.cloneCustomDictionary(selectedDictionary).then(dicts => {
                            setIsDuplicatingDictionary(false)
                            setShowDictionaryDuplicatedAlert(true)
                            setDictionaries(dicts)
                        })
                    }}
                >
                    {Strings.strings.cloneDictionary}
                </Button>
                {clientCreatedAlert}
            </div>

            <Table
                pagination={{ position: ['bottomLeft', 'topRight'], defaultPageSize: 100 }}
                dataSource={dataSource}
                columns={columns}
                size="small"
                expandable={{
                    expandedRowRender: record => {
                        let items = record.soundsLike.map((soundsLike: CustomVocabSoundsLike) => {
                            let isLoading = currentSoundsLikeItemSaving !== record.id ? "None" : "";
                            return (
                                <div
                                    key={`k-${soundsLike.id}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}>
                                    <Input
                                        ref={activeInput === soundsLike.id ? activeInputRef : undefined}
                                        style={{ display: "inline", float: "left", paddingRight: "10px" }}
                                        onBlur={(e) => {
                                            let updatedEntry: CustomVocabSoundsLikeOut = {
                                                id: soundsLike.id,
                                                text: e.target.value
                                            }
                                            setCurrentSoundsLikeItemSaving(soundsLike.id)
                                            API.updateCustomSoundsLike(updatedEntry)
                                                .then((updatedItem: CustomVocabSoundsLike) => {
                                                    if (customWords) {
                                                        let items = [...customWords];
                                                        let itemAtindex = items.find(e => e.id === updatedItem.content_id)
                                                        if (itemAtindex) {
                                                            let soundsLikeAtIndex = (itemAtindex.sounds_like ?? []).find(e => e.id === updatedItem.id)
                                                            if (soundsLikeAtIndex) {
                                                                let updated = { ...updatedItem }
                                                                updated.text = updated.text
                                                                updated.time_updated = updated.time_updated
                                                                soundsLikeAtIndex = updated
                                                                setCustomWords(items)
                                                                setCurrentSoundsLikeItemSaving(undefined)
                                                                console.log("updatedItems")
                                                            }
                                                        }
                                                    }
                                                })
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                activeInputRef.current?.blur()
                                            }
                                        }}
                                        defaultValue={soundsLike.text}
                                        onFocus={() => setActiveInput(soundsLike.id)}
                                    />
                                    <div style={{ display: isLoading, padding: "20px" }}>
                                        <SyncOutlined spin />
                                    </div>
                                    <DeleteOutlined
                                        style={{ fontSize: '12px', color: PUDU_SECONDARY_COLOUR }}
                                        onMouseUp={() => {
                                            API.deleteCustomSoundsLike(soundsLike.id)
                                                .then(() => {
                                                    let newCustomWords = [...customWords ?? []];
                                                    let relatedWord = newCustomWords.find(e => e.id === record.id);

                                                    if (relatedWord) {
                                                        console.log("record.sounds_like", relatedWord.sounds_like)
                                                        let updatedSoundsLikeItems = (relatedWord.sounds_like ?? [])
                                                            .filter((e: CustomVocabSoundsLike) => e.id !== soundsLike.id)
                                                        relatedWord.sounds_like = [...updatedSoundsLikeItems]
                                                        setCustomWords(newCustomWords)
                                                    }
                                                })
                                        }}
                                    />
                                </div>
                            )

                        })
                        return (
                            <div
                                key={`c-${record.id}`}
                                style={{ paddingLeft: "60px" }}
                            >
                                <small>sounds_like:</small>

                                {items}
                                <PlusOutlined
                                    style={{
                                        fontSize: '8px',
                                        color: PUDU_SECONDARY_COLOUR,
                                        cursor: "pointer"
                                    }}
                                    onMouseUp={() => {
                                        console.log(record)
                                        let newSoundsLike: CustomVocabSoundsLikeBase = {
                                            text: "",
                                            content_id: record.id
                                        }
                                        API.addCustomSoundsLike(newSoundsLike)
                                            .then((newItem: CustomVocabSoundsLike) => {
                                                if (customWords) {
                                                    let items = [...customWords];
                                                    let index = items.findIndex(e => e.id === record.id)
                                                    if (index >= 0) {
                                                        items[index].sounds_like?.push(newItem)
                                                        setCustomWords(items)
                                                        console.log("addCustomSoundsLike")
                                                    }
                                                }
                                            })
                                    }}
                                />

                            </div>)
                    }
                }}
            />
            <div>
                Total: {customWords?.length ?? 0} palabras
            </div>
        </div>
    )

    return (
        <div style={{ paddingBottom: "60px" }}>

            <div>
                <Form
                    onFinish={e => {
                        if (newDictName) {
                            let customDict: CustomDictionaryBase = {
                                name: newDictName,
                                language: newDictLanguage
                            }
                            API.createCustomDictionaries(customDict)
                                .then((newItem: CustomDictionary) => {
                                    fetchCustomDictionaries()
                                })
                        }
                    }}
                >
                    <FormItem style={{ display: "inline-flex" }}>
                        <Input
                            type="new_dictionaru"
                            placeholder="diccionario nuevo"
                            onChange={(e) => { setNewDictName(e.currentTarget.value) }}
                            value={newDictName}
                            width={100}
                        />
                    </FormItem>
                    <FormItem style={{ display: "inline-flex" }}>
                        <Select
                            // disabled={record.uploadComplete || record.uploadPressed}
                            value={newDictLanguage}
                            style={{ width: 100 }}
                            onChange={(e: any) => {
                                setNewDictLanguage(e);
                            }}
                        >
                            <Option key={Language.esp} value={Language.esp}>{Strings.strings.spanish}</Option>
                            <Option key={Language.eng} value={Language.eng}>{Strings.strings.english}</Option>
                        </Select>
                    </FormItem>
                    <FormItem style={{ display: "inline-flex" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            className="login-form-button"
                        >
                            Agregar
                        </Button>
                    </FormItem>

                </Form>
            </div>

            <div>
                <Select
                    // disabled={record.uploadComplete || record.uploadPressed}
                    // value={newDictLanguage}
                    allowClear={true}
                    placeholder="select dictionary"
                    style={{ width: 200 }}
                    onChange={(e: any) => {
                        setSelectedDictionary(e);
                    }}
                >
                    {dictionariesSelect}
                </Select>
            </div>

            {relatedToSelectedDictionary}

        </div>
    );

}

export default CustomVocabComponent;
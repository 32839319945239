
import { db } from "../db"


import {
    AudioLoadingState
} from "../types/enums";


export default {

    fetchAudioAndWaveform: async (
        waveformSourceUrl: string,
        transcriptionId: number,
        handleIsFetchingAudio: (isFetchingAudio: AudioLoadingState) => void,
        handleAudioBlob: (blob?: Blob) => void,
        handleWaveformData: (peakdata?: any) => void,
        handleDownloadPeaksProgress: (progress: number, total: number) => void,
        handleDownloadAudioProgress: (progress: number, total: number) => void,
    ) => {

        handleIsFetchingAudio(AudioLoadingState.loading)

        let localTranscription = db.transcriptions.where("txid").equals(transcriptionId).first();
        await localTranscription
            .then(async dbtranscription => {

                if (dbtranscription?.peaks !== undefined) {
                    //use local storage
                    // console.log("peaks local");
                    handleDownloadPeaksProgress(1, 1)
                    handleWaveformData(dbtranscription.peaks)
                }
                else {
                    fetch(waveformSourceUrl, {
                        method: "GET",
                        // headers: {
                        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
                        //     "Content-Type": "application/json",
                        //     "Access-Control-Allow-Origin": "*",
                        //     "Access-Control-Allow-Headers": "*"
                        // }
                    })
                        .then(async response => {
                            if (!response.ok) {
                                console.log("fetch audio peaks error.");
                                handleIsFetchingAudio(AudioLoadingState.errorPeaksData)
                                throw new Error("HTTP error " + response.status);
                            }
                            let reader = response.clone().body?.getReader();
                            if (reader) {
                                const contentLength = response.headers.get("content-length");
                                if (contentLength) {
                                    const chunks = new Uint8Array(Number(contentLength));
                                    let at = 0;
                                    for (; ;) {
                                        const { done, value } = await reader.read();
                                        handleDownloadPeaksProgress(at, Number(contentLength))
                                        if (done) {
                                            console.log("FETCH waveform data... DONE")
                                            break;
                                        }
                                        if (value) {
                                            chunks.set(value, at);
                                            at += value.length;
                                        }
                                    }
                                }
                            } else {
                                console.log("FETCH error - no reader")
                            }
                            return response.json()
                        })
                        .then(normalisedPeaks => {
                            db.transcriptions.where("txid").equals(transcriptionId).first().then(txLocal => {
                                if (txLocal) {
                                    txLocal.peaks = normalisedPeaks
                                    db.transcriptions.put(txLocal).then(peaks => {
                                        handleWaveformData(normalisedPeaks)
                                        handleIsFetchingAudio(AudioLoadingState.peaksAvailable)
                                    }).catch((e) => {
                                        console.log('store auio wave data error', e);
                                    });
                                } else {
                                    db.transcriptions.add(
                                        {
                                            txid: transcriptionId,
                                            peaks: normalisedPeaks,
                                            audiofile: undefined
                                        }
                                    ).then(peaks => {
                                        handleWaveformData(normalisedPeaks)
                                        handleIsFetchingAudio(AudioLoadingState.peaksAvailable)
                                    }).catch((e) => {
                                        console.log('store auio wave data error', e);
                                    });
                                }
                            })
                        })
                        .catch((e) => {
                            console.log('audio fetch error', e);
                            handleIsFetchingAudio(AudioLoadingState.errorPeaksData)
                        });
                }
            })
    }

}
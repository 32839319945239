import httpClient, { HttpMethod } from "./provider";
import { otpSend, otpVerify } from "./endpoints";

import { LoginResponse } from "../../types/index"

type OtpSendResponse = {
  status_code: string;
};
type LoginParams = {
  username: string;
  password: string;
};

type OtpSendParams = {
  username: string;
  password: string
  code: string;
};

export const loginOtpSend = (params: LoginParams): Promise<OtpSendResponse | null> => {
  const formData = Object.entries({ ...params, grant_type: "password" })
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join("&");
  return httpClient<OtpSendResponse>({
    url: otpSend(),
    method: HttpMethod.POST,
    body: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Client": "web"
    }
  });
};

export const loginOtpVerify = (params: OtpSendParams): Promise<LoginResponse | null> => {
  return httpClient<LoginResponse>({
    url: otpVerify(),
    method: HttpMethod.POST,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      "X-Client": "web"
    }
  });
};

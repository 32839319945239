export const TOKEN_KEY = "token";

export const startInAppSession = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
}

export const appSessionExists = (): boolean => {
  return localStorage.getItem(TOKEN_KEY) ? true : false
}

export const finishAppSession = () => {
  localStorage.removeItem(TOKEN_KEY);
}

export const getSessionToken = (): string => {
  return localStorage.getItem(TOKEN_KEY) || '';
}

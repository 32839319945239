
import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';

import API from "../api/API";
import CreateUserFormComponent from "./CreateUserComponent";

import CreateProjectComponent from "../components/CreateProjectComponent";
import TranscriptionStatusListComponent from "../components/TranscriptionStatusListComponent";
import CustomVocabComponent from "../components/CustomVocabComponent";
import ASRConfigComponent from "../components/ASRConfigComponent";
import TranscriptionSubmittedByUserComponent from "../components/TranscriptionSubmittedByUserComponent";
import UserListComponent from "../components/UserListComponent";
import ClientListComponent from "../components/ClientListComponent";

//@ts-ignore
import * as bcrypt from 'bcryptjs';

import { Tabs, Button } from 'antd';

import { Redirect } from "react-router-dom";

import "./UserDashboard.css";
import {
    User,
} from "../types/index";

import {
    Bar,
    BarChart,
    ResponsiveContainer,
} from 'recharts';


const { TabPane } = Tabs;

type AdminDashboardProps = {
    token: string;
};

type BatchJobForReport = {
    id: string;
    original_file_duration: number;
    time_created: string;
}

type BatchJobForReportForGraph = {
    jobCount: number;
    durationTotal: number;
}

const AdminDashboardComponent: React.FC<AdminDashboardProps> = props => {

    const { tab } = useParams<{ tab: string }>();

    const [activeTab, setActiveTab] = useState<string | undefined>(undefined)
    const [redirect, setRedirect] = useState<string | null>(null)
    const [users, setUsers] = useState<User[]>([]);
    const [batchJobForReports, setBatchJobForReports] = useState<BatchJobForReport[]>([]);

    useEffect(() => {
        setActiveTab(tab)
    }, [tab]);


    const handleCreateUser = (username: string, password: string, email: string) => {
        let saltRounds = 10;
        let salt = bcrypt.genSaltSync(saltRounds);
        let hashpassword = bcrypt.hashSync(password, salt);
        let user = {
            username: username,
            password_hash: hashpassword,
            email: email
        };

        API.createUser(user).then((data: User) => {
            //refresh users
            console.log("createUser...")
            setUsers(users)
        });
    };

    let userFormData = {
        username: "",
        password: "",
        email: "",
        enabled: false,
        editor: false,
        admin: false,
        verified: false,
        can_process_files: false,
        can_realtime: false,
        trial_mode: false,
        trial_expiration_date: null
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    let graphData: { [id: number]: BatchJobForReportForGraph } = {};

    batchJobForReports.forEach(item => {
        let dateString = item.time_created.replace(/ /g, "T") //fix for Safari
        let dateItem = new Date(dateString);
        let day = Math.floor(dateItem.getTime() / 86400)

        if (day in graphData) {
            graphData[day] = {
                jobCount: graphData[day].jobCount + 1,
                durationTotal: graphData[day].durationTotal + item.original_file_duration
            }
        } else {
            graphData[day] = {
                jobCount: 1,
                durationTotal: item.original_file_duration
            }
        }

    })

    let data = []

    for (let key in graphData) {
        let item = {
            item: "",
            uv: graphData[key].jobCount,
            pv: graphData[key].jobCount,
            amt: graphData[key].jobCount,
        }
        data.push(item);
    }



    return (
        <div style={{ paddingLeft: "10px" }}>
            <Tabs activeKey={activeTab} onChange={e => setActiveTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab="Users" key="1">
                    <UserListComponent users={users} />
                </TabPane>
                <TabPane tab="Clients" key="clients">
                    <ClientListComponent />
                </TabPane>
                <TabPane tab="Create User" key="2">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "top",
                            overflow: "hidden",
                            paddingRight: "12px"
                        }}
                    >
                        <div style={{ paddingTop: "6px", paddingLeft: "6px", paddingRight: "24px" }}>
                            <CreateUserFormComponent
                                formTitle="Create User"
                                handleCreateUser={handleCreateUser}
                                userFormData={userFormData} />
                        </div>

                        <div style={{ flexGrow: 1 }}></div>
                    </div>
                </TabPane>
                <TabPane tab="Proyectos" key="3">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "top",
                            overflow: "hidden",
                            paddingRight: "12px"
                        }}
                    >
                        <div style={{ paddingTop: "6px", paddingLeft: "6px", paddingRight: "24px" }}>
                            <CreateProjectComponent onCreateProject={() => { }} />
                        </div>

                        <div style={{ flexGrow: 1 }}></div>
                    </div>
                </TabPane>
                <TabPane tab="Project Status" key="4">
                    <TranscriptionStatusListComponent />
                </TabPane>
                <TabPane tab="Jobs by user" key="8">
                    <TranscriptionSubmittedByUserComponent />
                </TabPane>
                <TabPane tab="Custom Dict" key="6">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            paddingRight: "12px",
                            marginBottom: "70px"
                        }}
                    >
                        <div className="contentWrapper" style={{ marginBottom: "20px" }}>
                            <CustomVocabComponent />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="ASR Config" key="7">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            paddingRight: "12px",
                            marginBottom: "70px"
                        }}
                    >
                        <div className="contentWrapper" style={{ marginBottom: "20px" }}>
                            <ASRConfigComponent />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="TX Graph" key="tx_graph">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            paddingRight: "12px",
                            marginBottom: "70px"
                        }}
                    >
                        <div className="contentWrapper" style={{ marginBottom: "100px" }}>
                            <Button
                                type="primary"
                                danger={true}
                                onClick={() => {
                                    API.getBatchTranscriptionJobsInDataRange()
                                        .then((jobs: BatchJobForReport[]) => setBatchJobForReports(jobs))
                                }}
                            >
                                refresh
                            </Button>
                            <div style={{ width: "100%", height: "100%", marginBottom: "100px", padding: "20px" }}>
                                <ResponsiveContainer width="100%" height="90%">
                                    <BarChart width={150} height={40} data={data}>
                                        <Bar dataKey="uv" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default AdminDashboardComponent;
import React, { useState } from "react";

import { Layout, Menu } from "antd";

import {
  DashboardOutlined,
  EditOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";

import logo from "../../images/tp_logo_orange.svg";

import { Link } from "react-router-dom";
import { Strings } from "../../strings";

const { Sider } = Layout;

type Props = {
  logoutHandler: () => void;
  isAdmin: boolean;
  canRT: boolean;
  username: String
};

const Sidebar: React.FC<Props> = ({ logoutHandler, isAdmin, username, canRT }) => {
  const INIT_STATE = true;
  const [collapsed, setCollapsed] = useState(INIT_STATE);
  console.log("canRT", canRT)
  return (
    <Sider
      collapsible
      defaultCollapsed={INIT_STATE}
      collapsed={collapsed}
      onCollapse={newState => {
        setCollapsed(newState);
      }}
      collapsedWidth={0}
    >
      <div className="logoContainer">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        className="Menu"
        onClick={e => setCollapsed(true)}
      >
        <Menu.Item key="1" >
          <Link to="/">
            <EditOutlined />
            <span>{Strings.strings.transcriptions}</span>
          </Link>
        </Menu.Item>
        {canRT &&
          <Menu.Item key="realtime">
            <Link to="/realtime">
              <FieldTimeOutlined />
              <span>{Strings.strings.realtime}</span>
            </Link>
          </Menu.Item>
        }
        <Menu.Item key="config">
          <Link to="/account-config">
            <UserOutlined />
            <span>{Strings.strings.account}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="docs">
          <Link to="/docs">
            <QuestionCircleOutlined />
            <span>{Strings.strings.manual}</span>
          </Link>
        </Menu.Item>
        {isAdmin &&
          <Menu.Item key="3">
            <Link to="/admin">
              <DashboardOutlined />
              <span>{Strings.strings.admin}</span>
            </Link>
          </Menu.Item>
        }
        {isAdmin &&
          <Menu.Item key="6">
            <Link to="/info/batch/recent">
              <DatabaseOutlined />
              <span>{Strings.strings.recentBatchJobs}</span>
            </Link>
          </Menu.Item>
        }
        {/* {isAdmin &&
          <Menu.Item key="7">
            <Link to="/teamwork">
              <LineChartOutlined />
              <span>{Strings.strings.teamwork}</span>
            </Link>
          </Menu.Item>
        } */}
        <Menu.Item key="4" onClick={() => logoutHandler()}>
          <LogoutOutlined type="desktop" />
          <span>{Strings.strings.logout}</span>
        </Menu.Item>
      </Menu>
    </Sider >
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";

import { FC } from 'react';
import { PUDU_PRIMARY_COLOUR } from "../config/css";
import { Checkbox, Button } from "antd";
import QRModal from "../components/QRModal"
import { UserProfile, UserOTPSecret, VideoLink } from "../types";
import API from "../api/API";
import { sessionService } from "../services";
import { Strings } from "../strings";

type DocumentationProps = {
}

export const Documentation: FC<DocumentationProps> = ({ }) => {

    const [videoTutorialLink, setVideoTutorialLink] = useState<string | undefined>();

    useEffect(() => {
        handleGetTutorialVideo();
    }, []);

    const handleGetTutorialVideo = () => {
        API.getTutorialVideo().then((data: VideoLink) => {
            if (data !== undefined) {
                setVideoTutorialLink(data.url)
            }
        });
    };

    let video;
    if (videoTutorialLink) {
        video = (
            <div style={{ paddingTop: "12px", display: "block", paddingRight: "12px" }}>
                <video
                    style={{ maxWidth: "640px", width: "100%", height: "auto" }}
                    autoPlay={false}
                    loop
                    controls={true}>
                    <source src={videoTutorialLink} type="video/mp4" />
                </video>
            </div>
        )
    }

    return (
        <div
            style={{
                paddingLeft: "12px",
                paddingTop: "12px"
            }}
        >
            <h1>{Strings.strings.manual}</h1>
            {video}
        </div>

    );
}

export default Documentation;
import React, { useState, useEffect, Ref } from "react";

import { EditorItemType } from "../types/enums";

import TimeHelper from "../helpers/TimeHelper";

import { DeleteOutlined, ArrowDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox } from "antd";
import { Popconfirm, message } from "antd";
import { Tooltip } from 'antd';

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import TextEditorItem from "./TextEditorItem";
import { AudioOutlined } from '@ant-design/icons';

import "./TextEditor.css";

import {
    Edition,
    Speaker
} from "./../types/index";

import { Strings } from "../strings";

type TextEditorRowProps = {
    activeWordRef: Ref<HTMLDivElement>;
    edition: Edition,
    isActiveWord: boolean,
    speaker: Speaker | undefined,
    speakers: Speaker[],
    editorToolsEnabled: boolean,
    transcriptionId: number,
    // forceModeOn: boolean,
    editingEnabled: boolean,
    isSaving: boolean,
    includeForFindAndReplace: boolean,
    showFindAndReplace: boolean,
    handleAddOrCreateEdition: (edition: Edition) => void,
    handleSaveEdition: (edition: Edition) => void,
    handleOnFocusTextEditorItem: (edition: Edition) => void,
    handleSplitEdition: (splitIndex: number, edition: Edition) => void,
    onMouseUp: (edition: Edition) => void,
    handleInsertNewEdition: (edition: Edition) => void;
    handleDeleteEdition: (edition: Edition) => void;
    handleMergeEdition: (edition: Edition) => void;
    handleGoToWord: (edition: Edition) => void;
    handleShowSpeakers: (edition: Edition) => void;
    handleAddEditionsForFindAndReplace: (editionIds: number[]) => void
    handleRemoveEditionsForFindAndReplace: (editionIds: number[]) => void
};

const TextEditorRow: React.FC<TextEditorRowProps> = props => {

    const [forceMode, setForceMode] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(props.isSaving);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isMerging, setIsMerging] = useState<boolean>(false);

    useEffect(() => {
        if (isSaving === true) {
            let edition = Object.assign({}, props.edition, {
                paragraph_start: !props.edition.paragraph_start
            })
            props.handleAddOrCreateEdition(edition)
        }
    }, [isSaving]);

    useEffect(() => {
        if (isMerging === true) {
            message.success(Strings.strings.merging);
            props.handleMergeEdition(props.edition)
        }
    }, [isMerging]);

    useEffect(() => {
        if (isDeleting === true) {
            message.success(Strings.strings.deleting);
            props.handleDeleteEdition(props.edition);
        }
    }, [isDeleting]);

    let addSpeakerTitle = undefined
    let speakerPopup;

    if (!props.edition.speakerMatchesPreviousItem && props.speaker !== undefined) {
        addSpeakerTitle = props.speaker.name
    } else if (props.edition.speakerMatchesPreviousItem) {
        addSpeakerTitle = <AudioOutlined />;
    } else if (props.speaker === undefined) {
        addSpeakerTitle = Strings.strings.assignSpeaker;
    }

    if (props.editorToolsEnabled) {
        speakerPopup = (
            <div
                style={{ fontSize: "10px", cursor: "pointer" }}
                onClick={() => {
                    if (props.edition) {
                        props.handleShowSpeakers(props.edition)
                    }
                }}
            >
                {addSpeakerTitle}
            </div>
        )
    } else {
        speakerPopup = (
            <div style={{ fontSize: "10px", cursor: "pointer" }} >
                {props.speaker?.name}
            </div>
        )
    }

    let includeInFindAndReplaceButton;
    let deleteButton;
    let paragraphStart;
    let mergeWithNext;
    let insertNewEdition;

    if (props.editorToolsEnabled) {
        if (props.showFindAndReplace) {
            includeInFindAndReplaceButton = (
                <Tooltip title={Strings.strings.includeInFindAndReplace}>
                    <Checkbox
                        style={{ paddingRight: "16px" }}
                        checked={props.includeForFindAndReplace}
                        onChange={(e) => {
                            if (e.target.checked === false) {
                                if (props.edition.id) {
                                    props.handleRemoveEditionsForFindAndReplace([props.edition.id])
                                }
                            } else {
                                if (props.edition.id) {
                                    props.handleAddEditionsForFindAndReplace([props.edition.id])
                                }
                            }
                        }}
                    >
                        {props.includeForFindAndReplace}
                    </Checkbox>
                </Tooltip>
            )
        } else {
            let mergeTitle = (
                <div>
                    <div>
                        {Strings.strings.confirmMergeContent}
                    </div>
                    <div>
                        <i>Tip: Mantén presionado </i><code>Shift</code><i> al unir la región para evitar esta confirmación.</i>
                    </div>
                </div>
            )
            mergeWithNext = forceMode ?
                (
                    <span
                        style={{ paddingRight: "16px" }}
                    >
                        <ArrowDownOutlined
                            style={{ fontSize: '12px', color: PUDU_PRIMARY_COLOUR }}
                            onMouseUp={() => setIsMerging(true)}
                        />
                    </span>
                ) : (
                    <span
                        style={{ paddingRight: "16px" }}
                    >
                        <Popconfirm
                            title={mergeTitle}
                            onConfirm={(e: any) => setIsMerging(true)}
                            okText={Strings.strings.yes}
                            cancelText={Strings.strings.no}
                        >
                            <ArrowDownOutlined style={{ fontSize: '12px', color: PUDU_PRIMARY_COLOUR }} />
                        </Popconfirm>
                    </span>
                )

            deleteButton = forceMode ? (
                <span
                    style={{ paddingRight: "16px" }}
                >
                    <DeleteOutlined
                        style={{ fontSize: '12px', color: PUDU_SECONDARY_COLOUR }}
                        onMouseUp={() => setIsDeleting(true)}
                    />
                </span>
            ) : (
                <span
                    style={{ paddingRight: "16px" }}
                >
                    <Popconfirm
                        title={Strings.strings.confirmDeleteContent}
                        onConfirm={(e: any) => setIsDeleting(true)}
                        okText={Strings.strings.yes}
                        cancelText={Strings.strings.no}
                    >
                        <DeleteOutlined style={{ fontSize: '12px', color: PUDU_SECONDARY_COLOUR }} />
                    </Popconfirm>
                </span>
            )

            paragraphStart = (
                <Tooltip title={Strings.strings.paragraphStart}>
                    <Checkbox
                        checked={props.edition.paragraph_start}
                        onChange={() => {
                            setIsSaving(true)
                        }}
                    >
                        {props.edition.paragraph_start}
                    </Checkbox>
                </Tooltip>
            )

            insertNewEdition = (
                <PlusOutlined
                    style={{
                        fontSize: '8px',
                        color: PUDU_SECONDARY_COLOUR,
                        cursor: "pointer"
                    }}
                    onMouseUp={() => {
                        props.handleInsertNewEdition(props.edition)
                    }}
                />
            )

        }


    }

    let timeIn = TimeHelper.fancyTimeFormatHMS(props.edition.time)
    let timeOut = TimeHelper.fancyTimeFormatHMS(props.edition.time + (props.edition.duration ?? 0))
    let timeString = timeIn
    if (props.editorToolsEnabled) {
        timeString = `${timeIn} - ${timeOut}`
    }
    let timedisplay = (
        <div
            style={{
                textDecoration: "underline",
                color: PUDU_PRIMARY_COLOUR,
                fontSize: "0.8em",
                cursor: "pointer",
                paddingTop: "3px",
                display: "inline"
            }}
            onMouseUp={() => props.handleGoToWord(props.edition)}
        >
            {timeString}
        </div>
    )

    let textEditorItemStyle = `normalStyle edition`;

    if (props.isActiveWord) {
        textEditorItemStyle = `${textEditorItemStyle} activeWord`;
    }
    if (props.edition.text === undefined || props.edition.text?.length === 0) {
        textEditorItemStyle = `markedStyle activeWord`;
    }

    return (

        <div
            ref={props.isActiveWord ? props.activeWordRef : null}
            key={`item_${props.edition.id}`}
            style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: props.edition.speakerMatchesPreviousItem === true ? "0px" : "10px",
            }}>
            <div
                key={`space_${props.edition.id}`}
                style={{
                    maxWidth: "210px",
                    minWidth: "210px",
                    textAlign: "right",
                    flex: "1",
                    paddingRight: "20px",
                    alignItems: "stretch"
                }}
            >
                <div
                    className="disable-text"
                    onMouseDown={(e) => {
                        setForceMode(e.shiftKey)
                    }}
                >
                    <div className={[isSaving, isMerging, isDeleting].some(item => item === true) ? "saving" : "saved"}>
                        {Strings.strings.saving}
                    </div>
                    {includeInFindAndReplaceButton}
                    {deleteButton}
                    {mergeWithNext}
                    {paragraphStart}
                    {timedisplay}
                    {speakerPopup}
                    {insertNewEdition}
                </div>
            </div >
            <div style={{
                flex: 1,
                borderLeft: `2px solid ${props.speaker?.colour ?? PUDU_PRIMARY_COLOUR}`,
            }}>
                <TextEditorItem
                    key={`${props.edition.id}`}
                    className={textEditorItemStyle}
                    isActiveWord={props.isActiveWord}
                    onMouseUp={props.onMouseUp}
                    transcriptionId={props.transcriptionId}
                    edition={props.edition}
                    itemType={EditorItemType.word}
                    handleAddOrCreateEdition={props.handleAddOrCreateEdition}
                    handleSaveWordEdition={(edition) => {
                        props.handleSaveEdition(edition)
                    }}
                    onFocus={props.handleOnFocusTextEditorItem}
                    editingEnabled={props.editingEnabled}
                    handleSplitEdition={props.handleSplitEdition}
                    isSaving={isSaving}
                />

            </div>
        </div >
    );
};

function shouldUpdate(previousProps: TextEditorRowProps, nextProps: TextEditorRowProps) {
    return previousProps.edition.time === nextProps.edition.time &&
        previousProps.edition.text === nextProps.edition.text &&
        previousProps.edition.paragraph_start === nextProps.edition.paragraph_start &&
        previousProps.edition.speaker_id === nextProps.edition.speaker_id &&
        previousProps.edition.enabled === nextProps.edition.enabled &&
        previousProps.edition.speakerMatchesPreviousItem === nextProps.edition.speakerMatchesPreviousItem &&
        previousProps.isActiveWord === nextProps.isActiveWord &&
        previousProps.speaker === nextProps.speaker &&
        previousProps.speakers === nextProps.speakers &&
        previousProps.isSaving === nextProps.isSaving &&
        previousProps.showFindAndReplace === nextProps.showFindAndReplace &&
        previousProps.includeForFindAndReplace === nextProps.includeForFindAndReplace
}

export default React.memo(TextEditorRow, shouldUpdate)
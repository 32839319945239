/** @jsx jsx */
import React, { useState, useEffect } from "react";

import { Form, Input, Button, Alert } from "antd";

import { Redirect } from "react-router-dom";

import { sessionService } from "../services";

import API from "../api/API";

import { css, jsx } from "@emotion/core";
import "./VerifyUserEmailComponent.scss";
import BrandLogo from "../components/BrandLogo"

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { LoginResponse } from "../types/index"
import { Strings } from "../strings";

const FormItem = Form.Item;

type VerifyUserEmailComponentProps = {
    isAuthenticated: boolean;
    handleLogin: (token: string) => void;
    emailIsValid: boolean;
    email: string
}

const VerifyUserEmailComponent = (props: VerifyUserEmailComponentProps) => {

    const [email, setEmail] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);
    const [verificationSent, setVerificationSent] = useState<boolean>(false);
    const [verificationSuccessful, setVerificationSuccessful] = useState<boolean>(false);
    const [showCodeSentAlert, setShowCodeSentAlert] = useState<boolean>(false);

    useEffect(() => {
        setEmail(props.email)
        if (props.email === undefined || props.email.length === 0) {
            setRedirectToLogin(true)
        }
    }, [props.email])

    const formBasicStyle = css`
    border-radius: 8px;
    height: 40px;
  `;

    const buttonStyle = css`
    ${formBasicStyle};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
  `;

    const inputStyle = css`
    ${formBasicStyle};
    color: ${PUDU_PRIMARY_COLOUR};
    font-size: 16px;
    background: #f3f3f3;
  
    * {
      background: #f3f3f3;
    }
  `;

    useEffect(() => {
        setVerificationSent(props.emailIsValid)
    }, [props.emailIsValid]);

    const handleSendEmail = (event: React.FormEvent) => {
        event.preventDefault();
        sendEmailToVerify();
    };

    const handleSendPasswordAndOTP = (event: React.FormEvent) => {
        event.preventDefault();
        sendPasswordsAndOtp();
    };

    const sendEmailToVerify = async () => {
        setLoading(true)

        await API.verifyEmail(email).then(resp => {
            setLoading(false)
            if (!resp.ok) {
                console.log("verify email error")
                alert("error")
            } else {
                if (resp.status === 200) {
                    setRedirectToLogin(true)
                } else if (resp.status === 201) {
                    setVerificationSent(true)
                } else {
                    alert("error")
                }
            }
        }).catch((error: Error) => {
            console.log("error", error)
            setLoading(false)
            alert(error)
        })
    };

    const sendPasswordsAndOtp = async () => {
        setLoading(true)

        await API.updatedPasswordWithOTP(email, password, otp)
            .then((resp: Response | any) => {
                setLoading(false)
                if (!resp.ok) {
                    console.log("verify email error 2")
                    alert("error")
                } else {
                    if (resp.status === 200) {
                        return resp.json()
                    } else {
                        setVerificationSuccessful(true)
                    }
                }
            })
            .then(json => {
                let loginResponse: LoginResponse = (json as LoginResponse);
                if (loginResponse) {
                    const token = loginResponse.access_token;
                    sessionService.startInAppSession(token);
                    props.handleLogin(token);
                }
            })
            .catch((error: Error) => {
                console.log("error", error)
                setLoading(false)
                alert(error)
            })
    };

    const handleResendOtp = async () => {
        setLoading(true)

        API.otpVerifyAccount(email)
            .then((loginResult) => {
                setLoading(false)
                if (loginResult) {
                    setShowCodeSentAlert(true)
                } else {
                    // setLoginStep(LoginStep.password)
                    alert(Strings.strings.incorrectInformation);
                }
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            });

    };

    let codeSentAlert;

    if (showCodeSentAlert) {
        codeSentAlert = (
            <Alert
                message={`${Strings.strings.theCodeWasSuccessfullySentTo}${email}`}
                type="success"
                showIcon
                closable
                onClose={() => setShowCodeSentAlert(false)}
                style={{ fontSize: "0.8em" }}
            />
        )
    }



    if (props.isAuthenticated) {
        return <Redirect to="/" push={true} />
    }
    if (redirectToLogin) {
        return <Redirect to={{
            pathname: "/",
            state: {
                email: email
            }
        }} />;
    }

    let redirectToLoginButton = (
        <div>
            <Button
                style={{ fontSize: "12px", width: "100%", paddingTop: "10px" }}
                type="link"
                onClick={() => setRedirectToLogin(true)}
            >
                {Strings.strings.enterWithAnotherAccount}
            </Button>
        </div>

    )
    let verifyForm;
    let updatePasswordField;
    let title = Strings.strings.inorderToLoginPleaseVerifyYourAccount;
    let successMessage;

    if (verificationSuccessful) {
        successMessage = (
            <div>
                <div style={{ textAlign: "center", paddingBottom: "18px" }}>
                    Su cuenta fue verificada exitosamente!
                </div>
                <div>
                    <Button
                        css={buttonStyle}
                        type="primary"
                        className="login-form-button"
                        style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
                        onClick={() => setRedirectToLogin(true)}
                    >
                        {Strings.strings.login}
                    </Button>
                </div>

            </div>
        )
    } else if (!verificationSent) {
        verifyForm = (
            <Form className="login-form">
                <FormItem >
                    <Input
                        css={inputStyle}
                        name="email"
                        type="email"
                        placeholder={Strings.strings.email}
                        onChange={(e) => { setEmail(e.currentTarget.value) }}
                        value={email}
                    />
                </FormItem>

                <FormItem>
                    <Button
                        css={buttonStyle}
                        // disabled={!(username.length > 0 && password.length > 0)}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
                        onClick={handleSendEmail}
                        loading={loading}
                    >
                        Verificar su correo
                    </Button>
                </FormItem>
                {redirectToLoginButton}
            </Form>
        )
    } else {
        title = Strings.strings.enterTheCodeWeEmailedYou
        updatePasswordField = (
            <Form className="login-form">

                <FormItem >
                    <Input
                        css={inputStyle}
                        name="otp"
                        type="otp"
                        placeholder={Strings.strings.verificationCode}
                        onChange={(e) => { setOtp(e.currentTarget.value) }}
                        value={otp}
                    />
                    <Button
                        style={{
                            fontSize: "12px", width: "100%"
                        }}
                        type="link"
                        onClick={() => { handleResendOtp() }}>{Strings.strings.sendAnNewCodeToMyEmail}
                    </Button>

                    {codeSentAlert}
                </FormItem>

                <FormItem >
                    <Input
                        css={inputStyle}
                        name="password"
                        type="password"
                        placeholder={Strings.strings.newPassword}
                        onChange={(e) => { setPassword(e.currentTarget.value) }}
                        value={password}
                    />
                </FormItem>

                <FormItem >
                    <Input
                        css={inputStyle}
                        name="passwordconfirmation"
                        type="password"
                        placeholder={Strings.strings.confirmNewPassword}
                        onChange={(e) => { setPasswordConfirmation(e.currentTarget.value) }}
                        value={passwordConfirmation}
                    />
                </FormItem>

                <FormItem>
                    <Button
                        css={buttonStyle}
                        // disabled={!(username.length > 0 && password.length > 0)}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
                        onClick={handleSendPasswordAndOTP}
                        loading={loading}
                    >
                        {Strings.strings.verifyAccount}
                    </Button>
                </FormItem>

                {redirectToLoginButton}

            </Form>

        )
    }

    return (
        <div className="loginContainer">
            <div className="loginCenter">
                <BrandLogo />
                <div style={{
                    textAlign: "center",
                    paddingBottom: "12px",
                    paddingLeft: "30px",
                    paddingRight: "30px"
                }}
                >
                    {title}
                    {/* {verificationSentMessage} */}
                </div>
                {verifyForm}
                {updatePasswordField}
                {successMessage}
            </div>
        </div>

    );

}
export default VerifyUserEmailComponent;
/** @jsx jsx */
import React, { useState, useEffect } from "react";

import { Form, Input, Button, Alert } from "antd";

import { EyeInvisibleOutlined, EyeTwoTone, CheckCircleOutlined } from '@ant-design/icons';

import API from "../api/API";
import { Result } from "antd";
import { css, jsx } from "@emotion/core";
import "./VerifyUserEmailComponent.scss";
import BrandLogo from "../components/BrandLogo"

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { LoginResponse } from "../types/index"
import { Strings } from "../strings";
import { tokenToString } from "typescript";

const FormItem = Form.Item;

type VerifyUserEmailComponentProps = {
    handleRedirectToLogin: () => void;
    recoverPasswordToken: string;
}

const VerifyUserEmailComponent = (props: VerifyUserEmailComponentProps) => {

    console.log("props", props.recoverPasswordToken)
    const [email, setEmail] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [showCodeSentAlert, setShowCodeSentAlert] = useState<boolean>(false);
    const [showPasswordChanged, setShowPasswordChanged] = useState<boolean>(false);
    const [showLinkExpired, setShowLinkExpired] = useState<boolean>(false);

    useEffect(() => {
        handleOnRecoverPasswordInit()
    }, [props.recoverPasswordToken])


    const handleChangePassword = (event: React.FormEvent) => {
        event.preventDefault();
        API.updatePassword(password, props.recoverPasswordToken)
            .then(resp => {
                setLoading(false)
                if (resp.ok) {
                    if (resp.status === 200) {
                        setShowPasswordChanged(true)
                        return
                    }
                }
                console.log("link expired")
                setShowLinkExpired(true)

            })
            .catch(error => {
                setLoading(false)
                console.log(error)
                setShowLinkExpired(true)
            });
    };

    // const validPassword = new RegExp(
    //     '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
    // )
    const validPassword = (): boolean => {
        return validCharacterLength.test(password) &&
            validUpperCase.test(password) &&
            validLowerCase.test(password) &&
            validDigitCount.test(password) &&
            validSpecialCharacter.test(password)
    }

    const validCharacterLength = new RegExp(
        '(?=.{8,})'
    )
    const validUpperCase = new RegExp(
        '(?=.*[A-Z])'
    )
    const validLowerCase = new RegExp(
        '(?=.*[a-z])'
    )
    const validDigitCount = new RegExp(
        '(?=.*[0-9])'
    )
    const validSpecialCharacter = new RegExp(
        '([^A-Za-z0-9])'
    )

    const handleOnRecoverPasswordInit = () => {
        if (props.recoverPasswordToken) {
            API.decodeEmailFromToken(props.recoverPasswordToken)
                .then(resp => {
                    setLoading(false)
                    if (resp.ok) {
                        if (resp.status === 200) {
                            resp.json().then(json => {
                                console.log(json["email"])
                                setEmail(json["email"])
                            })
                            return
                        }
                    }
                    console.log("link expired")
                    setShowLinkExpired(true)
                })
                .catch(error => {
                    console.log("link expired")
                    setLoading(false)
                    console.log(error)
                    setShowLinkExpired(true)
                });
        }
    }

    const formBasicStyle = css`
    border-radius: 8px;
    height: 40px;
  `;

    const buttonStyle = css`
    ${formBasicStyle};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
  `;

    const inputStyle = css`
    ${formBasicStyle};
    color: ${PUDU_PRIMARY_COLOUR};
    font-size: 16px;
    background: #f3f3f3;
  
    * {
      background: #f3f3f3;
    }
  `;

    let codeSentAlert;

    if (showCodeSentAlert) {
        codeSentAlert = (
            <Alert
                message={`${Strings.strings.theCodeWasSuccessfullySentTo}${email}`}
                type="success"
                showIcon
                closable
                onClose={() => setShowCodeSentAlert(false)}
                style={{ fontSize: "0.8em" }}
            />
        )
    }

    let redirectToLoginButton = (
        <div>
            <Button
                style={{ fontSize: "12px", width: "100%", paddingTop: "10px" }}
                type="link"
                onClick={() => props.handleRedirectToLogin()}
            >
                {Strings.strings.enterWithAnotherAccount}
            </Button>
        </div>
    )

    const passwordRequirements = (
        <div style={{ paddingBottom: "10pt" }}>
            La contraseña debe tener al menos:
            <div>- 8 caracteres <CheckCircleOutlined hidden={!validCharacterLength.test(password)} /></div>
            <div>- una letra mayúscula <CheckCircleOutlined hidden={!validUpperCase.test(password)} /></div>
            <div>- una letra minúscula <CheckCircleOutlined hidden={!validLowerCase.test(password)} /></div>
            <div>- un dígito numérico <CheckCircleOutlined hidden={!validDigitCount.test(password)} /></div>
            <div>- un carácter especial <CheckCircleOutlined hidden={!validSpecialCharacter.test(password)} /></div>
        </div>
    )


    let updatePasswordField = (
        <Form className="login-form">

            {/* <FormItem >
                <Input
                    css={inputStyle}
                    name="otp"
                    type="otp"
                    placeholder={Strings.strings.verificationCode}
                    onChange={(e) => { setOtp(e.currentTarget.value) }}
                    value={otp}
                />
                <Button
                    style={{
                        fontSize: "12px", width: "100%"
                    }}
                    type="link"
                    onClick={() => { handleResendOtp() }}>{Strings.strings.sendAnNewCodeToMyEmail}
                </Button>

                {codeSentAlert}
            </FormItem> */}

            <FormItem >
                <Input.Password
                    css={inputStyle}
                    name="password"
                    type="password"
                    placeholder={Strings.strings.newPassword}
                    onChange={(e) => { setPassword(e.currentTarget.value) }}
                    value={password}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </FormItem>

            <FormItem >
                <Input.Password
                    css={inputStyle}
                    name="passwordconfirmation"
                    type="password"
                    placeholder={Strings.strings.confirmNewPassword}
                    onChange={(e) => { setPasswordConfirmation(e.currentTarget.value) }}
                    value={passwordConfirmation}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </FormItem>

            {passwordRequirements}

            <FormItem>
                <Button
                    css={buttonStyle}
                    disabled={!(validPassword() && password === passwordConfirmation)}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
                    onClick={handleChangePassword}
                    loading={loading}
                >
                    {Strings.strings.updatePassword}
                </Button>
            </FormItem>

            {redirectToLoginButton}

        </Form >

    )


    if (showLinkExpired) {
        return (
            <div className="loginContainer">
                <div className="loginCenter">
                    <Result
                        title={"El enlace ha caducado"}
                        extra={
                            <Button
                                key={"goToLogin2"}
                                css={buttonStyle}
                                type="primary"
                                className="login-form-button"
                                style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
                                onClick={props.handleRedirectToLogin}
                                loading={loading}
                            >
                                Iniciar sesión
                            </Button>
                        }
                    />
                </div>
            </div>
        )

    } else if (showPasswordChanged) {
        return (
            <div className="loginContainer">
                <div className="loginCenter">
                    <Result
                        status="success"
                        title="La contraseña se ha actualizado con éxito"
                        extra={[
                            <Button
                                key={"goToLogin1"}
                                css={buttonStyle}
                                type="primary"
                                className="login-form-button"
                                style={{ backgroundColor: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR, boxShadow: "none", textShadow: "none" }}
                                onClick={props.handleRedirectToLogin}
                                loading={loading}
                            >
                                Iniciar sesión
                            </Button>
                        ]}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="loginContainer">
                <div className="loginCenter">
                    <BrandLogo />
                    <div style={{
                        textAlign: "center",
                        paddingBottom: "12px",
                        paddingLeft: "30px",
                        paddingRight: "30px"
                    }}
                    >
                    </div>
                    {updatePasswordField}
                </div>
            </div>

        );
    }

}
export default VerifyUserEmailComponent;
import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';

import API from "../api/API";

import { AutoComplete } from "antd";
import { Table } from "antd";
import { Tabs } from 'antd';
import { Input } from 'antd';
import { Button } from 'antd';
import { Checkbox } from 'antd';
import {
    DownOutlined,
    UpOutlined,
    UserAddOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';

import { RefSelectProps } from "rc-select";
import { Redirect } from "react-router-dom";
import { User, Group, Client, Transcription, CustomDictionary, ClientDetail } from "../types/index";

import CreateClientComponent from "./CreateClientComponent"
import CreateGroupComponent from "./CreateGroupComponent"
import TranscriptionSubmittedByUserComponent from "../components/TranscriptionSubmittedByUserComponent";

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";

const { TabPane } = Tabs;

type ClientDetailComponentProps = {
    id: number
};

const ClientDetailsComponent: React.FC<ClientDetailComponentProps> = props => {

    const { id } = useParams<{ id: string }>();

    const [redirect, setRedirect] = useState<string | null>(null);
    const [clientId, setClientId] = useState<number>(Number(id));
    const [client, setClient] = useState<Client | undefined>(undefined);
    const [transcriptions, setTranscriptions] = useState<Transcription[]>([]);
    const autoCompleteRef = useRef<RefSelectProps | null>(null)
    const autoCompleteInputRef = useRef<Input>(null)
    const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false);
    const [availableUsers, setAvailableUsers] = useState<User[]>([]);
    const [possibleUsers, setPossibleUsers] = useState<{ value: string }[]>([]);
    const [availableDictionaries, setAvailableDictionaries] = useState<CustomDictionary[]>([]);

    useEffect(() => {
        handleGetClient()
    }, [clientId]);

    useEffect(() => {
        console.log(client)
    }, [client]);

    useEffect(() => {
        let values = availableUsers.map(availableUser => { return { value: availableUser.username } });
        setPossibleUsers(values);
    }, [availableUsers]);

    const addUserToGroup = (user_id: number, group_id: number) => {
        API.addUserToGroup(group_id, user_id)
            .then(data => {
                if (client) {
                    let groups = [...client.groups ?? []]
                    let groupIndex = groups.findIndex(group => group.id === group_id)
                    if (groupIndex > -1) {
                        groups[groupIndex] = data
                        let clientUpdated: Client = { ...client }
                        clientUpdated.groups = groups
                        setClient(clientUpdated)
                    }
                }
            });
    };

    const removeUserFromGroup = (user_id: number, group_id: number) => {
        API.removeUserFromGroup(group_id, user_id)
            .then(data => {
                if (client) {
                    let groups = [...client.groups ?? []]
                    let groupIndex = groups.findIndex(group => group.id === group_id)
                    if (groupIndex > -1) {
                        groups[groupIndex] = data
                        let clientUpdated: Client = { ...client }
                        clientUpdated.groups = groups
                        setClient(clientUpdated)
                    }
                }
            });
    };

    const handleGetClient = () => {
        API.getClientDetail(clientId).then((data: ClientDetail) => {
            setClient(data.client)
            setAvailableDictionaries(data.dictionaries)
        });
    };

    const handleOnUpdateClient = (client: Client): Promise<Client> => {
        return new Promise((resolve, reject) => {
            if (client.id === undefined) {
                API.createClient(client).
                    then((data: Client) => {
                        resolve(data)
                    }).catch(e => reject(e))
                    .finally(handleGetClient)
            } else {
                API.updateClient(client).
                    then((data: Client) => {
                        resolve(data)
                    }).catch(e => reject(e))
                    .finally(handleGetClient)
            }
        })
    }


    const handleOnCreateGroup = (group: Group): Promise<Group> => {
        return new Promise((resolve, reject) => {
            if (group.id === undefined) {
                API.createGroup(group).
                    then((data: Group) => {
                        resolve(data)
                    }).catch(e => reject(e))
                    .finally(handleGetClient)
            } else {
                API.updateGroup(group).
                    then((data: Group) => {
                        resolve(data)
                    }).catch(e => reject(e))
                    .finally(handleGetClient)
            }
        })
    }


    const onAddUserToClient = (data: string) => {

        let user = availableUsers.find(user => user.username === data)

        if (user !== undefined) {
            autoCompleteRef.current?.blur()
            autoCompleteInputRef.current?.setValue("")

            API.addUserToClient(clientId, user.id)
                .then(saved => {
                    if (client) {
                        let clientUpdated: Client = { ...client }
                        clientUpdated.users = Array.from(saved)
                        setClient(clientUpdated)
                    }
                })
        }
    };

    const removeUserFromClient = (user_id: number) => {
        API.removeUserFromClient(clientId, user_id)
            .then(users => {
                if (client) {
                    let clientUpdated: Client = { ...client }
                    clientUpdated.users = users
                    setClient(clientUpdated)
                }
            })
    };

    const onSearch = (searchText: string) => {
        if (searchText.length > 0) {
            API.usersMatchingPattern(searchText)
                .then((results: User[]) => {
                    setAvailableUsers(results)
                })
        }
    };

    const handleUpdateDictionariesForGroup = (group_id: number, dictionary_id: number, removeItem: boolean) => {
        if (client) {
            let groups = [...client.groups ?? []]
            let group = groups.find(group => group.id === group_id)
            if (group) {

                let dictIds: number[] = []


                if (removeItem) {
                    const index = group.custom_dictionaries.map(dict => dict.id).indexOf(dictionary_id);
                    if (index > -1) {
                        group.custom_dictionaries.splice(index, 1)
                        dictIds = group.custom_dictionaries.map(dict => dict.id);
                    }
                } else if (!group.custom_dictionaries.map(dict => dict.id).includes(dictionary_id)) {
                    console.log("EH")
                    let dictionary = availableDictionaries.find(item => dictionary_id)
                    // make sure we don't get multiple dicts for the same language
                    dictIds = group.custom_dictionaries
                        .filter(element => element.language !== dictionary?.language)
                        .map(dict => dict.id);
                    dictIds.push(dictionary_id)
                } else {
                    return
                }
                API
                    .updateDictionariesForGroup(group_id, dictIds)
                    .then((updatedGroup: Group) => {
                        let groupIndex = groups.findIndex(group => group.id === group_id)
                        if (groupIndex > -1) {
                            groups[groupIndex] = updatedGroup
                            let clientUpdated: Client = { ...client }
                            clientUpdated.groups = groups
                            setClient(clientUpdated)
                        }
                    })
            }
        }
    }

    const groupsDataSource: any = (client?.groups ?? [])
        .sort((a: Group, b: Group) => {
            return (a.id ?? 0) - (b.id ?? 0)
        })
        .map((group) => {
            return ({
                key: group.id,
                id: group.id,
                name: group.name,
                users: group.users,
                custom_dictionaries: group
            })
        })
    const groupsColumns: any = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: (a: any, b: any) => a.id - b.id
        },
        {
            title: "name",
            dataIndex: "name",
            key: "name",
            sorter: (a: any, b: any) => a.localeCompare(b)
        },
        {
            title: "diccionarios",
            dataIndex: "custom_dictionaries",
            key: "custom_dictionaries",
            sorter: (a: any, b: any) => a.localeCompare(b),
            render: (group: Group) => {
                console.log(group)
                let availableDicts = availableDictionaries.map(item => {
                    let checked = group.custom_dictionaries.map(item => item.id).includes(item.id)
                    return (
                        <div key={item.id}>
                            <Checkbox
                                style={{ paddingRight: "10px" }}
                                checked={checked}
                                onClick={() => {
                                    if (group.id) {
                                        handleUpdateDictionariesForGroup(group.id, item.id, checked)
                                    }
                                }}
                            />
                            {item.language} - "{item.name}"
                        </div>
                    )
                })
                return availableDicts
            }
        }
    ];

    const usersDataSource: any = (client?.users ?? [])
        .sort((a: User, b: User) => {
            return a.id - b.id
        })
        .map((user) => {
            return ({
                key: user.id,
                id: user.id,
                username: user,
            })
        })
    const usersColumns: any = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: (a: any, b: any) => a.id - b.id
        },
        {
            title: "username",
            dataIndex: "username",
            key: "username",
            sorter: (a: any, b: any) => a.localeCompare(b),
            render: (user: User) => {
                return (
                    <div key={user.id} style={{ display: "flex", alignItems: "center" }}>
                        <div key={user.id} style={{ paddingRight: "6px" }}>
                            {user.username}
                        </div>

                        <Button
                            type="primary"
                            size="small"
                            style={{
                                background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR
                            }}
                            danger={true}
                            onClick={() => {
                                removeUserFromClient(user.id)
                            }}
                        >
                            Sacar
                        </Button>

                    </div>
                )
            }
        }
    ];

    if (redirect) {
        return (<Redirect to={redirect} />)
    }

    let userAutoComplete = (
        <AutoComplete
            ref={autoCompleteRef}
            options={possibleUsers}
            style={{ width: 200 }}
            onSelect={onAddUserToClient}
            onSearch={onSearch}
            placeholder="search usernames here"
            open={autoCompleteOpen}
            onFocus={e => setAutoCompleteOpen(true)}
            onBlur={e => setAutoCompleteOpen(false)}
            allowClear={true}
            children={(
                <Input
                    ref={autoCompleteInputRef}
                ></Input>
            )}
        />
    )

    let clientComponent;
    if (client) {
        clientComponent = (
            <div style={{ paddingBottom: "10px" }}>
                <CreateClientComponent client={client} onSubmit={handleOnUpdateClient} />
            </div>
        )
    }

    // let expandedRows: number[] = [];
    // (client?.groups ?? []).filter(item => item.id !== undefined).forEach(item => {
    //     if (item.id) {
    //         expandedRows.push(item.id)
    //     }
    // })

    return (
        <div style={{ paddingLeft: "10px", paddingTop: "10px" }}>
            <div>
                <Button
                    type="primary"
                    size="small"
                    style={{
                        background: PUDU_SECONDARY_COLOUR, borderColor: PUDU_SECONDARY_COLOUR
                    }}
                    onClick={() => {
                        let url = `/admin/clients`;
                        setRedirect(url)
                    }}
                >
                    <ArrowLeftOutlined /> clientes
                </Button>
                <h2>{client?.name}</h2>
            </div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="data" key="1">
                    {clientComponent}
                </TabPane>
                <TabPane tab="Users" key="2">
                    {userAutoComplete}
                    <div style={{ overflow: "scroll", height: "calc(100vh - 220px)", }}>

                        <Table
                            pagination={{ position: ['bottomLeft', 'topLeft'], defaultPageSize: 20 }}
                            dataSource={usersDataSource}
                            columns={usersColumns}
                            size="small"
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: event => { },
                        //     };
                        // }}
                        />
                    </div>
                </TabPane>
                <TabPane tab="Groups" key="3">
                    <div style={{ paddingBottom: "10px" }}>
                        <CreateGroupComponent client_id={clientId} onSubmit={handleOnCreateGroup} />
                    </div>
                    <Table
                        pagination={{ position: ['bottomLeft', 'topLeft'], defaultPageSize: 20 }}
                        dataSource={groupsDataSource}
                        columns={groupsColumns}
                        size="small"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { },
                            };
                        }}
                        expandable={{
                            // expandedRowKeys: expandedRows,
                            expandedRowRender: (group: Group) => {
                                let usersDivs = (client?.users?.map((user, index) => {
                                    let userIsInGroup = group.users?.map(user => { return user.id }).includes(user.id);
                                    let buttonColour = userIsInGroup ? PUDU_SECONDARY_COLOUR : PUDU_PRIMARY_COLOUR

                                    return (
                                        <div key={`u${user.id}-g${group.id ?? index}`} style={{ display: "flex", alignItems: "center", paddingBottom: "6px" }}>
                                            <div style={{ paddingRight: "10px" }}>{user.username}</div>
                                            <Button
                                                type="primary"
                                                size="small"
                                                style={{
                                                    background: `${buttonColour}`, borderColor: `${buttonColour}`
                                                }}
                                                danger={userIsInGroup}
                                                onClick={() => {
                                                    if (group.id) {
                                                        userIsInGroup ? removeUserFromGroup(user.id, group.id) : addUserToGroup(user.id, group.id)
                                                    }
                                                }}
                                            >
                                                {userIsInGroup ? "Eliminar" : "Agregar"}
                                            </Button>
                                        </div>
                                    )
                                }))

                                return (
                                    <div key={`g${group.id}`}>
                                        {usersDivs}
                                    </div>
                                )
                            },
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <UpOutlined onClick={e => onExpand(record, e)} />
                                ) : (
                                    <UserAddOutlined onClick={e => onExpand(record, e)} />
                                )
                        }}
                    />
                </TabPane>
                <TabPane tab="TXs" key="4">
                    <TranscriptionSubmittedByUserComponent clientId={clientId} />
                </TabPane>
            </Tabs>

        </div>
    );
}

export default ClientDetailsComponent;
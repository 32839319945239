import React, { useEffect, useState, useRef } from "react";
import { Button, Alert } from "antd";

import {
    SpeechmaticsConfig
} from "../types";

import "./UserTranscriptionJobsAdmin.css";

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { Strings } from "../strings";
import API from "../api/API"
import { Form, InputNumber } from "antd";


const FormItem = Form.Item;


type ASRConfigComponentProps = {};

const ASRConfigComponent: React.FC<ASRConfigComponentProps> = props => {

    const defaultConfig = {
        speaker_diarization_config_sensitivity: 0.5,
        punctuation_overrides_sensitivity: 0.5
    }

    const [speechmaticsConfig, setSpeechmaticsConfig] = useState<SpeechmaticsConfig>(defaultConfig)
    const [showSave, setShowSave] = useState<boolean>(false)

    useEffect(() => {
        getCustomWords()
    }, []);

    const getCustomWords = () => {
        API.getSMConfig()
            .then((value: SpeechmaticsConfig) => {
                console.log("SpeechmaticsConfig", value)
                setSpeechmaticsConfig(value)
            })
    }

    const saveAlert = (
        <Alert
            message="Actualizado"
            type="success"
            showIcon
            closable
        // onClose={() => { setShowSave(false) }}
        />
    )

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            paddingRight: "12px"
        }}>
            <div>
                {showSave ? saveAlert : ""}
                <Form
                    onFinish={e => {
                        API.updateSMConfig(speechmaticsConfig)
                            .then((updatedConfig: SpeechmaticsConfig) => {
                                setSpeechmaticsConfig(updatedConfig)
                                setShowSave(true)
                            })
                    }}
                >
                    <FormItem
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div>speaker_diarization: sensitivity (default: 0.5)</div>
                        <InputNumber
                            type="new_word"
                            placeholder="palabra nueva"
                            onChange={(e) => { setSpeechmaticsConfig({ ...speechmaticsConfig, speaker_diarization_config_sensitivity: e }) }}
                            min={0.1}
                            max={1}
                            value={speechmaticsConfig.speaker_diarization_config_sensitivity}
                            step={0.05}
                            width={300}
                        />
                    </FormItem>
                    <FormItem
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}

                    >
                        <div>punctuation_overrides: sensitivity (default: 0.5)</div>
                        <InputNumber
                            type="new_word"
                            placeholder="palabra nueva"
                            onChange={(e) => { setSpeechmaticsConfig({ ...speechmaticsConfig, punctuation_overrides_sensitivity: e }) }}
                            min={0.1}
                            max={1}
                            value={speechmaticsConfig.punctuation_overrides_sensitivity}
                            step={0.05}
                            width={300}
                        />
                    </FormItem>
                    <FormItem
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            className="login-form-button"
                        >
                            {Strings.strings.update}
                        </Button>


                    </FormItem>

                </Form>
            </div>

        </div>
    );

}

export default ASRConfigComponent;
import React, { useState, useRef, useEffect } from "react";
import { Modal } from 'antd';
import { MoreOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { PUDU_PRIMARY_COLOUR } from "../config/css";
import API from "../api/API";

import { Form, Input } from "antd";
import { List, arrayMove } from 'react-movable';

import { v4 as uuidv4 } from "uuid";

import Button from './StyledComponents/AntDButton'

import {
    Transcription,
    TranscriptionMetadataToReorder,
    TranscriptionMetadataEntry
} from "../types/index";
import { Strings } from "../strings";

type EditTranscriptionDocsDetailsModalProps = {
    transcription?: Transcription
    visible: boolean,
    onOk: (metadataItems: TranscriptionMetadataEntry[]) => void,
    onCancel: () => void,
    tmpMetadataItems: TranscriptionMetadataEntry[] | undefined,
    tmpFilename: string | undefined,
    tmpTitle: string | undefined,
    tmpDescription: string | undefined
}

const EditTranscriptionDocsDetailsModal = (props: EditTranscriptionDocsDetailsModalProps) => {

    const [metadataitems, setMetadataitems] = useState((props.tmpMetadataItems ? (props.transcription?.transcriptionmetadataentries ?? []) : []).sort((a, b) => {
        return a.order - b.order
    }));
    const [activeInput, setActiveInput] = useState<string | undefined>();
    const activeInputRef = useRef<Input>(null);

    const filename = props.transcription?.filename ?? props.tmpFilename;
    const title = props.transcription?.title ?? props.tmpTitle;
    const description = props.transcription?.description ?? props.tmpDescription;


    useEffect(() => {
        if (props.visible === true) {
            if (metadataitems.length === 0) {
                addDefaultMetadataEntries()
            }
        }
    }, [props.visible, metadataitems]);

    useEffect(() => {
        if (props.transcription?.id) {
            API.getTranscriptionMetadataItems(props.transcription.id).then((data: TranscriptionMetadataEntry[]) => {
                setMetadataitems(data)
            })
        }
    }, [props.transcription]);

    const updateOrderForItems = (reordered: TranscriptionMetadataEntry[]) => {
        let items: TranscriptionMetadataToReorder[] = reordered.map(item => {
            return {
                "id": item.id, "order": item.order
            }
        })
        if (props.transcription?.id) {
            API.updateTranscriptionMetadataItemOrder(items, props.transcription.id).then((data: TranscriptionMetadataEntry[]) => {
                setMetadataitems(data)
            })
        } else {
            setMetadataitems(reordered)
        }
    }

    const addNewMetadataItem = () => {
        if (props.transcription?.id) {
            API.addNewMetadataItem(props.transcription?.id).then((data: TranscriptionMetadataEntry[]) => {
                setMetadataitems(data)
            })
        } else {
            let items = [...metadataitems];
            let newItem: TranscriptionMetadataEntry = {
                key: "",
                order: items.length,
                tmpId: uuidv4()
            }
            items.push(newItem)
            setMetadataitems(items)
        }
    }

    const deleteMetadataItem = (metadataItem: TranscriptionMetadataEntry) => {
        if (props.transcription?.id && metadataItem.id !== undefined) {
            API.deleteMetadataItem(props.transcription?.id, metadataItem.id).then((data: TranscriptionMetadataEntry[]) => {
                setMetadataitems(data)
            })
        } else if (metadataItem.tmpId !== undefined) {
            let items = metadataitems.filter(e => e.tmpId !== metadataItem.tmpId)
            setMetadataitems(items)
        }
    }

    const updateTranscriptionMetadataEntry = (entry: TranscriptionMetadataEntry) => {
        if (props.transcription?.id) {
            API.updateMetadataItem(props.transcription.id, entry).then((data: TranscriptionMetadataEntry[]) => {
                activeInputRef.current?.blur()
                setMetadataitems(data)
            })
        } else if (entry.tmpId) {
            let items = [...metadataitems]
            let index = items.findIndex(e => e.tmpId === entry.tmpId)
            items[index].key = entry.key
            items[index].text = entry.text
            setMetadataitems(items)
        }
    }

    const addDefaultMetadataEntries = () => {
        if (props.transcription?.id) {
            API.addDefaultMetadataItems(props.transcription.id).then((data: TranscriptionMetadataEntry[]) => {
                activeInputRef.current?.blur()
                setMetadataitems(data)
            })
        } else {
            API.getDefaultMetadataItems().then((items: string[]) => {
                setMetadataitems(items.map((item, index) => {
                    return {
                        "id": undefined,
                        "key": item,
                        "text": undefined,
                        "transcription_id": undefined,
                        "order": index,
                        "tmpId": uuidv4()
                    }
                }))
            })
        }
    }

    let sortedItems = metadataitems.sort((a, b) => {
        return a.order - b.order
    })

    return (
        <Modal
            style={{ top: 20 }}
            closable={false}
            visible={props.visible}
            title={Strings.strings.transcriptionMetadata}
            onCancel={props.onCancel}
            footer={[
                <Button
                    key="ok"
                    $buttonType="normal"
                    style={{
                        background: PUDU_PRIMARY_COLOUR,
                        borderColor: PUDU_PRIMARY_COLOUR
                    }}
                    type="primary"
                    size="small"
                    onClick={() => {
                        props.onOk(metadataitems)
                    }}
                >{Strings.strings.save}
                </Button>
            ]}
            onOk={() => {
                if (props.transcription?.id === undefined) {
                    props.onOk(metadataitems)
                }
            }}
        >
            <h5 >
                <div>{Strings.strings.file}: {filename}</div>
                <div>{Strings.strings.title}: {title}</div>
                <div>{Strings.strings.description}: {description}</div>
            </h5>

            <Form
                name="basic"
                labelCol={{ span: 6 }}
            >
                <List
                    values={sortedItems}
                    onChange={({ oldIndex, newIndex }) => {
                        let reordered = arrayMove(sortedItems, oldIndex, newIndex)
                        reordered.forEach((metadataitem, index) => metadataitem.order = index)
                        updateOrderForItems(reordered)
                    }}
                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                    renderItem={({ value, props }) => {
                        let metadataEntryKey = `${value?.key}-key`
                        let metadataEntryValue = `${value?.key}-value`
                        return (
                            <div {...props}

                            >
                                <div
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <div style={{ width: "20px" }}>
                                        <MoreOutlined />
                                    </div>

                                    <Input
                                        ref={activeInput === metadataEntryKey ? activeInputRef : undefined}
                                        style={{ display: "inline-block", paddingRight: "10px" }}
                                        key={metadataEntryKey}
                                        name={metadataEntryKey}
                                        onBlur={(e) => {
                                            let updatedEntry: TranscriptionMetadataEntry = {
                                                id: value.id,
                                                transcription_id: value.transcription_id,
                                                order: value.order,
                                                key: e.currentTarget.value,
                                                text: value.text,
                                                tmpId: value.tmpId
                                            }
                                            updateTranscriptionMetadataEntry(updatedEntry)
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                activeInputRef.current?.blur()
                                            }
                                        }}
                                        placeholder={value?.key}
                                        defaultValue={value?.key}
                                        onFocus={() => setActiveInput(metadataEntryKey)}
                                    />
                                    <Input
                                        ref={activeInput === metadataEntryValue ? activeInputRef : undefined}
                                        style={{ display: "inline-block" }}
                                        key={metadataEntryValue}
                                        name={metadataEntryValue}
                                        onBlur={(e) => {
                                            let updatedEntry: TranscriptionMetadataEntry = {
                                                id: value.id,
                                                transcription_id: value.transcription_id,
                                                order: value.order,
                                                key: value.key,
                                                text: e.currentTarget.value,
                                                tmpId: value.tmpId
                                            }
                                            updateTranscriptionMetadataEntry(updatedEntry)
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                activeInputRef.current?.blur()
                                            }
                                        }}
                                        placeholder={value?.key}
                                        defaultValue={value.text}
                                        onFocus={() => setActiveInput(metadataEntryValue)}
                                    />
                                    <div style={{ paddingLeft: "4px" }}>
                                        <Button
                                            $buttonType="empty"
                                            shape="circle"
                                            size="small"
                                            onClick={() => {
                                                deleteMetadataItem(value)
                                            }
                                            }>
                                            <CloseOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />
            </Form>
            <div style={{ paddingTop: "10px" }}>
                <Button $buttonType="normal" style={{ marginRight: "8px" }} type="primary" shape="circle" onClick={() => addNewMetadataItem()}><PlusOutlined /></Button>
                {/* <Button $buttonType="normal" type="primary" onClick={() => addDefaultMetadataEntries()}>{Strings.strings.addDefaults}</Button> */}
            </div>
        </Modal >
    );
}

export default EditTranscriptionDocsDetailsModal;
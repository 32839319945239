import React, { useEffect, useState } from "react";

import API from "../api/API";

import { Slider } from "antd";

import moment from "moment";

import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';


type UserAction = {
    description: string
    id: number
    time_created: number
    transcription_id?: number
    user_action: number
    user_action_name?: string
    user_id: number
    username: string
}

const TeamworkComponent = () => {

    const [userActions, setUserActions] = useState<UserAction[]>([]);
    const [hours, setHours] = useState<number>(24);
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        API.getUserActions().then((json) => {
            setUserActions(json)
        })
    }, []);

    useEffect(() => {
        let newNow = new Date()
        // newNow.setDate(newNow.getDate() - days);
        newNow.setHours(newNow.getHours() - hours);
        setNow(newNow)
    }, [hours]);

    var usersByLines: { [id: string]: UserAction[]; } = {};

    userActions.forEach(userAction => {
        let clone = { ...userAction };
        let stringAsDate = new Date(clone.time_created)
        if (stringAsDate > now) {

            if (clone.username in usersByLines === false) {
                usersByLines[clone.username] = []
            }
            clone.time_created = stringAsDate.getTime()

            // if (userAction.user_action === 0) {
            //     clone.user_action_name = "logged_in"
            // }
            // else if (userAction.user_action === 1) {
            //     clone.user_action_name = "logged_out"
            // }
            // else if (userAction.user_action === 2) {
            //     clone.user_action_name = "tx_played_audio"
            // }
            // else if (userAction.user_action === 3) {
            //     clone.user_action_name = "tx_paused_audio"
            // }
            // else if (userAction.user_action === 4) {
            //     clone.user_action_name = "tx_edition_started"
            // }
            // else if (userAction.user_action === 5) {
            //     clone.user_action_name = "tx_edition_ended"
            // }
            // else if (userAction.user_action === 6) {
            //     clone.user_action_name = "tx_edition_adjusted_in"
            // }
            // else if (userAction.user_action === 7) {
            //     clone.user_action_name = "tx_edition_adjusted_out"
            // }
            // else if (userAction.user_action === 8) {
            //     clone.user_action_name = "tx_speaker_created"
            // }
            // else if (userAction.user_action === 9) {
            //     clone.user_action_name = "tx_speaker_assigned"
            // }
            // else if (userAction.user_action === 10) {
            //     clone.user_action_name = "tx_speaker_renamed"
            // }
            // else if (userAction.user_action === 11) {
            //     clone.user_action_name = "tx_findreplace_opened"
            // }
            // else if (userAction.user_action === 12) {
            //     clone.user_action_name = "tx_findreplace_closed"
            // }
            // else if (userAction.user_action === 1) {
            //     clone.user_action_name = "tx_findreplace_executed"
            // } else {
            //     clone.user_action_name = `${clone.user_action}`
            // }

            usersByLines[clone.username.toString()].push(clone)
        }
    })

    let lines = Object.entries(usersByLines).map(([key, value]) => {
        return <Scatter key={key} name={key} data={value} fill="#8884d8" line shape="cross" />
    });

    return (
        <div style={{ width: "100%", height: "100%", overflow: "scroll", marginBottom: "20px" }}>

            <div style={{ width: "90%", height: "500px", paddingBottom: "30px" }}>
                <Slider
                    style={{ display: "inline-block", minWidth: "200px", marginRight: "4px" }}
                    min={1}
                    defaultValue={24}
                    max={7 * 24}
                    step={1}
                    onChange={(value: number) =>
                        // setDays(value)
                        setHours(value)
                    }
                /> (Últimas {hours} horas / {Math.floor(hours / 24)} días)
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart
                        width={800}
                        height={400}
                        margin={{
                            top: 20,
                            right: 40,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid />
                        <XAxis
                            type="number"
                            dataKey="time_created"
                            name="date"
                            domain={['auto', 'auto']}
                            tickFormatter={(unixTime) => moment(unixTime).format('DD-MM-YYYY HH:mm')} />
                        {/* <YAxis type="category" dataKey="user_action_name" name="actiontype" /> */}
                        <YAxis type="number" dataKey="user_action" name="actiontype" />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Legend />
                        {lines}
                    </ScatterChart>
                </ResponsiveContainer>

            </div>
            <div>
                <p>0 = "logged_in"</p>
                <p>1 = "logged_out"</p>
                <p>2 = "tx_played_audio"</p>
                <p>3 = "tx_paused_audio"</p>
                <p>4 = "tx_edition_started"</p>
                <p>5 = "tx_edition_ended"</p>
                <p>6 = "tx_edition_adjusted_in"</p>
                <p>7 = "tx_edition_adjusted_out"</p>
                <p>8 = "tx_speaker_created"</p>
                <p>9 = "tx_speaker_assigned"</p>
                <p>10 = "tx_speaker_renamed"</p>
                <p>11 = "tx_findreplace_opened"</p>
                <p>12 = "tx_findreplace_closed"</p>
                <p>13 = "tx_findreplace_executed"</p>
            </div>
        </div>
    );
}
export default TeamworkComponent;
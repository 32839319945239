import React, { useState } from "react";
import API from "../api/API";

import { Form, Input, Button } from "antd";

import "antd/dist/antd.css";
import { PUDU_PRIMARY_COLOUR } from "../config/css";

import {
    Project
} from "../types";

const FormItem = Form.Item;

type CreateProjectComponentProps = {
    onCreateProject: (newProject: Project) => void
};

const CreateProjectComponent: React.FC<CreateProjectComponentProps> = props => {

    const [name, setName] = useState<string>("")
    const [saving] = useState<boolean>(false)

    const handleSubmit = (event: React.FormEvent) => {
        API.createProject(
            {
                name: name
            }
        ).then(data => {
            clearInput()
            props.onCreateProject(data)
            console.log("data", data)
        })
    };

    const clearInput = () => {
        setName("")
    }

    return (
        <div>
            <Form onFinish={handleSubmit} >
                <FormItem style={{ display: "inline-flex" }}>
                    <Input
                        type="name"
                        placeholder="nombre"
                        onChange={(e) => { setName(e.currentTarget.value) }}
                        value={name}
                        suffix={saving ? "guardando..." : ""}
                        width={800}
                    />
                </FormItem>

                <FormItem style={{ display: "inline-flex" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                        }}
                        className="login-form-button"
                    >
                        Crear proyecto
                    </Button>

                </FormItem>

            </Form>
        </div>
    );

}

export default CreateProjectComponent;
import React, { useEffect, useState } from "react";

import API from "../api/API";
import { Redirect } from "react-router-dom";

import { Table } from "antd";
import { RecentSMJobs, SMJobStatus, SMUsage } from "../types"

import TimeHelper from "../helpers/TimeHelper";
import { Months } from "../types/dates";
import { Days } from "../types/dates";

import { FileOutlined } from '@ant-design/icons';
import { PUDU_PRIMARY_COLOUR } from "../config/css";

const RecentBatchJobs = () => {

    const [jobs, setJobs] = useState<SMJobStatus[]>([]);
    const [usage, setUsage] = useState<SMUsage | undefined>(undefined);
    const [selectedTx, setSelectedTx] = useState<string | undefined>(undefined);
    const [redirectToTx, setredirectToTx] = useState<boolean>(false);

    function handleDownloadSMJson(smtx_id: string, transcription_id: number) {
        API.getSmTranscriptionJson(smtx_id, transcription_id);
    };


    useEffect(() => {
        API.getRecentBatchJobs().then((json: RecentSMJobs) => {
            console.log(json)
            setJobs(json.jobs.jobs)
            setUsage(json.usage)
        })

    }, []);

    const dataSource: any = jobs.map(smJobStatus => {
        return (
            {
                id: smJobStatus,
                txid: smJobStatus.config.tracking.details.transcription_id,
                userid: smJobStatus.config.tracking.details.userid,
                key: smJobStatus.id,
                createdAt: smJobStatus.created_at,
                duration: TimeHelper.fancyTimeFormatHMS(Number(smJobStatus.duration)),
                status: smJobStatus.status,
                title: smJobStatus.config.tracking.title,
                reference: smJobStatus.config.tracking.reference,
            }
        )
    })

    const columns: any = [
        {
            title: "sm id",
            dataIndex: "id",
            key: "key",
            render: (record: any) => {
                return (
                    <button
                        style={{ marginRight: "4px", background: "none", border: "none", cursor: "pointer", color: PUDU_PRIMARY_COLOUR }}
                        onClick={() => handleDownloadSMJson(record.id, record.config.tracking.details.transcription_id)}

                    >
                        <FileOutlined /> {record.id}
                    </button>
                )
            }
        },
        {
            title: "txid",
            dataIndex: "txid",
            key: "key",
            render: (record: string) => {
                return (
                    <button
                        style={{ marginRight: "4px", background: "none", border: "none", cursor: "pointer", color: PUDU_PRIMARY_COLOUR }}
                        onClick={() => {
                            let url = "/transcription/" + record;
                            setSelectedTx(url)
                            setredirectToTx(true)
                        }}
                    >
                        {record}
                    </button>
                )
            }
        },
        {
            title: "userid",
            dataIndex: "userid",
            key: "key",
            render: (record: string) => {
                return (
                    <button
                        style={{ marginRight: "4px", background: "none", border: "none", color: PUDU_PRIMARY_COLOUR }}
                        onClick={() => {
                            let url = "users/profile/" + record;
                            setSelectedTx(url)
                            setredirectToTx(true)
                        }}
                    >
                        {record}
                    </button>
                )
            }
        },
        {
            title: "createdAt",
            dataIndex: "createdAt",
            key: "key",
            render: (record: any) => {
                let dateCreated = new Date(record);
                let formattedDate = `${dateCreated.getHours().toString().padStart(2, '0')}:${dateCreated.getMinutes().toString().padStart(2, '0')},  ${Days[dateCreated.getUTCDay()]
                    } ${dateCreated.getUTCDate()} ${Months[dateCreated.getUTCMonth()]
                    } ${dateCreated.getUTCFullYear()}`;
                return formattedDate
            },
        },
        {
            title: "title",
            dataIndex: "title",
            key: "key"
        },
        {
            title: "reference",
            dataIndex: "reference",
            key: "key"
        },
        {
            title: "duration",
            dataIndex: "duration",
            key: "key"
        },
        {
            title: "status",
            dataIndex: "status",
            key: "key"
        },
    ];

    if (redirectToTx) {
        if (selectedTx) {
            return <Redirect to={selectedTx} />
        }
    }


    let usageStuff;
    if (usage) {
        let details = usage.details.map((detail, id) => {
            return (<div key={`details-${detail.type}-${detail.mode}${id}`} style={{ paddingBottom: "16px" }}>
                <div>operating mode: <b>{detail.operating_point}</b></div>
                <div>jobs count: <b>{detail.count}</b></div>
                <div>total hours: <b>{detail.duration_hrs}</b></div>
                <div>language: <b>{detail.language}</b></div>
                <div>mode: <b>{detail.mode}</b></div>
                <div>type: <b>{detail.type}</b></div>
            </div>)
        })

        let summary = usage.summary.map(detail => {
            return (<div key={`summary-${detail.type}-${detail.mode}`} style={{ paddingBottom: "6px" }}>
                <div>jobs count: <b>{detail.count}</b></div>
                <div>total hours: <b>{detail.duration_hrs}</b></div>
                <div>mode: <b>{detail.mode}</b></div>
                <div>type: <b>{detail.type}</b></div>
            </div>)
        })

        usageStuff = (
            <div>
                <div>
                    <h3>Summary</h3>
                    <div>
                        since: <b>{usage?.since}</b>
                    </div>
                    <div>
                        until: <b>{usage?.until}</b>
                    </div>

                    {summary}
                </div>
                <div
                    style={{ paddingTop: "12px" }}
                >
                    <h3>Details</h3>
                    {details}
                </div>
            </div>
        )
    }

    console.log("usage", usage)
    return (
        <div
            style={{
                display: "flex",
                alignItems: "top",
                justifyContent: "left",
                paddingTop: "8px",
                paddingLeft: "8px"
            }}
        >
            <div>
                {usageStuff}
            </div>
            <div
                style={{ paddingLeft: "12px" }}
            >
                <h3>Recent SM batch jobs</h3>
                <Table
                    pagination={{ position: ['bottomRight'], pageSize: 10 }}
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                />
            </div>
        </div>

    );
}
export default RecentBatchJobs;
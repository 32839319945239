/** @jsx jsx */
import { useState, useEffect } from "react";

import API from "../api/API";

import { jsx } from "@emotion/core";
import "./VerifyUserEmailComponent.scss";
import { VideoLink } from "../types";

const UserManual = () => {
    const [videoTutorialLink, setVideoTutorialLink] = useState<string | undefined>();

    useEffect(() => {
        handleGetTutorialVideo()
    }, [])

    const handleGetTutorialVideo = () => {
        API.getTutorialVideo().then((data: VideoLink) => {
            if (data !== undefined) {
                setVideoTutorialLink(data.url)
            }
        });
    };


    let video;
    if (videoTutorialLink) {
        video = (
            <div style={{ paddingTop: "12px", display: "block", padding: "12px" }}>
                <video
                    style={{ maxWidth: "640px", width: "100%", height: "auto" }}
                    autoPlay={false}
                    loop
                    controls={true}>
                    <source src={videoTutorialLink} type="video/mp4" />
                </video>
            </div>
        )
    }


    return (
        <div style={{
            position: "fixed",
            left: "50%",
            top: "50%",

            maxWidth: "640px", width: "100%",
            transform: "translate(-50%, -50%)"
        }}
        >
            {video}
        </div>
    );

}
export default UserManual;
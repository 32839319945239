import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    UserProfile,
} from "../types";

interface UserProfileState {
    userProfile: UserProfile | undefined,
}

const initialState: UserProfileState = {
    userProfile: undefined,
};

export const userProfileSlice = createSlice({
    name: "userTranscriptionsList",
    initialState: initialState,
    reducers: {
        updateUserProfile: (state, userProfile: PayloadAction<UserProfile>) => {
            state.userProfile = userProfile.payload
        },
    },
});

export const {
    updateUserProfile
} = userProfileSlice.actions;

const userProfileReducer = userProfileSlice.reducer
export default userProfileReducer;
import React, { useEffect, useState, useRef } from "react";
import useDebounce from "../helpers/Debounce"
import { Table, AutoComplete, DatePicker } from "antd";

import TimeHelper from "../helpers/TimeHelper";
import {
    User,
    BatchTranscriptionJob,
    Client,
    Group
} from "../types/index";

import "./UserTranscriptionJobsAdmin.css";

import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { Strings } from "../strings";
import API from "../api/API"
import { Form, Input, Tag } from "antd";
import { RefSelectProps } from "rc-select";


type TranscriptionSubmittedByUserProps = {
    clientId?: number
};

const { RangePicker } = DatePicker;

const TranscriptionSubmittedByUserComponent: React.FC<TranscriptionSubmittedByUserProps> = props => {

    const [batchTranscriptionJobs, setBatchTranscriptionJobs] = useState<BatchTranscriptionJob[] | undefined>(undefined)

    const [availableUsers, setAvailableUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [possibleUsers, setPossibleUsers] = useState<{ value: string }[]>([]);
    const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [clientId, setClientId] = useState<number | undefined>(props.clientId);

    const [currentIndex, setCurrentIndex] = useState<number>(1);
    // const [currentSize, setCurrentSize] = useState<number>(50);
    const [totalBatchTranscriptionJobs, setTotalBatchTranscriptionJobs] = useState<number>(0)

    const [userPattern, setUserPattern] = useState<string>("");

    const debouncedUserPattern = useDebounce<string>(userPattern, 500)

    const autoCompleteInputRef = useRef<Input>(null)
    const autoCompleteRef = useRef<RefSelectProps | null>(null)

    useEffect(() => {
        if (props.clientId) {
            setUserPattern("*")
            updateTranscriptionsForUsersMatchingPattern()
        }
    }, [])

    // Fetch API (optional)
    useEffect(() => {
        if (userPattern.length > 0) {
            updateTranscriptionsForUsersMatchingPattern()
        } else {
            updateTranscriptionsMatchingUsernames()
        }
    }, [debouncedUserPattern, currentIndex])

    useEffect(() => {
        let values = availableUsers.map(availableUser => { return { value: availableUser.username } });
        setPossibleUsers(values);
    }, [availableUsers]);

    useEffect(() => {
        updateTranscriptionsMatchingUsernames()
    }, [selectedUsers, startDate, endDate]);

    const updateTranscriptionsForUsersMatchingPattern = () => {
        console.log("updateTranscriptionsForUsersMatchingPattern")
        if (userPattern.length > 0) {
            setIsLoading(true)
            API.getTranscriptionsSubmittedByUsersMatchingPattern(currentIndex, 50, userPattern, clientId, startDate, endDate)
                .then((results) => {
                    setIsLoading(false)
                    setTotalBatchTranscriptionJobs(results["total"])
                    // setCurrentSize(results["size"])
                    setBatchTranscriptionJobs(results["items"])
                })
        }
    }

    const updateTranscriptionsMatchingUsernames = () => {
        if (userPattern.length === 0) { //need to improve this - pattern vs single user search
            let selectedUserIds = selectedUsers.map(user => user.id.toString())
            if (selectedUserIds.length > 0) {
                setIsLoading(true)
                API.getTranscriptionsSubmittedByUsers(currentIndex, 50, selectedUserIds, clientId, startDate, endDate)
                    .then(results => {
                        setIsLoading(false)
                        setTotalBatchTranscriptionJobs(results["total"])
                        // setCurrentSize(results["size"])
                        setBatchTranscriptionJobs(results["items"])
                    })
            }
        }
    }

    const onAddUser = (data: string) => {
        autoCompleteRef.current?.blur()
        // autoCompleteInputRef.current?.setValue("")
        let user = availableUsers.find(user => user.username === data)
        if (user !== undefined) {
            let thisUser = user;
            let users = [...selectedUsers]
            let exists = users.findIndex(existinguser => existinguser.id === thisUser.id)
            if (exists === -1) {
                users.push(user)
                setSelectedUsers(users);
            }
        }
    };

    const onSearch = (searchText: string) => {

        if (searchText.charAt(0) === "*") {
            setCurrentIndex(1)
            setUserPattern(searchText)
        }
        else if (searchText.length > 0) {
            setCurrentIndex(1)
            API.usersMatchingPattern(searchText, clientId)
                .then((results: User[]) => {
                    setAvailableUsers(results)
                })
        }
    };

    const onRemoveUserAt = (index: number) => {
        let users = [...selectedUsers]
        users.splice(index, 1)
        setSelectedUsers(users);
    };

    const onPaginationChange = (page: number) => {
        setCurrentIndex(page)
    }

    const dataSourceTranscriptions: any = batchTranscriptionJobs?.map((transcriptionJob) => {
        return ({
            key: transcriptionJob.id,
            id: transcriptionJob.transcription_id,
            batchid: transcriptionJob.id,
            duration: transcriptionJob.original_file_duration,
            date: transcriptionJob.time_created,
            filename: transcriptionJob,
            user: transcriptionJob,
            client: transcriptionJob.client,
            group: transcriptionJob.group,
        })
    })

    const columnsTranscriptions: any = [
        {
            title: Strings.strings.id,
            dataIndex: "id",
            key: "id",
            sorter: (a: any, b: any) => a.id < b.id,
        },
        {
            title: Strings.strings.file,
            dataIndex: "filename",
            key: "filename",
            sorter: (a: any, b: any) => a.filename.original_file_name.localeCompare(b.filename.original_file_name),
            render: (record: BatchTranscriptionJob) => {
                return (
                    <div>
                        <div style={{ fontSize: "0.8em" }}>{record.original_file_name}</div>
                        <div style={{ fontSize: "0.8em" }}>Título: {record.batchjob_transcription.title}</div>
                        <div style={{ fontSize: "0.8em" }}>Descripción: {record.batchjob_transcription.description}</div>
                    </div>
                )
            }
        },
        {
            title: Strings.strings.client,
            dataIndex: "client",
            key: "client",
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            render: (client: Client) => {
                return (
                    <div>{client?.name}</div>
                )
            }
        },
        {
            title: Strings.strings.group,
            dataIndex: "group",
            key: "group",
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            render: (group: Group) => {
                return (
                    <div>{group?.name}</div>
                )
            }
        },
        {
            title: Strings.strings.duration,
            dataIndex: "duration",
            key: "duration",
            sorter: (a: any, b: any) => a.id < b.id,
            render: (duration: number) => {
                return TimeHelper.hoursMinutesFormat(duration)
            }
        },
        {
            title: Strings.strings.creationDate,
            dataIndex: "date",
            key: "date",
            sorter: (a: any, b: any) => {
                console.log("date", a.date, b.date)
                return a.date.localeCompare(b.date)
            },
            render: (time_created: string) => {
                let dateString = time_created.replace(/ /g, "T") //fix for Safari
                let dateItem = new Date(dateString);
                dateItem.setHours(dateItem.getHours() - dateItem.getTimezoneOffset() / 60);
                const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'America/Santiago' };
                let localDate = dateItem.toLocaleDateString('es-CL', options)
                return localDate
            },

        },
        {
            title: Strings.strings.user,
            dataIndex: "user",
            key: "user",
            //sorter: (a: any, b: any) => a. < b.id,
            render: (job: BatchTranscriptionJob) => {
                let user = availableUsers.find(user => user.id === job.user_id)?.email ?? ""
                return (
                    <div>
                        {job.user?.username}
                    </div>
                )
            },
        },
    ];

    let selectedUsersPills = selectedUsers.map((user, index) => {
        return (
            <Tag key={index} closable onClose={e => onRemoveUserAt(index)}>{user.username}</Tag>
        )
    })

    let totalDuration = 0

    batchTranscriptionJobs?.forEach(i => { totalDuration += i.original_file_duration })

    return (
        <div style={{ paddingBottom: "60px" }}>
            <RangePicker
                onChange={(values, formattedDates) => {
                    setStartDate(formattedDates[0])
                    setEndDate(formattedDates[1])
                }}
            />
            <AutoComplete
                ref={autoCompleteRef}
                options={possibleUsers}
                style={{ width: 200 }}
                onSelect={onAddUser}
                onSearch={onSearch}
                placeholder="search usernames here"
                open={autoCompleteOpen}
                onFocus={e => setAutoCompleteOpen(true)}
                onBlur={e => setAutoCompleteOpen(false)}
                allowClear={true}
                children={(
                    <Input
                        ref={autoCompleteInputRef}
                    ></Input>
                )}
            />
            {selectedUsersPills}
            <div>
                Total duration: {TimeHelper.fancyTimeFormat(totalDuration)}
            </div>
            <div>
                Total txs: {totalBatchTranscriptionJobs}
            </div>
            <div style={{ paddingBottom: "140px", height: "100vh", overflow: "scroll" }}>
                <Table
                    pagination={{
                        current: currentIndex,
                        position: ['bottomLeft', 'topLeft'],
                        pageSize: 50,
                        total: totalBatchTranscriptionJobs,
                        showSizeChanger: false,
                        onChange: e => onPaginationChange(e)
                    }}
                    dataSource={dataSourceTranscriptions}
                    columns={columnsTranscriptions}
                    size="small"
                    scroll={{ "x": true }}
                    loading={isLoading}
                />
            </div>
        </div>
    );

}

export default TranscriptionSubmittedByUserComponent;
import React, { useEffect, useState } from "react";
import API from "../api/API";

import { Input, Table, Button } from "antd";
import { Form } from "antd";

import { PUDU_PRIMARY_COLOUR } from "../config/css";

import {
    ProjectStatusType,
    ProjectStatusTypeOut
} from "../types/index";

const FormItem = Form.Item;

type TranscriptionStatusListProps = {};

const TranscriptionStatusListComponent: React.FC<TranscriptionStatusListProps> = props => {

    const [projectStatusTypes, setProjectStatusTypes] = useState<ProjectStatusType[]>([])
    const [name, setName] = useState<string>("")
    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
        getProjectStatusTypes()
    }, []);

    const getProjectStatusTypes = () => {
        API.getProjectStatusTypes().then((data: ProjectStatusType[]) => {
            setProjectStatusTypes(data)
            clearInput()
        })
    }

    const clearInput = () => {
        setSaving(false)
        setName("")
    }

    const handleSubmit = (event: React.FormEvent) => {
        let newProjectStatusType: ProjectStatusTypeOut = {
            name: name
        }
        API.addProjectStatusType(newProjectStatusType).then((data: ProjectStatusType) => {
            let newItems = projectStatusTypes
            newItems.push(data)
            setProjectStatusTypes(newItems)
            clearInput()
        })
    };

    const dataSource: any = projectStatusTypes
        .map((projectStatusType) => {
            return ({
                key: projectStatusType.id,
                id: projectStatusType.id,
                name: projectStatusType.name,
                createdBy: projectStatusType.user_id,
                timeCreated: projectStatusType.time_created
            })
        })

    const columns: any = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        }
    ];

    return (
        <div style={{ paddingRight: "20px" }}>

            <div>
                <Form onFinish={handleSubmit} >
                    <FormItem style={{ display: "inline-flex" }}>
                        <Input
                            type="name"
                            placeholder="nombre"
                            onChange={(e) => { setName(e.currentTarget.value) }}
                            value={name}
                            suffix={saving ? "guardando..." : ""}
                            width={800}
                        />
                    </FormItem>

                    <FormItem style={{ display: "inline-flex" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                background: PUDU_PRIMARY_COLOUR, borderColor: PUDU_PRIMARY_COLOUR
                            }}
                            className="login-form-button"
                        >
                            Agregar otro tipo de estatus
                        </Button>

                    </FormItem>

                </Form>
            </div>

            <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                size="small"
            />
        </div >
    );
}

export default TranscriptionStatusListComponent;
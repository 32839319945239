import { loginOtpSend, loginOtpVerify } from "./http";
import {
  startInAppSession,
  appSessionExists,
  finishAppSession,
  getSessionToken
} from "./session";

export const httpService = {
  loginOtpSend,
  loginOtpVerify
};

export const sessionService = {
  startInAppSession,
  appSessionExists,
  finishAppSession,
  getSessionToken
};

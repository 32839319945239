import React from "react";

import logo from "../images/tp_logo_orange.svg";

const BrandLogo: React.FC = () => {

    return (
        <div style={{
            textAlign: "center",
            display: "block",
            marginBottom: "50px"
        }}>
            <img
                style={{
                    width: "136px",
                    height: "136px"

                }}
                src={logo}
                alt="Logo"
            />
        </div>
    );
};

export default BrandLogo;
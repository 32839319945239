
import React, { useState, useEffect } from "react";
import CreateSpeaker from "./CreateSpeaker";

import TranscriptionSpeakerColourComponent from "./TranscriptionSpeakerColourComponent"
import { Input, Table, Checkbox, Button } from "antd";

import {
    Speaker
} from "../types/index";

import { LoadingOutlined } from '@ant-design/icons';

type TranscriptionSpeakersTableProps = {
    regionId?: number;
    currentRegionSpeakerId?: number;
    speakers: Speaker[];
    transcription_id: number;
    handleUpdateSpeakerForRegions: (
        transcription_id: number,
        speaker_id?: number,
        edition_id?: number[],
    ) => void;
    createSpeaker: (speaker: Speaker, onSuccess: () => void) => void
};

const TranscriptionSpeakersTableComponent: React.FC<TranscriptionSpeakersTableProps> = props => {

    const [speakers, setSpeakers] = useState<Speaker[]>([])
    const [currentSpeakerUnsaved, setCurrentSpeakerUnsaved] = useState<Speaker | undefined>(undefined)
    const [speakersSaving, setSpeakersSaving] = useState<number[]>([])

    let shouldSaveCurrentSpeakerUnsaved = false

    useEffect(() => {
        if (!currentSpeakerUnsaved) {
            setSpeakers(props.speakers)
        }
    }, [props]);


    const dataSource: any = speakers
        .sort((a, b) => (a.name ?? "").localeCompare((b.name ?? "")))
        .filter((speaker) => speaker !== undefined)
        .map((speaker) => {
            return ({
                key: speaker.id,
                id: speaker,
                name: speaker,
                speakerForRegion: speaker,
                rgb: speaker
            })
        })

    const columns: any = [
        {
            title: "",
            dataIndex: "speakerForRegion",
            width: props.regionId === undefined ? 0 : 50,
            key: "speakerForRegion",

            render: (speaker: Speaker) => {
                return (
                    <Checkbox
                        checked={speaker?.id === props.currentRegionSpeakerId}
                        style={{ "display": props.regionId === undefined ? "none" : "block", "visibility": props.regionId === undefined ? "hidden" : "visible" }}
                        onClick={() => {
                            if (props.regionId !== undefined) {

                                let checked = speaker?.id === props.currentRegionSpeakerId
                                props.handleUpdateSpeakerForRegions(
                                    props.transcription_id,
                                    checked === true ? undefined : speaker.id,
                                    [props.regionId]
                                )
                            }
                        }}
                    />

                )
            }
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            width: 400,
            render: (speaker: Speaker, value: string, index: number) => {

                let suffix;
                if (speaker.id) {
                    suffix = speakersSaving.includes(speaker.id) ? <LoadingOutlined /> : <span />
                }

                return (
                    <Input
                        style={{ width: 'calc(100% - 50px)' }}
                        onChange={(e) => {
                            setCurrentSpeakerUnsaved(Object.assign({}, speakers[index], {
                                name: e.target.value
                            }))
                        }}
                        value={
                            (speaker.id === currentSpeakerUnsaved?.id) ? currentSpeakerUnsaved?.name : speaker.name
                        }
                        onPressEnter={(e) => {

                            shouldSaveCurrentSpeakerUnsaved = true
                            e.currentTarget.blur()

                            if (currentSpeakerUnsaved?.id) {
                                if (!speakersSaving.includes(currentSpeakerUnsaved.id)) {
                                    speakersSaving.push(currentSpeakerUnsaved.id)
                                }
                                props.createSpeaker(currentSpeakerUnsaved, () => {
                                    if (speaker.id) {
                                        let speakersSavingUpdated = speakersSaving.filter(speakerId => speakerId !== speaker.id)
                                        setSpeakersSaving(speakersSavingUpdated)
                                    }
                                    shouldSaveCurrentSpeakerUnsaved = false
                                    setCurrentSpeakerUnsaved(undefined)
                                })
                            }

                        }}
                        onBlur={() => {
                            if (!shouldSaveCurrentSpeakerUnsaved) {
                                setCurrentSpeakerUnsaved(undefined)
                                setSpeakers(props.speakers)
                            }
                        }}
                        onFocus={() => setCurrentSpeakerUnsaved(speakers[index])}
                        suffix={suffix}

                    />
                )
            }
        },
        {
            title: "Color",
            dataIndex: "rgb",
            key: "rgb",
            render: (speaker: Speaker, value: string, index: number) => {
                if (speaker.id) {
                    return (
                        < TranscriptionSpeakerColourComponent
                            speaker={speaker}
                            transcription_id={props.transcription_id}
                            createSpeaker={props.createSpeaker}
                        />
                    )
                }
            }
        }
    ];

    return (
        <div>
            <CreateSpeaker
                transcription_id={props.transcription_id}
                createSpeaker={props.createSpeaker}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: "100%",
                        maxHeight: '600px',
                        overflow: "scroll"
                    }}
                >
                    <Table
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                    />
                </div>
            </div>
        </div >

    );
}

export default TranscriptionSpeakersTableComponent;

import React, { Component, createRef } from "react";

import { FormInstance } from "antd";

import UserFormComponent from "./UserFormComponent"
import { UserFormDataProps } from "../types/index"

type CreateUserProps = {
    userFormData: UserFormDataProps
    formTitle: string;
    handleCreateUser: (username: string, password: string, email: string, admin: boolean, editor: boolean, enabled: boolean) => void;
};

interface CreateUserFormState {
    userFormData: UserFormDataProps;
}

export default class CreateUserComponent extends Component<CreateUserProps, CreateUserFormState> {

    formRef = createRef<FormInstance>()

    constructor(props: CreateUserProps) {
        super(props);
        this.state = {
            userFormData: this.props.userFormData,
        };
    }

    componentWillReceiveProps(nextProps: CreateUserProps) {
        console.log("CreateUserComponent componentWillReceiveProps", nextProps)
        this.setState({
            userFormData: nextProps.userFormData,
        });
    }

    handleSubmitSignup = (formData: UserFormDataProps) => {
        console.log("formData", formData)
        if (formData.password) {
            this.props.handleCreateUser(formData.username, formData.password, formData.email, formData.admin, formData.editor, formData.enabled)
        } else {
            alert("missing password")
        }
    };

    render() {
        return (
            <div>
                <UserFormComponent
                    userFormData={this.state.userFormData}
                    title="Create User"
                    handleSubmitForm={this.handleSubmitSignup}
                    requiresPassword={true}
                />
            </div >
        );
    }
}

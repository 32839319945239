import React from "react";
import API from "../api/API";

//@ts-ignore

import { Table, Button, Checkbox } from "antd";
import { Input } from 'antd';
import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";

import {
    Transcription,
    User,
    TranscriptionUserPermission,
} from "../types/index";

type TranscriptionUsersProps = {
    transcription: Transcription;
};

type TranscriptionUsersState = {
    redirect: string | null;
    transcription: Transcription;
    allUsers: User[]
    id: number | null
    searchTerm: string | null;
};

type TranscriptionUser = {
    id: number;
    username: string;
    included: boolean
}

export class TranscriptionUsersComponent extends React.Component<
    TranscriptionUsersProps,
    TranscriptionUsersState
> {

    constructor(props: TranscriptionUsersProps) {
        super(props);

        this.state = {
            redirect: null,
            transcription: props.transcription,
            allUsers: [],
            id: null,
            searchTerm: null
        };

        this.handleGetUsers();
    }

    componentDidMount() {
        console.log("mounted");
    }

    handleGetUsers = () => {
        API.getUsers().then((data: User[]) => {
            console.log("handleGetUsers done", data);
            this.setState({
                allUsers: data
            });

            this.handleGetUsersForTranscription();
        });
    };

    handleGetUsersForTranscription = () => {
        //@ts-ignore
        const id = parseInt(this.props.computedMatch.params.id, 10);
        console.log("handleGetUsersForTranscription", id);
        API.getUsersForTranscription(id).then((data: Transcription) => {
            console.log("getUsersForTranscription done", data);
            this.setState({
                transcription: data,
                id: id
            })
        });
    };

    handleRemoveUserFromTX = (userid: number) => {
        if (this.state.id) {
            API.removeUserFromTranscription(userid, this.state.id).then((data: Transcription) => {
                console.log("handleRemoveUserFromTX done", data);
                this.setState({
                    transcription: Object.assign({}, this.state.transcription, {
                        users: data
                    }),
                })
            });
        }
    };

    handleAddUserToTX = (userid: number) => {
        if (this.state.id) {
            API.addUserToTranscription(userid, this.state.id).then((data: Transcription) => {
                console.log("handleRemoveUserFromTX done", data);
                this.setState({
                    transcription: Object.assign({}, this.state.transcription, {
                        users: data
                    }),
                })

            });
        }
    };

    handleUserCanEdit = (userid: number, canEdit: boolean, id: number | null) => {
        if (this.state.id) {
            API.updateUserCanEditTranscription(
                userid,
                canEdit,
                this.state.transcription.id,
                id).then((data: TranscriptionUserPermission[]) => {
                    console.log("handleUserCanEdit", data);
                    this.setState({
                        transcription: Object.assign({}, this.state.transcription, {
                            user_permissions: data
                        }),
                    })
                });
        }

    }

    render() {

        console.log("TX users", this.state.transcription)

        let users: TranscriptionUser[] = []
        if (this.state.allUsers !== undefined) {
            users = this.state.allUsers.map((user) => {
                let included = -1
                if (this.state.transcription !== undefined) {
                    if (this.state.transcription.users !== undefined) {
                        included = this.state.transcription.users.findIndex(incUser => incUser.id === user.id)
                    }
                }

                return {
                    id: user.id,
                    username: user.username,
                    included: included === -1 ? false : true
                }
            })
        }

        const dataSource: any = users
            .filter(a => a.username.includes(this.state.searchTerm ?? ""))
            .map((user) => {
                return ({
                    key: user.id,
                    id: user.id,
                    include: user,
                    canEdit: user,
                    username: user.username,
                })
            })

        const columns: any = [
            {
                title: "Id",
                dataIndex: "id",
                key: "id",
                // defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.id.localeCompare(b.id),
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
                // defaultSortOrder: 'descend',
                sorter: (a: any, b: any) => a.username.localeCompare(b.username),
            },
            {
                title: "Can edit",
                dataIndex: "include",
                key: "include",
                render: (user: TranscriptionUser) => {
                    if (this.state.transcription !== undefined) {
                        if (this.state.transcription.users !== undefined) {

                            let enabledUser = this.state.transcription.users.find(user => user.enabled === true)
                            let found = this.state.transcription.user_permissions.find(permission => permission.user_id === user.id)
                            if (found) {
                                console.log("find", user.username, user.id, found?.transcription_id, found?.user_id, found?.id, found?.can_edit);
                            }

                            if (enabledUser !== undefined) {
                                if (enabledUser.enabled === true) {
                                    if (user.included === true) {
                                        let canEdit = found?.can_edit ?? false
                                        return (
                                            <Checkbox
                                                checked={canEdit}
                                                onClick={() => {
                                                    this.handleUserCanEdit(user.id, !canEdit, found?.id ?? null)
                                                }}
                                            />

                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                title: "Include User",
                dataIndex: "include",
                key: "include",
                render: (user: TranscriptionUser) => {
                    let buttonColour = user.included === true ? PUDU_SECONDARY_COLOUR : PUDU_PRIMARY_COLOUR
                    return (
                        <Button
                            type="primary"
                            style={{
                                background: `${buttonColour}`, borderColor: `${buttonColour}`
                            }}
                            danger={user.included === true}
                            onClick={() => {
                                user.included === true ? this.handleRemoveUserFromTX(user.id) : this.handleAddUserToTX(user.id)
                            }}
                        >
                            {user.included === true ? "Eliminar" : "Agregar"}
                        </Button>
                    )
                }
            },
        ];
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    paddingRight: "12px"
                }}
            >
                <div className="contentWrapper" style={{ marginTop: "2px" }}>


                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            overflow: "hidden",
                            paddingRight: "12px"
                        }}
                    >
                        <div style={{ paddingTop: "6px", paddingLeft: "6px" }}>
                            <h3>Users for transcription: {this.state.id}</h3>
                        </div>
                        <div style={{ flexGrow: 1 }}></div>
                    </div>
                    <div style={{ paddingBottom: "10px" }}>
                        <Input
                            style={{
                                width: 240,
                            }}
                            placeholder="username..."
                            onChange={(text) => {
                                this.setState({ "searchTerm": text.target.value })
                            }}
                            allowClear
                        />
                    </div>
                    <div>
                        <Table
                            pagination={{ position: ['bottomLeft'], pageSize: 20 }}
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export class Strings {
    static strings = {
        action: "Acción",
        addDefaults: "Añadir predeterminados",
        account: "Cuenta",
        admin: "Admin",
        activeRegion: "Región activa",
        assignSpeaker: "(Asignar speaker)",
        audienceType: "Tipo de auciencia",
        autoScrollMode: "Modo de Autoscroll",
        batchJobId: "Batchjob ID",
        buffering: "buffering...",
        cancel: "Cancelar",
        checkingConnection: "Probando la conexión",
        client: "Cliente",
        clientCreated: "Cliente creado",
        clientUpdated: "Cliente actualizado",
        closeTheTranscription: "Cerrar la transcripción?",
        cloneDictionary: "Clonar diccionario",
        code: "Código",
        confirmDeleteContent: "¿Estás seguro de borrar esta región?",
        confirmMergeContent: "¿Quieres unir con la siguiente región? ",
        customDictionary: "Diccionario custom",
        confirmNewPassword: "Confirmar contraseña nueva",
        creationDate: "Fecha de creación",
        declarant: "Declarante",
        deleting: "Eliminando...",
        delete: "Eliminar",
        deleteTranscription: "Eliminar transcipción",
        description: "Descripción",
        descriptionObligatoryField: "Descripción (campo obligatorio)",
        dictionaryClonedSuccesfully: "El diccionario ha sido duplicado con éxito",
        downloadingWaveform: "Descargando waveform",
        downloadingTranscription: "Descargando transcripción",
        downloadAllDocx: "Descargar docx (todos)",
        downloads: "Descargas",
        docx: "docx",
        done: "listo",
        download: "Descargar",
        dragYourFilesOrClick: "Arrastra tus archivos acá o haz click para seleccionar los archivos manualmente",
        duration: "Duración",
        editor: "Editor",
        english: "Inglés",
        email: "correo electrónico",
        enterOtpYouReceived: "Ingresa el código que hemos enviado a tu correo electrónico",
        enterTheCodeWeEmailedYou: "Ingresa el código de verificación que hemos enviado a tu correo y crea tu contraseña",
        enterWithAnotherAccount: "Ingresar con otra cuenta",
        error: "error",
        file: "Archivo",
        findAndReplace: "Buscar y reemplazar",
        group: "Grupo",
        id: "ID",
        import: "Importar",
        inReview: "en revisión",
        includeInFindAndReplace: "Incluir en buscar y reemplazar",
        includeUser: "Incluir usuario",
        incorrectPassword: "Contraseña incorrecta",
        info: "Info",
        language: "Idioma",
        loading: "cargando...",
        login: "Ingresar",
        logout: "Cerrar Sesión",
        markAsDone: "Marcar como listo",
        m4a: "m4a",
        manual: "Manual",
        metadata: "Metadata",
        merging: "Mergeando...",
        name: "Nombre",
        newPassword: "Contraseña nueva",
        no: "No",
        off: "Apagado",
        otpInvalid: "Código inválido",
        paragraphStart: "Comienzo de párrafo",
        password: "Contraseña",
        pleaseDontUseSpecialCharacters: "Por favor no usar caracteres especiales en el nombre del archivo",
        generating: "generando",
        incorrectInformation: "Información incorrecta",
        inorderToLoginPleaseVerifyYourAccount: "Para ingresar a la aplicación, por favor verifica tu cuenta",
        pleaseEnterPassword: "Por favor ingrese su contraseña",
        pleaseHaveSupportContactMe: "Por favor contáctenme desde soporte",
        process: "Procesar",
        processingState: "Estado de procesamiento",
        processing: "procesando",
        processWithASR: "Procesar con ASR",
        processWithoutASR: "Procesar sin ASR",
        progress: "Progreso",
        project: "Proyecto",
        projects: "Proyectos",
        recentBatchJobs: "Recent Batch Jobs",
        recoverPassword: "Recuperar contraseña",
        pleaseCheckYourEmail: "Se han enviado a su dirección de correo electrónico instrucciones sobre cómo cambiar su contraseña",
        realtime: "Realtime",
        remove: "Sacar",
        removeFromTheList: "Remover",
        save: "Guardar",
        saving: "Guardando...",
        sendCodeToMyEmail: "Enviar código a mi correo",
        sendAnNewCodeToMyEmail: "Enviar un nuevo código",
        shortcuts: "Atajos",
        size: "Tamaño",
        somethingWeirdHappenedTryAgain: "Algo ha ocurrido, vuelve a intentarlo",
        spanish: "Español",
        speakers: "Speakers",
        state: "Estado",
        status: "Status",
        successfullySentWithTXID: "Enviado con éxito. ID Transcripción: ",
        teamwork: "Teamwork",
        theCodeWasSuccessfullySentTo: "El código fue enviado con éxito a: ",
        text: "Texto",
        title: "Título",
        titleObligatoryField: "Título (campo obligatorio)",
        tjpson: "tjpson",
        transcription: "Transcripción",
        transcriptions: "Transcripciones",
        transcriptionMetadata: "Transcription - metadata",
        trialEnded: "Estimado usuario:\n\nSu periodo de prueba ha expirado.\n\nGracias por usar Team Pudú.",
        updateUser: "Actualizar usuario",
        update: "Actualizar",
        updatePassword: "Actualizar contraseña",
        uploadFiles: "Subir Archivos",
        uploadFilesDisabled: "El servicio de carga de archivos no está disponible",
        uploadFilesDisabledSubtitle: "Esto puede ser causado por una VPN, proxy, firewall o antivirus activo en tu equipo o red local. Por favor revisa tus sistemas de seguridad e inténtalo nuevamente.",
        user: "Usuario",
        userCreatedSuccessfully: "Usuario actualizado con éxito.",
        verificationCode: "Código de verificación",
        verifyAccount: "Verificar cuenta",
        yes: "Si",
    }
}


import React, { Component } from "react";
import API from "../api/API";
//@ts-ignore
import * as bcrypt from 'bcryptjs';
import { Table } from "antd";
import UserFormComponent from "./UserFormComponent"
import {
    User,
    BatchTranscriptionJob,
    UserFormDataProps,
    Project,
    UserTranscriptionsList
} from "../types/index";
import { Tabs } from "antd";
import { Button } from "antd";
import { Strings } from "../strings"

const { TabPane } = Tabs;

type EditUserProps = {
    id: number
    userFormData: UserFormDataProps
    formTitle: string;
};
interface EditUserState {
    id: number,
    userFormData?: UserFormDataProps;
    transcriptionJobs: BatchTranscriptionJob[];
    projects: Project[];
}

export default class EditUserComponent extends Component<EditUserProps, EditUserState> {
    constructor(props: EditUserProps) {
        super(props);

        //@ts-ignore
        let id = parseInt(this.props.computedMatch.params.id, 10);

        this.state = {
            id: id,
            transcriptionJobs: [],
            projects: []
        };

    }

    componentDidMount() {
        this.handleGetUser()
        this.handleGetTranscriptionsForUser()
    }

    handleGetUser = () => {
        API.getUser(this.state.id).then((data: User) => {
            if (data !== undefined) {
                console.log(data)
                this.setState({
                    userFormData: {
                        username: data.username,
                        email: data.email,
                        enabled: data.enabled,
                        editor: data.editor,
                        admin: data.admin,
                        verified: data.verified ?? false,
                        can_process_files: data.can_process_files ?? false,
                        can_realtime: data.can_realtime ?? false,
                        trial_mode: data.trial_mode,
                        trial_expiration_date: data.trial_expiration_date
                    }
                })
            }
        })
    }

    handleGetTranscriptionsForUser = () => {
        API.getTranscriptionsForUser(this.state.id)
            .then((data: UserTranscriptionsList) => {
                if (data !== undefined) {
                    this.setState({
                        transcriptionJobs: data.batch_jobs ?? [],
                        projects: data.projects
                    })
                }
            });
    }

    handleUpdateUser = (formData: UserFormDataProps) => {

        let saltRounds = 10;
        let salt = bcrypt.genSaltSync(saltRounds);

        let hashpassword = null
        if (formData.password) {
            hashpassword = formData.password.length > 0 ? bcrypt.hashSync(formData.password, salt) : null;
        }
        let user = {
            id: this.state.id,
            username: formData.username,
            password_hash: hashpassword,
            email: formData.email,
            admin: formData.admin,
            editor: formData.editor,
            enabled: formData.enabled,
            verified: formData.verified,
            can_process_files: formData.can_process_files,
            can_realtime: formData.can_realtime,
            trial_mode: formData.trial_mode,
            trial_expiration_date: formData.trial_expiration_date
        };

        API.updateUserCredentials(user)
            .then((data: User) => {
                alert(Strings.strings.userCreatedSuccessfully)
                //refresh users
                this.setState({
                    userFormData: {
                        username: data.username,
                        email: data.email,
                        enabled: data.enabled,
                        editor: data.editor,
                        admin: data.admin,
                        verified: data.verified ?? false,
                        can_process_files: data.can_process_files ?? false,
                        can_realtime: data.can_realtime ?? false,
                        trial_mode: data.trial_mode ?? false,
                        trial_expiration_date: data.trial_expiration_date
                    }
                })
            });
    };

    handleRemoveUserFromTX = (tx_id: number,) => {
        //@ts-ignore
        if (this.state.id) {
            API.removeTranscriptionFromUser(this.state.id, tx_id).then((data: BatchTranscriptionJob[]) => {
                this.setState({
                    transcriptionJobs: data,
                })
            });
        }
    }

    handleRemoveUserFromProject = (projectId: number,) => {
        //@ts-ignore
        if (this.state.id) {
            API.removeProjectFromUser(this.state.id, projectId).then((data: Project[]) => {
                this.setState({
                    projects: data,
                })
            });
        }
    }

    render() {

        const dataSourceTranscriptions: any = this.state.transcriptionJobs.map((transcriptionJob) => {
            return ({
                key: transcriptionJob.id,
                id: transcriptionJob.transcription_id,
                batchid: transcriptionJob.id,
                include: transcriptionJob.transcription_id,
                filename: transcriptionJob
            })
        })

        const dataSourceProjects: any = this.state.projects.map((project) => {
            return ({
                key: project.id,
                id: project.id,
                name: project.name,
                include: project.id,
            })
        })

        const columnsTranscriptions: any = [
            {
                title: Strings.strings.id,
                dataIndex: "id",
                key: "id",
                sorter: (a: any, b: any) => a.id.localeCompare(b.id),
            },
            {
                title: Strings.strings.file,
                dataIndex: "filename",
                key: "filename",
                sorter: (a: any, b: any) => a.filename.localeCompare(b.filename),
                render: (record: BatchTranscriptionJob) => {
                    return (
                        <div>
                            <div style={{ fontSize: "0.8em" }}>{record.original_file_name}</div>
                            <div style={{ fontSize: "0.8em" }}>Título: {record.batchjob_transcription.title}</div>
                            <div style={{ fontSize: "0.8em" }}>Descripción: {record.batchjob_transcription.description}</div>
                        </div>
                    )
                }
            },
            {
                title: Strings.strings.includeUser,
                dataIndex: "include",
                key: "include",
                render: (transcriptionJobId: number) => (
                    <Button
                        type="primary"
                        danger={true}
                        onClick={() => {
                            this.handleRemoveUserFromTX(transcriptionJobId)
                        }}
                    >
                        {Strings.strings.remove}
                    </Button>
                )
            },
        ];

        const columnsProjects: any = [
            {
                title: Strings.strings.id,
                dataIndex: "id",
                key: "id",
                sorter: (a: any, b: any) => a.localeCompare(b),
            },
            {
                title: Strings.strings.name,
                dataIndex: "name",
                key: "name",
                sorter: (a: any, b: any) => a.localeCompare(b),
            },
            {
                title: Strings.strings.includeUser,
                dataIndex: "include",
                key: "include",
                render: (projectId: number) => (
                    <Button
                        type="primary"
                        danger={true}
                        onClick={() => {
                            this.handleRemoveUserFromProject(projectId)
                        }}
                    >
                        {Strings.strings.remove}
                    </Button>
                )
            },
        ];

        let form;
        if (this.state.userFormData) {
            form = (
                <UserFormComponent
                    userFormData={this.state.userFormData}
                    title={Strings.strings.updateUser}
                    handleSubmitForm={this.handleUpdateUser}
                    requiresPassword={false}
                />
            )
        }

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    paddingRight: "12px"
                }}
            >
                <div className="contentWrapper" style={{ marginTop: "2px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            overflow: "hidden",
                            paddingRight: "12px"
                        }}
                    >
                        <div style={{ paddingTop: "6px", paddingLeft: "6px" }}>
                            <h3>Edit User</h3>
                        </div>
                        <div style={{ flexGrow: 1 }}></div>
                    </div>

                    <div>
                        {form}
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={Strings.strings.transcriptions} key="1">
                                <Table
                                    pagination={{ position: ['bottomLeft'], pageSize: 100 }}
                                    dataSource={dataSourceTranscriptions}
                                    columns={columnsTranscriptions}
                                    size="small"
                                />
                            </TabPane>
                            <TabPane tab={Strings.strings.projects} key="2">
                                <Table
                                    pagination={{ position: ['bottomLeft'], pageSize: 100 }}
                                    dataSource={dataSourceProjects}
                                    columns={columnsProjects}
                                    size="small"
                                />
                            </TabPane>
                        </Tabs>

                    </div>
                </div>
            </div>
        );
    }
}

import React, { useState, useEffect, useRef } from "react";

import API from "../api/API";

import { Redirect } from "react-router-dom";

import { useAppSelector, useAppDispatch } from '../app/hooks'
import { PUDU_PRIMARY_COLOUR } from "../config/css";
import {
    UserTranscriptionsList,
    ProjectStatusEntryOut,
    ProjectStatusEntry,
    PendingFile,
    Project,
    TranscriptionMetadataEntry,
    TranscriptionPresignedUploadUrl,
    CustomDictionary
} from "../types";
import { Tabs } from "antd";
import { UserProfile } from "../types";
import UserTranscriptionJobsAdmin from "./UserTranscriptionJobsAdmin";
import UserTranscriptionJobsClient from "./UserTranscriptionJobsClient";
import UserTranscriptionsProjects from "./UserTranscriptionsProjects";
import CreateProjectComponent from "../components/CreateProjectComponent";
import PendingUploadsTable from "./PendingUploadsTable"
import UploadComponent from "./UploadComponent"
import "./UserDashboard.css";

import { store } from '../app/store'

import {
    updateUserTranscriptionsList,
    updateProjects,
    updateBatchJobs
} from "../features/UserTranscriptionsListSlice"

const { TabPane } = Tabs;

type TestUploadLink = {
    url: string
}

type UserDashboardProps = {
    userProfile: UserProfile;
    token: string;
    handleCheckStatusForBatchJobs: (ids: number[]) => void;
};

const UserDashboardComponent: React.FC<UserDashboardProps> = props => {

    let now = new Date();
    now.setDate(now.getDate() - 1)

    const userTranscriptionsList = useAppSelector((state) => state.userTranscriptionsList)
    const userProfile = useAppSelector((state) => state.userProfile)
    const dispatch = useAppDispatch()

    const [pendingUploadItems, setPendingUploadItems] = useState<PendingFile[]>([])
    const [redirect, setRedirect] = useState<string | null>(null);

    const pendingUploadItemsSet = useRef<Set<PendingFile>>(new Set<PendingFile>())
    const pendingItemUploadRequestsInProgress = useRef<{ [key: string]: XMLHttpRequest }>({})
    const [checkingSpacesAvailable, setCheckingSpacesAvailable] = useState<boolean>(true)
    const [spacesAvailable, setSpacesAvailable] = useState<boolean>(true)

    const [dictionaries, setDictionaries] = useState<CustomDictionary[]>([])

    const initialTestUploadFired = useRef<boolean>(false)

    useEffect(() => {
        fetchCustomDictionaries();
        handleGetBatchTranscriptionJobs();
        checkSpacesAvailable();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkStatusForBatchJobs();
        }, 10000);
    }, [userTranscriptionsList]);

    const checkSpacesAvailable = () => {
        setCheckingSpacesAvailable(true)
        if (initialTestUploadFired.current === false) {
            initialTestUploadFired.current = true
            API.getTestUploadLink()
                .then((link: TestUploadLink) => {

                    var xhttp = new XMLHttpRequest();

                    xhttp.onerror = function (e) {
                        setSpacesAvailable(false);
                        setCheckingSpacesAvailable(false)
                        API.reportIssue(`user potentially without upload access.`)
                        console.log("Error: cloud storage currently unavailable");
                    };

                    xhttp.onreadystatechange = () => {
                        if (xhttp.readyState === 4 && xhttp.status === 200) {
                            setCheckingSpacesAvailable(false)
                        }
                    }

                    xhttp.open("PUT", link.url, true);
                    xhttp.setRequestHeader("Content-Type", "application/json");
                    try {
                        xhttp.send(JSON.stringify({ "test": "test" }));
                    } catch (e) {
                        console.log("cloud storage currently unavailable");
                    }

                })
        } else {
            console.log("initialTestUpload already Fired")
        }
    }

    const fetchCustomDictionaries = () => {
        API.getCustomDictionaries()
            .then(data => {
                setDictionaries(data)
            })
    }

    const handleAddPendingUploadItem = (item: PendingFile) => {
        if (userProfile.userProfile?.clients?.length === 1) {
            if (userProfile.userProfile?.clients[0]) {
                item.client_id = userProfile.userProfile?.clients[0].id
                if (userProfile.userProfile?.groups?.length) {
                    if (userProfile.userProfile?.groups?.length > 0) {
                        item.group_id = userProfile.userProfile?.groups[0].id
                    }
                }
            }
        }
        pendingUploadItemsSet.current.add(item)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    }

    const handleRemovePendingItem = (uuid: string) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        pendingItems.splice(thisItemIndex, 1)
        pendingUploadItemsSet.current = new Set(pendingItems)

        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)

        if (pendingItemUploadRequestsInProgress.current[uuid] !== undefined) {
            pendingItemUploadRequestsInProgress.current[uuid].abort()
            delete pendingItemUploadRequestsInProgress.current[uuid]
        }
    }

    const handleTranscriptionClick = (id: number) => {
        let url = "/transcription/" + id;
        setRedirect(url)
    }

    const handleTranscriptionUsersClick = (id: string) => {
        let url = `/users/transcription/${id}`;
        setRedirect(url)
    }

    const handleProjectUsersClick = (id: number) => {
        let url = `/project/${id}/users`;
        setRedirect(url)
    }

    const handleDeleteBatchTranscriptionJob = (id: number) => {
        API.deleteTranscription(id)
            .then(txId => {
                let updatedTranscriptionBatchJobs = userTranscriptionsList.batch_jobs.filter(batchJob => batchJob.batchjob_transcription.id != txId);
                dispatch(updateBatchJobs(updatedTranscriptionBatchJobs));
                let updatedProjects = userTranscriptionsList.projects.map(project => {
                    let projectFiltered = { ...project };
                    projectFiltered.transcriptions = projectFiltered.transcriptions.filter(transcriptionId => transcriptionId != txId);
                    return projectFiltered
                })
                dispatch(updateProjects(updatedProjects));
            })
    }

    const checkStatusForBatchJobs = () => {
        let batchJobIdsNotDone = userTranscriptionsList.batch_jobs
            .filter(item => {
                let stringAsDate = new Date(item.time_created)
                return stringAsDate >= now
            })
            .filter(item => item.status !== "done")
            .map(e => e.id)
        props.handleCheckStatusForBatchJobs(batchJobIdsNotDone)
    }

    const handleGetBatchTranscriptionJobs = () => {
        API.getBatchTranscriptionJobs().then((data: UserTranscriptionsList) => {
            dispatch(updateUserTranscriptionsList(data))
        });
    };

    const handleDownloadAllDocx = (projectId: number, filename: string) => {
        API.getProjectDocx(projectId, filename)
    };

    const onCreateProject = (project: Project) => {
        let previousProjects = userTranscriptionsList?.projects ?? []
        previousProjects.push(project)
        dispatch(updateProjects(previousProjects))
    }

    const handleUpdateProjectStatus = (projectStatusTypeId: number, note: string, transcriptionId: number) => {
        let projectStatusEntryOut: ProjectStatusEntryOut = {
            transcription_id: transcriptionId,
            project_status_id: projectStatusTypeId,
            note: note
        }
        API.addProjectStatusEntry(projectStatusEntryOut, transcriptionId).then((response: ProjectStatusEntry) => {
            let batchJobs = userTranscriptionsList?.batch_jobs
            if (batchJobs !== undefined && batchJobs !== null) {
                let foundIndex = batchJobs?.findIndex(e => e.transcription_id === response.transcription_id)
                if (foundIndex >= 0) {
                    batchJobs[foundIndex].project_status = response
                    dispatch(updateBatchJobs(batchJobs))
                }
            }
        })
    };

    const handleChangeTranscriptionProject = (transcriptionId: number, projectId: number) => {
        API.updateProjectForTranscription(transcriptionId, projectId).then(data => {
            dispatch(updateProjects(data))
        })
    }

    const fileCanBeProcessed = (pendingFile: PendingFile): boolean => {
        let titleAndDescriptionOk = (pendingFile.title.length > 0 && pendingFile.description.length > 0)
        return titleAndDescriptionOk && !pendingFile.uploadPressed && (pendingFile.client_id !== undefined)
    }

    const handlePendingFileTitleUpdate = (uuid: string, title: string) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        pendingItems[thisItemIndex].title = title
        pendingItems[thisItemIndex].enabledForProcessing = fileCanBeProcessed(pendingItems[thisItemIndex])
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    }

    const handlePendingFileDescriptionUpdate = (uuid: string, description: string) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        pendingItems[thisItemIndex].description = description
        pendingItems[thisItemIndex].enabledForProcessing = fileCanBeProcessed(pendingItems[thisItemIndex])
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    }

    const handleChangeLanguageForItem = (uuid: string, language: string) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        pendingItems[thisItemIndex].language = language
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    };

    const handleChangeClientForItem = (uuid: string, clientId: number) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        pendingItems[thisItemIndex].client_id = clientId
        pendingItems[thisItemIndex].group_id = undefined
        pendingItems[thisItemIndex].enabledForProcessing = fileCanBeProcessed(pendingItems[thisItemIndex])
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    };

    const handleChangeGroupForItem = (uuid: string, groupId?: number) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        pendingItems[thisItemIndex].group_id = groupId
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    };

    const handleMetadataForItem = (uuid: string, metadata: TranscriptionMetadataEntry[]) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === uuid)
        if (thisItemIndex >= 0) {
            pendingItems[thisItemIndex].metadata = metadata.map(a => {
                return {
                    "key": a.key,
                    "text": a.text ?? "",
                    "order": a.order
                }
            });
        }
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    }

    const handleUpdatePendingFile = (handlePendingFile: PendingFile) => {
        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let thisItemIndex = pendingItems.findIndex(item => item.uuid === handlePendingFile.uuid)
        pendingItems[thisItemIndex] = handlePendingFile
        pendingUploadItemsSet.current = new Set(pendingItems)
        let items = Array.from(pendingUploadItemsSet.current.values())
        setPendingUploadItems(items)
    }

    const processItem = (uuid: string, withAsr: boolean) => {

        let pendingItems = Array.from(pendingUploadItemsSet.current.values())
        let pendingItem = pendingItems.find(item => item.uuid === uuid)

        if (pendingItem !== undefined) {

            // Update pending file was submitted
            let item = { ...pendingItem };
            item.uploadPressed = true
            item.enabledForProcessing = false
            handleUpdatePendingFile(item)


            let formData = new FormData();
            let project_id = ""

            if (item.project_id !== null) {
                project_id = `${item.project_id}`
            }

            let metadata = item.metadata.map(item => {
                return {
                    "key": item.key,
                    "order": item.order,
                    "text": item.text
                }
            })

            // formData.append("file", item.file);
            formData.append("filename", item.file.name);
            formData.append("name", item.file.name);
            formData.append("content_type", item.file.type);
            formData.append("tmpid", item.uuid);
            formData.append("language", item.language);
            formData.append("title", item.title ?? "");
            formData.append("description", item.description ?? "");
            formData.append("project_id", project_id);
            formData.append("with_asr", withAsr === true ? "True" : "False");
            formData.append("metadata", JSON.stringify(metadata));
            formData.append("client_id", `${item.client_id}`);
            formData.append("group_id", `${item.group_id}`);

            let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/batch/upload`;
            var request = new XMLHttpRequest();
            request.open("POST", endpoint);
            request.setRequestHeader("Accept", "application/json");
            request.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
            request.setRequestHeader("Access-Control-Allow-Origin", "*");
            request.setRequestHeader("Access-Control-Allow-Headers", "*");
            request.onabort = () => {
                console.log("upload aborted")
            }

            request.onerror = () => {
                //tell backend there was an issue
                API.reportIssue(`issue whilst trying to create new tx - ${endpoint}`)
            }

            request.onreadystatechange = () => {
                if (request.readyState === 4 && request.status === 200) {
                    let json = JSON.parse(request.responseText);

                    let transcription_id = json["txid"]

                    var uploadAudioRequest = new XMLHttpRequest();
                    uploadAudioRequest.open("PUT", json["url"]);
                    uploadAudioRequest.setRequestHeader("Content-Type", "audio/mpeg");
                    uploadAudioRequest.upload.onprogress = (e) => {
                        // Update pending file upload progress
                        var percentComplete = Math.ceil((e.loaded / e.total) * 100);
                        item.uploadProgress = percentComplete
                        handleUpdatePendingFile(item)
                    };
                    uploadAudioRequest.onabort = () => {
                        console.log(item.uuid, "upload aborted")
                    }

                    uploadAudioRequest.onerror = (e: ProgressEvent) => {
                        // tell backend there was an issue
                        console.log("XMLHttpRequest error:", e);
                        API.reportIssue(`Issue whilst trying to upload.\n
Ready State: ${uploadAudioRequest.readyState}.
Status: ${uploadAudioRequest.status}.
StatusText: ${uploadAudioRequest.statusText}.
Endpoint: ${json["url"]}.
Error: ${e}`
                        )
                        alert("error subiendo archivo");
                    }

                    uploadAudioRequest.onreadystatechange = () => {

                        if (uploadAudioRequest.readyState === 4 && uploadAudioRequest.status === 200) {

                            let endpoint = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/transcription/${transcription_id}/process`;

                            let formData = new FormData();
                            // formData.append("file", item.file);
                            formData.append("filename", item.file.name);
                            // formData.append("tmpid", item.uuid);
                            // formData.append("language", item.language);
                            formData.append("with_asr", withAsr === true ? "True" : "False");
                            // formData.append("title", item.title ?? "");
                            // formData.append("description", item.description ?? "");

                            fetch(endpoint, {
                                method: 'POST',
                                body: formData,
                                headers: {
                                    Accept: "application/json",
                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Headers": "*"
                                },
                            }).then(response => {
                                if (response.status >= 200 && response.status < 300) {
                                    item.txid = transcription_id
                                    item.uploadComplete = true
                                    handleUpdatePendingFile(item)
                                    handleGetBatchTranscriptionJobs()
                                    // handleRemovePendingItem(item.uuid)
                                } else {
                                    item.uploadPressed = false
                                    handleUpdatePendingFile(item)
                                }
                            })
                        }
                    }
                    pendingItemUploadRequestsInProgress.current[uuid] = uploadAudioRequest
                    try {
                        uploadAudioRequest.send(item.file);
                    } catch (e) {
                        console.log("error:", e)
                    }
                } else if (request.status !== 200) {
                    API.reportIssue(`Issue whilst trying to process. Status: ${request.status}. StatusText: ${request.statusText}. Endpoint: ${endpoint}`)
                }
            };

            request.send(formData);
        } else {
            console.log("item not found in pending items")
        }
    };

    if (!props.userProfile) {
        return <div style={{ "padding": "20px" }}>Cargando transcripciones...</div>;
    }

    let isAdmin = props.userProfile.admin;
    let canProcessFiles = props.userProfile.can_process_files || isAdmin;

    if (redirect !== null) {
        return <Redirect to={redirect} />
    }

    let pendingUploadsTable = (
        <PendingUploadsTable
            processItem={processItem}
            isAdmin={isAdmin}
            uploadEnabled={spacesAvailable}
            checkingConnection={checkingSpacesAvailable}
            items={pendingUploadItems}
            customDictionaries={dictionaries}
            groups={userProfile.userProfile?.groups ?? []}
            clients={userProfile.userProfile?.clients ?? []}
            handleRemovePendingItem={handleRemovePendingItem}
            handlePendingFileTitleUpdate={handlePendingFileTitleUpdate}
            handlePendingFileDescriptionUpdate={handlePendingFileDescriptionUpdate}
            handleChangeLanguageForItem={handleChangeLanguageForItem}
            handleMetadataForItem={handleMetadataForItem}
            handleChangeGroupForItem={handleChangeGroupForItem}
            handleChangeClientForItem={handleChangeClientForItem}
        />
    )

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                paddingRight: "12px"
            }}
        >
            <div className="contentWrapper" style={{ marginTop: "2px" }}>

                {canProcessFiles && pendingUploadsTable}

                <Tabs
                    className="userDashboardTabs"
                    defaultActiveKey="2"
                >
                    <TabPane tab="Proyectos" key="1" className="userDashboardTabs"
                    >
                        {isAdmin &&
                            <CreateProjectComponent onCreateProject={onCreateProject} />
                        }
                        <UserTranscriptionsProjects
                            isAdmin={isAdmin}
                            canProcessFiles={canProcessFiles}
                            userTranscriptionsList={userTranscriptionsList ?? {
                                batch_jobs: [],
                                project_status_types: [],
                                projects: []
                            }}
                            handleDeleteBatchTranscriptionJob={handleDeleteBatchTranscriptionJob}
                            handlGetBatchTranscriptionJobs={handleGetBatchTranscriptionJobs}
                            handleTranscriptionClick={handleTranscriptionClick}
                            handleTranscriptionUsersClick={handleTranscriptionUsersClick}
                            handleProjectUsersClick={handleProjectUsersClick}
                            handleUpdateProjectStatus={handleUpdateProjectStatus}
                            handleChangeTranscriptionProject={handleChangeTranscriptionProject}
                            handleProcessItem={handleAddPendingUploadItem}
                            handleDownloadAllProjectDocx={handleDownloadAllDocx}
                        />
                    </TabPane>
                    <TabPane tab="Transcripciones" key="2" className="userDashboardTabs"
                    >

                        {canProcessFiles &&
                            <UploadComponent
                                handleProcessItem={handleAddPendingUploadItem}
                                project_id={null}
                            />
                        }

                        {canProcessFiles &&
                            <UserTranscriptionJobsAdmin
                                isAdmin={isAdmin}
                                handleTranscriptionClick={handleTranscriptionClick}
                                handleTranscriptionUsersClick={handleTranscriptionUsersClick}
                                handleDeleteBatchTranscriptionJob={handleDeleteBatchTranscriptionJob}
                                projectStatusTypes={userTranscriptionsList?.project_status_types ?? []}
                                userTranscriptionsList={userTranscriptionsList}
                                handleUpdateProjectStatus={handleUpdateProjectStatus}
                                handleChangeTranscriptionProject={handleChangeTranscriptionProject}
                            />
                        }
                        {!canProcessFiles &&
                            <UserTranscriptionJobsClient
                                batchJobs={userTranscriptionsList?.batch_jobs ?? []}
                                handlGetBatchTranscriptionJobs={handleGetBatchTranscriptionJobs}
                                handleTranscriptionClick={handleTranscriptionClick}
                                handleTranscriptionUsersClick={handleTranscriptionUsersClick}
                            />
                        }
                    </TabPane>
                </Tabs>

            </div>
        </div>
    );
}

export default UserDashboardComponent;
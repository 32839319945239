function showTwoDigits(number: number) {
    return ("00" + number).slice(-2);
}

export default {
    displayTime: (currentFrame: number) => {
        var fps = 30;
        var h = Math.floor(currentFrame / (60 * 60 * fps));
        var m = (Math.floor(currentFrame / (60 * fps))) % 60;
        var s = (Math.floor(currentFrame / fps)) % 60;
        var f = currentFrame % fps;
        return showTwoDigits(h) + ":" + showTwoDigits(m) + ":" + showTwoDigits(s) + ":" + showTwoDigits(f);
    },


    fancyTimeFormat: (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;
        let msecs = (time % 1).toFixed(2).split(".")[1];

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        // if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        // }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        ret += ":" + msecs;
        return ret;
    }
    ,
    fancyTimeFormatHMS: (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    },
    hoursMinutesFormat: (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);

        return `${hrs} h ${mins} mins`;
    },

    getDateWithoutTimezone: (data: Date) => {
        let result: Date = new Date(data);
        let stringDate = result.toString();

        // Check if timezone offest is present
        if (stringDate.indexOf("GMT+") > 0) {
            // Timezone offest is present, we need to change the date object
            result.setHours(result.getHours() - result.getTimezoneOffset() / 60);
        }

        return result;

    }
}
import React, { createRef } from "react";

import {
    Edition,
    Word,
    Speaker,
} from "./../types/index";

import { AutoScrollState, EditorInteractionMode } from "../types/enums";

import "./TextEditor.css";

import TextEditorBody from "./TextEditorBody";
import FindAndReplaceComponent from "./FindAndReplaceComponent"

// interface SortedTextEditorItem {
//     textEditorItem: JSX.Element[];
//     order: number;
// }
// interface SortedTextEditorItemHashMap {
//     [editionid: number]: SortedTextEditorItem;
// }

type TextEditorComponentProps = {
    transcriptionId: number;
    editions: Edition[];
    speakers: Speaker[];
    activeWord: Edition | null;
    shouldScrollActiveWordIntoView: boolean;
    editingEnabled: boolean;
    autoScrollState: AutoScrollState;
    editorToolsEnabled: boolean;
    shouldJumpToActiveWord: boolean;
    editionIdsSaving: number[]
    showFindAndReplace: boolean;
    itemsSelectedForFindAndReplace: number[],
    handleAddOrCreateEdition: (edition: Edition) => void;
    handleActivateWord: (word: Edition, jump: boolean) => void;
    handleGoToTime: (time: number, activateWord: boolean) => void;
    handleSaveEdition: (edtion: Edition) => void;
    handleDeleteEdition: (edition: Edition) => void;
    handleSplitEdition: (splitIndex: number, edition: Edition) => void;
    handleMergeEdition: (edition: Edition) => void;
    handleInsertNewEdition: (edition: Edition) => void;
    handleOnFocusTextEditorItem: (word: Edition) => void
    handleShowSpeakers: (edition: Edition) => void;
    handleAddEditionsForFindAndReplace: (editionIds: number[]) => void;
    handleRemoveEditionsForFindAndReplace: (editionIds: number[]) => void;
    handleFindAndReplace: (searchTerm: string, replaceTerm: string) => void;
    handleSelectAll: () => void
    handleDeselectAll: () => void
    handlUpdateCurrentSearchTerm: (searchTerm: string | undefined) => void
};

type TextEditorComponentState = {
    interactionMode: EditorInteractionMode;
    editions: Edition[]
};

type TextSelection = {
    wordIn?: number; //first word id
    wordOut?: number; //last word id
};

export class TextEditor extends React.Component<
    TextEditorComponentProps,
    TextEditorComponentState
> {
    currentTextSelection: TextSelection = {};
    currentUserCorrection?: Word;
    currentIndex: Number = 0;
    editorRef = createRef<HTMLDivElement>();
    activeWordRef = createRef<HTMLDivElement>();
    editorScrollRef = createRef<HTMLDivElement>();
    cursor = (
        <div key="cursor" className={"cursor"}>
            |
        </div>
    );

    constructor(props: TextEditorComponentProps) {
        super(props);

        this.state = {
            interactionMode: EditorInteractionMode.readyToReceiveWS,
            editions: []
        };

        this.editorScrollRef.current?.scrollTo({ top: this.editorScrollRef.current?.scrollHeight })
    }

    componentDidUpdate = (previousProps: TextEditorComponentProps) => {
        if (this.props.shouldJumpToActiveWord) {
            if (this.props.activeWord) {
                if (previousProps.activeWord !== this.props.activeWord) {
                    if (this.props.autoScrollState === AutoScrollState.scrollToActive) {
                        let scrollAmount = (this.activeWordRef.current?.offsetTop ?? 0) - (this.editorScrollRef.current?.offsetTop ?? 0) - 50;
                        // console.log("will scroll...", this.props.activeWord.text, this.activeWordRef.current?.offsetTop)
                        this.editorScrollRef.current?.scrollTo({
                            top: scrollAmount,
                            behavior: "smooth"
                        })
                    } else {
                        console.log("will scroll B...", this.props.activeWord.text)
                        if (this.props.autoScrollState === AutoScrollState.scrollToNew) {
                            // For realtime
                            this.editorScrollRef.current?.scrollTo({
                                top: this.editorScrollRef.current?.scrollHeight,
                                behavior: "smooth"
                            })
                        }
                    }
                }
            }
        }
    }

    onBlur = () => {
        this.setState({
            interactionMode: EditorInteractionMode.readyToReceiveWS
        });
    };

    onMouseUp = (word: Edition) => {
        // console.log("onMouseUp ", word.id);
        this.props.handleGoToTime(word.time, true)
    };

    goToWord = (word: Edition) => {
        // console.log("goToWord ", word.id);
        this.props.handleActivateWord(word, true);
    };

    render() {

        let findAndReplace;

        if (this.props.showFindAndReplace) {
            findAndReplace = (
                <FindAndReplaceComponent
                    itemsForFindAndReplace={this.props.itemsSelectedForFindAndReplace}
                    handleFindAndReplace={this.props.handleFindAndReplace}
                    handleSelectAll={this.props.handleSelectAll}
                    handleDeselectAll={this.props.handleDeselectAll}
                    handlUpdateCurrentSearchTerm={this.props.handlUpdateCurrentSearchTerm}
                />
            )
        }

        return (
            <div style={{
                backgroundColor: "rgb(230, 230, 230)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                {findAndReplace}
                <TextEditorBody
                    editions={this.props.editions}
                    editorRef={this.editorRef}
                    onBlur={this.onBlur}
                    editorScrollRef={this.editorScrollRef}
                    editorToolsEnabled={this.props.editorToolsEnabled}

                    activeWordRef={this.activeWordRef}
                    activeWord={this.props.activeWord}
                    speakers={this.props.speakers}
                    editionIdsSaving={this.props.editionIdsSaving}

                    showFindAndReplace={this.props.showFindAndReplace}
                    itemsForFindAndReplace={this.props.itemsSelectedForFindAndReplace}

                    transcriptionId={this.props.transcriptionId}
                    editingEnabled={this.props.editingEnabled}
                    handleAddOrCreateEdition={this.props.handleAddOrCreateEdition}
                    handleSaveWordEdition={this.props.handleSaveEdition}
                    handleOnFocusTextEditorItem={this.props.handleOnFocusTextEditorItem}
                    handleSplitEdition={this.props.handleSplitEdition}
                    onMouseUp={this.onMouseUp}
                    handleInsertNewEdition={this.props.handleInsertNewEdition}
                    handleDeleteEdition={this.props.handleDeleteEdition}
                    handleMergeEdition={this.props.handleMergeEdition}
                    handleGoToWord={this.goToWord}
                    handleShowSpeakers={this.props.handleShowSpeakers}
                    handleAddEditionsForFindAndReplace={this.props.handleAddEditionsForFindAndReplace}
                    handleRemoveEditionsForFindAndReplace={this.props.handleRemoveEditionsForFindAndReplace}
                />
            </div>
            // </div>
        );
    }
}

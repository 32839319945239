import React, { createRef } from "react";

type TranscriptionWebsocketProps = {
  transcriptionId: number;
  handleReceivedWebsocketMessage: (somestuff: string) => void;
};

type TranscriptionWebsocketState = {};

export class TranscriptionWebsocket extends React.Component<
  TranscriptionWebsocketProps,
  TranscriptionWebsocketState
> {
  ws: WebSocket;
  websocketRef = createRef<WebSocket>();

  timeout: number = 250;
  constructor(props: TranscriptionWebsocketProps) {
    super(props);
    this.state = {};

    const wsEndpoint = `${process.env.REACT_APP_WS_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/ws/transcription/${this.props.transcriptionId}`;
    this.ws = new WebSocket(wsEndpoint);
  }

  componentDidMount() {
    this.connect();
  }

  connect = () => {
    let that = this; // cache the this
    var connectInterval: NodeJS.Timeout;

    this.ws.onopen = () => {
      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    this.ws.onmessage = evt => {
      this.props.handleReceivedWebsocketMessage(evt.data);
    };

    this.ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.checkWebsocketConnection, Math.min(10000, that.timeout)); //call check function after timeout
    };

    this.ws.onerror = err => {
      console.error("Socket encountered error: ", err.type, "Closing socket");

      this.ws.close();
    };
  };

  checkWebsocketConnection = () => {
    console.log("WS CHECK");
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) this.connect();
  };

  render() {
    return "";
  }
}

import React, { createRef, useState } from "react";

import API from "../api/API";

import "./TextEditor.css";

import {
    Edition,
    UserLogEntryRequest
} from "../types/index";

import {
    UserAction
} from "../types/enums";

import { EditorItemType } from "../types/enums";

type TextEditorItemProps = {
    edition: Edition;
    transcriptionId: number;
    itemType: EditorItemType;
    className: string;
    isActiveWord: boolean,
    editingEnabled: boolean;
    isSaving: boolean;
    handleAddOrCreateEdition: (edition: Edition) => void;
    onMouseUp: (edition: Edition) => void;
    handleSaveWordEdition: (edition: Edition) => void;
    onFocus: (edition: Edition) => void;
    handleSplitEdition: (splitIndex: number, edition: Edition) => void
};


const TextEditorItem: React.FC<TextEditorItemProps> = props => {

    const [unsaved, setUnsaved] = useState<boolean>(false);
    // const [text, setText] = useState<string>(props.edition.text ?? "");
    const [isSaving, setIsSaving] = useState<boolean>(props.isSaving);

    const editionDivRef = createRef<HTMLDivElement>();

    function handleOnMouseUp() {
        console.log("this.props.isActiveWord", props.isActiveWord)
        if (props.edition && !props.isActiveWord) {
            console.log("this.props.isActiveWor 2d")
            props.onMouseUp(props.edition);
        }
    };

    function save() {
        if (editionDivRef.current && editionDivRef.current.textContent) {
            console.log("Save")
            let edition = Object.assign({}, props.edition, { text: editionDivRef.current.textContent })
            props.handleSaveWordEdition(edition)
            // setText(editionDivRef.current.textContent)
            // editionDivRef.current.blur();
        }

    }

    function handleOnFocus() {
        console.log("handleOnFocus this.props.isActiveWord", props.isActiveWord)
        props.onFocus(props.edition)

        let request: UserLogEntryRequest = {
            user_action: UserAction.tx_edition_started,
            description: `editionId: ${props.edition.id}`,
            transcription_id: props.transcriptionId
        }
        API.logUserAction(request)

    }

    function handleOnBlur() {
        setIsSaving(props.edition.text !== editionDivRef.current?.textContent)
        setUnsaved(false)
        save()

        let request: UserLogEntryRequest = {
            user_action: UserAction.tx_edition_ended,
            description: `editionId: ${props.edition.id}`,
            transcription_id: props.transcriptionId
        }
        API.logUserAction(request)
    }

    function handleSplit(e: React.KeyboardEvent<HTMLDivElement>) {
        let splitIndex = document.getSelection()?.anchorOffset;
        let edition = Object.assign({}, props.edition, { text: editionDivRef.current?.textContent })
        if (splitIndex) {
            console.log("split", document.getSelection()?.anchorOffset)
            setIsSaving(true)
            props.handleSplitEdition(splitIndex, edition)
        }
    }

    return (
        <div
            id={`${props.edition?.id}`}
            key={`cont_${props.edition?.id}`}
            style={{ display: "inline" }}
        >
            <div style={{ paddingBottom: "0" }}>
                <div
                    key={`space_${props.edition.id}`}
                    onMouseUp={() => props.onMouseUp(props.edition)}
                >
                </div>

                <div
                    ref={editionDivRef}
                    className={props.className}
                    onKeyDown={(evt) => {
                        if (evt.key === "Escape") {
                            setUnsaved(false)
                            if (editionDivRef.current) {
                                editionDivRef.current.innerHTML = props.edition.text ?? ""
                            }
                            editionDivRef.current?.blur();
                        }
                    }}
                    onKeyUp={(evt) => {
                        setUnsaved(editionDivRef.current?.innerText !== props.edition.text ?? "")
                    }}
                    onKeyPress={(evt) => {
                        if (evt.shiftKey && evt.key === "Enter") {
                            evt.preventDefault();
                            handleSplit(evt)
                        } else if (evt.key === "Enter") {
                            evt.preventDefault();
                            editionDivRef.current?.blur();
                        }
                    }}
                    contentEditable={props.editingEnabled}
                    suppressContentEditableWarning={true}
                    data-placeholder=""
                    onMouseUp={handleOnMouseUp}
                    onFocus={handleOnFocus}
                    onBlur={() => {
                        handleOnBlur()
                    }}
                >
                    {props.itemType !== EditorItemType.space
                        ? (props.edition.text)
                        : " "}
                </div>
                <div style={{ paddingRight: "10px", minWidth: "10px", maxWidth: "10px", display: "inline" }}></div>
                <div className={unsaved === true ? "unsaved" : "saved"}>
                    Presiona Enter para guardar
                </div>
                <div className={isSaving === true ? "saving" : "saved"} >
                    Guardando...
                </div>
            </div>
        </div >
    );

}


function shouldUpdate(previousProps: TextEditorItemProps, nextProps: TextEditorItemProps) {
    return previousProps.edition === nextProps.edition &&
        previousProps.transcriptionId === nextProps.transcriptionId &&
        previousProps.itemType === nextProps.itemType &&
        previousProps.className === nextProps.className &&
        previousProps.isActiveWord === nextProps.isActiveWord &&
        previousProps.editingEnabled === nextProps.editingEnabled &&
        previousProps.isSaving === nextProps.isSaving
}

export default React.memo(TextEditorItem, shouldUpdate)
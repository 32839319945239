import React, { createRef } from "react";

import { Button, Slider } from "antd";

import { Edition } from "../types/index";

// import CustomPointMarker from './CustomPointMarker';
// import SimplePointMarker from './SimplePointMarker';
import CustomSegmentMarker from './CustomSegmentMarker';
import SimpleSegmentMarker from './SimpleSegmentMarker';
import TimeHelper from "../helpers/TimeHelper";
import { PUDU_PRIMARY_COLOUR, PUDU_SECONDARY_COLOUR } from "../config/css";
import { CaretRightOutlined, PauseOutlined, DashboardOutlined, ZoomOutOutlined, ZoomInOutlined, SoundOutlined } from '@ant-design/icons';

import API from "../api/API"

import {
    UserLogEntryRequest
} from "../types/index";
import {
    UserAction
} from "../types/enums";


//@ts-ignore
import Peaks, {
    CreateSegmentMarkerOptions,
    CreateSegmentLabelOptions,
    PeaksInstance,
    SegmentDragEvent,
    WaveformViewMouseEvent,
    PeaksOptions,
    Segment
} from "peaks.js";

//@ts-ignore
import Konva from 'konva';

import {
    WaveFormData,
    PeaksSegment,
    TranscriptionAudioLink
} from "../types/index";
import { Strings } from "../strings";

function createSegmentMarker(options: CreateSegmentMarkerOptions) {
    if (options.view === 'zoomview') {
        return new CustomSegmentMarker(options);
    }
    else {
        return new SimpleSegmentMarker(options);
    }
}

function createSegmentLabel(options: CreateSegmentLabelOptions) {

    return new Konva.Text({
        text: undefined,
        fontSize: 14,
        fontFamily: 'Helvetica',
        fill: 'black'
    });
}

type PeaksComponentProps = {
    // audioFile: Blob
    audioFile: string | undefined
    peaksSegments?: PeaksSegment[];
    peakdata: WaveFormData;
    currentTime: number | null;
    userCanEdit: boolean;
    txid: number;
    handleActivateWord: (word: Edition | null, shouldJumpToActiveWord: boolean) => void;
    handleExitedSegment: (editionId: number) => void;
    handleGoToPreviousWord: (time: number) => void;
    handleGoToNextWord: (time: number) => void;
    handleOnSegmentEntered: (segmentId: string | undefined, shouldJumpToActiveWord: boolean) => void;
    handleUpdateSegment: (startTime: number, endTime: number, id: number) => void;
    handleGoToTime: (time: number, activateWord: boolean) => void;
    handleBufferingAudio: (isBuffering: boolean) => void;
};

type PeaksComponentState = {
    playing: Boolean;
    currentTime: number;
    duration: number;
    autoCenter: boolean;
    audiofile: string | undefined;
    currentZoom: number;
    samplesPerSecond: number
    totalSamples: number
    isBufferingAudio: boolean
};

export class PeaksComponent extends React.Component<
    PeaksComponentProps,
    PeaksComponentState
> {
    peaksInstance: PeaksInstance | null;

    zoomviewWaveformRef = createRef<HTMLDivElement>();
    overviewContainer = createRef<HTMLDivElement>();
    audioplayer = createRef<HTMLAudioElement>();
    zoomslider = createRef<HTMLOptionElement>();

    currentSegment: PeaksSegment | undefined

    currentPlaybackRate: number | undefined = undefined
    currentTime: number | undefined = undefined
    isCurrentlyPlaying: boolean = false
    requiresAudioReload: boolean = false

    audioWaveFormActive: boolean = false;

    lastPlayedTime: number | undefined = undefined

    segmentWasClicked: boolean = false
    audioShouldContinue: boolean = false

    constructor(props: PeaksComponentProps) {
        super(props);
        this.state = {
            playing: false,
            currentTime: 0,
            duration: 0,
            autoCenter: true,
            audiofile: this.props.audioFile,
            currentZoom: this.props.peakdata["samples_per_pixel"],
            samplesPerSecond: this.props.peakdata["samples_per_pixel"],
            totalSamples: this.props.peakdata["length"],
            isBufferingAudio: true
        };
        this.peaksInstance = null;
    }

    componentDidMount = () => {
        this.configurePeaks(this.props.peakdata, this.props.audioFile)
    }

    shouldComponentUpdate = (nextProps: PeaksComponentProps, nextState: PeaksComponentState) => {
        let newSegmentsChanged = this.props.peaksSegments?.length !== nextProps.peaksSegments?.length

        if (nextState !== this.state) {
            return true
        }
        if (!newSegmentsChanged) {
            this.props.peaksSegments?.forEach((element, index) => {
                if (nextProps.peaksSegments) {
                    if (nextProps.peaksSegments[index].startTime !== element.startTime || nextProps.peaksSegments[index].endTime !== element.endTime || nextProps.peaksSegments[index].color !== element.color) {
                        newSegmentsChanged = true;
                        return;
                    }
                }

            });
        }
        if (this.props.audioFile === nextProps.audioFile && this.props.peakdata === nextProps.peakdata && this.peaksInstance !== undefined && !newSegmentsChanged) {
            return false
        } else if (newSegmentsChanged || this.props.audioFile !== nextProps.audioFile || this.props.peakdata !== nextProps.peakdata) {
            return true
        } else {
            return true
        }
    }

    componentDidUpdate = (previousProps: PeaksComponentProps) => {

        if (this.props.audioFile !== previousProps.audioFile && this.props.peakdata !== previousProps.peakdata && this.peaksInstance !== undefined && this.props.peaksSegments !== previousProps.peaksSegments) {
            this.configurePeaks(this.props.peakdata, this.props.audioFile)
        }
        else if (this.props.peaksSegments !== previousProps.peaksSegments) {
            this.setupPeaksViews()
            this.addSegmentsToPeaks()
        }

        let player = this.audioplayer.current;
        if (player !== null && this.lastPlayedTime) {
            player.currentTime = this.lastPlayedTime;
            this.lastPlayedTime = undefined
        }
    }

    componentWillUnmount() {
        if (this.peaksInstance) {
            this.peaksInstance.destroy();
        }
    }

    private setupPeaksViews = () => {
        let view = this.peaksInstance?.views.getView("zoomview");
        view?.enableAutoScroll(true)
        view?.setAmplitudeScale(1.0);
        view?.showPlayheadTime(true);
        view?.enableMarkerEditing(this.props.userCanEdit)
    }

    handlePlayerSeeked = (time: number) => {
        // console.log("handlePlayerSeeked", time)
        this.props.handleGoToTime(time, true)
    }

    handleTimeUpdate = (time: number) => {
        if (time !== this.currentTime) {
            if (!this.requiresAudioReload) {
                this.currentPlaybackRate = this.audioplayer.current?.playbackRate;
                this.currentTime = time
                this.isCurrentlyPlaying = !this.audioplayer.current?.paused ?? false;
            }
            this.setState({ currentTime: time })
        }
        this.addSegmentsToPeaks()
    }

    handlePlayerReady = () => {
        this.setState({ isBufferingAudio: false })
        if (this.audioplayer.current && this.requiresAudioReload) {
            this.requiresAudioReload = false
            this.props.handleBufferingAudio(false)

            if (this.currentTime !== undefined) {
                // console.log("reload at current time")
                this.audioplayer.current.currentTime = this.currentTime
            }
            if (this.isCurrentlyPlaying) {
                this.audioplayer.current?.play()
            }
            if (this.currentPlaybackRate !== undefined) {
                // console.log("playbackRate", this.currentPlaybackRate)
                this.audioplayer.current.playbackRate = this.currentPlaybackRate
            }
        }

    }

    handlePlayerError = (error: MediaError) => {
        if (error.MEDIA_ERR_SRC_NOT_SUPPORTED || error.MEDIA_ERR_NETWORK || error.MEDIA_ERR_DECODE) {
            this.setState({ isBufferingAudio: true })
            this.requiresAudioReload = true
            this.props.handleBufferingAudio(true)
            API.getTranscriptionAudio(this.props.txid)
                .then((audioLink: TranscriptionAudioLink) => {
                    try {
                        if (this.audioplayer.current) {
                            this.audioplayer.current.src = audioLink.url
                        } else {
                            console.log("player issue")
                        }
                    } catch (error) {
                        console.log("audio error", error)
                    }
                })
                .catch(error => console.log(" getTranscriptionAudioerror:", error))
        }
    }

    handleZoomUpdate = (time: number) => {
        // console.log("handleZoomUpdate", time)
    }

    handleUpdateSegment = () => {
        console.log("handlueUpdateSegment", this.peaksInstance?.player.getCurrentTime())
    }

    onSegmentEntered = (segment: PeaksSegment) => {
        if (this.audioplayer.current && !this.audioplayer.current?.paused) {
            this.props.handleOnSegmentEntered(segment.id, false)
        }
    }

    onSegmentExit = (region: PeaksSegment) => {
        // console.log("onSegmentExit")
        if (!this.segmentWasClicked) {
            if (region.id) {
                this.props.handleExitedSegment(Number(region.id));
            }
        }
        this.segmentWasClicked = false
        if (this.audioShouldContinue) {
            this.audioShouldContinue = false
            this.audioplayer.current?.play()
        }
    };

    onSegmentDragged = (event: SegmentDragEvent) => {
        this.lastPlayedTime = this.audioplayer.current?.currentTime
        if (event.segment.id) {
            this.props.handleUpdateSegment(event.segment.startTime, event.segment.endTime, Number(event.segment.id))
        }
    }

    handleDblClickZoomView = (event: WaveformViewMouseEvent) => {
        // console.log("handleDblClickZoomView", event.time)
        this.props.handleGoToTime(event.time, false)
    }

    configurePeaks = (peakdata?: any, url?: string) => {
        try {
            if (url && this.audioplayer.current) {
                this.audioplayer.current.src = url
            }
        } catch (error) {
            console.log("error", error)
        }

        let options: PeaksOptions = {

            containers: {
                zoomview: this.zoomviewWaveformRef.current,
                overview: this.overviewContainer.current
            },

            createSegmentMarker: createSegmentMarker,
            createSegmentLabel: createSegmentLabel,

            mediaElement: this.audioplayer.current!,

            logger: console.error.bind(console),

            emitCueEvents: true,
            // playheadTextColor: '#aaa',

            // Colour for the in marker of segments
            segmentStartMarkerColor: PUDU_PRIMARY_COLOUR,
            segmentColor: PUDU_PRIMARY_COLOUR,

            // Colour for the out marker of segments
            segmentEndMarkerColor: PUDU_SECONDARY_COLOUR,

            // the color of a point marker
            pointMarkerColor: PUDU_SECONDARY_COLOUR,
            keyboard: false,

            axisLabelColor: '#000',
            playheadColor: 'rgba(0, 0, 0, 1)',
            showPlayheadTime: true,
            waveformData: {
                json: peakdata,
            },
            zoomWaveformColor: 'rgba(200, 200, 200, 1)',
            timeLabelPrecision: 3,
        };

        if (this.peaksInstance) {
            this.peaksInstance.destroy();
            this.peaksInstance = null;
        }

        Peaks.init(options, (err, peaksInstance) => {
            if (peaksInstance) {
                this.peaksInstance = peaksInstance;
                this.peaksInstance.on("player.timeupdate", this.handleTimeUpdate);
                this.peaksInstance.on("player.seeked", this.handlePlayerSeeked);
                this.peaksInstance.on("player.error", this.handlePlayerError);
                this.peaksInstance.on("player.canplay", this.handlePlayerReady);
                this.peaksInstance.on("segments.enter", this.onSegmentEntered);
                this.peaksInstance.on("segments.exit", this.onSegmentExit);
                // this.peaksInstance.on("segments.click", this.onSegmentClicked);
                this.peaksInstance.on("segments.dragend", this.onSegmentDragged);
                this.peaksInstance.on("zoom.update", this.handleZoomUpdate);
                this.peaksInstance.on("zoomview.dblclick", this.handleDblClickZoomView);
                this.peaksInstance.on("overview.click", this.handleClickOverView);
                this.peaksInstance.on("zoomview.click", this.handleClickZoomView);

                let view = this.peaksInstance?.views.getView("zoomview");

                let minPixelsWidthPerSecond = this.props.peakdata.sample_rate / this.props.peakdata.samples_per_pixel
                let minZoom = Math.floor((this.zoomviewWaveformRef.current?.clientWidth ?? 1) / minPixelsWidthPerSecond)

                this.setState({
                    currentZoom: minZoom,
                    samplesPerSecond: this.props.peakdata["samples_per_pixel"],
                    totalSamples: this.props.peakdata["length"]

                })

                if (this.state.duration) {
                    // view?.setZoom({ scale: peakdata["samples_per_pixel"] })
                    view?.setZoom({ seconds: 10 })
                }

                this.setupPeaksViews()
                this.addSegmentsToPeaks()
            }
        });
    }

    handleClickOverView = (event: WaveformViewMouseEvent) => {
        this.currentTime = event.time
        this.goToTime(event.time)
    }

    handleClickZoomView = (event: WaveformViewMouseEvent) => {
        this.currentTime = event.time
        this.goToTime(event.time)
    }

    handleKeyPressed = (e: KeyboardEvent) => {
        let jump = e.shiftKey ? 5 : 1

        if (e.key === 'Tab') {
            e.preventDefault();
            if (this.state.playing) {
                this.audioplayer.current?.pause()

                let request: UserLogEntryRequest = {
                    user_action: UserAction.tx_paused_audio,
                    description: "",
                    transcription_id: this.props.txid
                }
                API.logUserAction(request)

            } else {
                this.audioplayer.current?.play()

                let request: UserLogEntryRequest = {
                    user_action: UserAction.tx_played_audio,
                    description: "",
                    transcription_id: this.props.txid
                }
                API.logUserAction(request)
            }
        }
        else if (e.key === 'ArrowLeft') {
            let player = this.audioplayer.current;
            if (player !== null) {
                let currentTime = player.currentTime;
                player.currentTime = currentTime - jump;
            }
        }
        else if (e.key === 'ArrowRight') {
            let player = this.audioplayer.current;
            if (player !== null) {
                let currentTime = player.currentTime;
                player.currentTime = currentTime + jump;
            }
        }
        else if (e.key === 'ArrowUp') {
            if (this.audioplayer.current) {
                this.props.handleGoToPreviousWord(this.audioplayer.current.currentTime)
            }
        }
        else if (e.key === 'ArrowDown') {
            if (this.audioplayer.current) {
                this.props.handleGoToNextWord(this.audioplayer.current.currentTime)
            }
        }
    }

    goToTime = (time: number) => {
        if (this.audioplayer.current !== null && !this.segmentWasClicked) {
            this.audioplayer.current.currentTime = time
        }
    }

    roundFloat = (value: number) => {
        // round time due to float limitation in peaks
        return Math.floor(value * 1000000) / 1000000
    }

    binarySearchEditionByTime = (time: number) => {
        // console.log("binarySearchEditionByTime", time)
        if (this.props.peaksSegments) {
            let start = 0;
            let end = this.props.peaksSegments.length - 1;
            let middle = Math.floor((start + end) / 2);
            while (start <= end) {
                middle = Math.floor((start + end) / 2);
                let segment = this.props.peaksSegments[middle];
                if ((this.roundFloat(segment.startTime) <= time && time < this.roundFloat(segment.endTime))) {
                    // console.log("binarySearchEditionByTime match", middle, segment.startTime, segment.endTime, time)
                    return middle;
                } else if (segment.endTime < time) {
                    // console.log("binarySearchEditionByTime too low", middle, segment.startTime, segment.endTime, time)
                    start = middle + 1;
                } else {
                    // console.log("binarySearchEditionByTime too high", middle, segment.startTime, segment.endTime, time)
                    end = middle - 1;
                }
            }
            return middle;
        }
    }

    addSegmentsToPeaks = () => {
        if (this.peaksInstance) {
            if (this.currentSegment) {
                // Check if current within region
                if (this.currentSegment.startTime <= this.state.currentTime && this.state.currentTime < this.currentSegment.endTime) {
                    return
                }
            }
            this.peaksInstance.segments.removeAll()
            this.peaksInstance.points.removeAll()
            let timeHandles = 20 // # of  total segments to display
            let currentSegmentIndex = this.binarySearchEditionByTime(this.state.currentTime)
            if (currentSegmentIndex !== undefined) {
                if (this.props.peaksSegments !== undefined) {
                    this.currentSegment = this.props.peaksSegments[currentSegmentIndex];
                    let lowerLimit = Math.max(currentSegmentIndex - (timeHandles / 2), 0)
                    let upperLimit = Math.min(lowerLimit + timeHandles, this.props.peaksSegments.length)
                    let indexesToDisplay = Array.from({ length: upperLimit - lowerLimit }, (_, i) => i + lowerLimit)
                    indexesToDisplay.forEach(index => {
                        if (this.props.peaksSegments) {
                            if (index < this.props.peaksSegments.length) {
                                let segment = this.props.peaksSegments[index]

                                this.peaksInstance?.segments.add({
                                    update: this.handleUpdateSegment,
                                    startTime: segment.startTime,
                                    endTime: segment.endTime,
                                    editable: segment.editable ?? false,
                                    color: segment.color,
                                    labelText: segment.labelText,
                                    id: segment.id,
                                })
                            }
                        }
                    })
                }
            }
        }
    }

    zoom = (amount: number) => {

        let minPixelsWidthPerSecond = this.props.peakdata.sample_rate / this.props.peakdata.samples_per_pixel
        let potentialZoom = (this.zoomviewWaveformRef.current?.clientWidth ?? 1) / amount;

        if (potentialZoom <= minPixelsWidthPerSecond) {
            this.peaksInstance?.views.getView('zoomview')?.setZoom({ seconds: amount })
            this.setState({
                currentZoom: amount
            })
        }
    };

    zoomOut = () => {
        let newZoom = Math.min(this.state.currentZoom * 2, this.state.duration)
        this.peaksInstance?.views.getView('zoomview')?.setZoom({ seconds: newZoom })
        this.setState({
            currentZoom: newZoom
        })
        this.peaksInstance?.views.getView('zoomview')?.fitToContainer()
    }

    zoomIn = () => {
        let updatedZoom = Math.max(this.state.currentZoom / 2, 20);
        this.peaksInstance?.views.getView('zoomview')?.setZoom({ seconds: updatedZoom })
        this.setState({
            currentZoom: updatedZoom
        })
        this.peaksInstance?.views.getView('zoomview')?.fitToContainer()
    }

    render() {

        let zoom = this.state.duration ? this.state.currentZoom : 10;
        let minPixelsWidthPerSecond = this.props.peakdata.sample_rate / this.props.peakdata.samples_per_pixel
        let minZoom = Math.floor((this.zoomviewWaveformRef.current?.clientWidth ?? 1) / minPixelsWidthPerSecond)

        return (
            <div
                style={{
                    width: "100%",
                    height: "120px",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0
                    }}
                >
                    <div>
                        <div
                            style={{ height: "60px" }}
                            ref={this.zoomviewWaveformRef}
                            className="zoomview-container"
                        />
                        <div
                            ref={this.overviewContainer}
                            id="overview-waveform"
                            style={{ paddingTop: "4px", paddingBottom: "4px", height: "35px" }}
                        />
                        <div style={{ height: "25px", display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "space-between" }}>
                            <Button
                                type="primary"
                                style={{
                                    width: "80px",
                                    background: PUDU_SECONDARY_COLOUR,
                                    borderColor: PUDU_SECONDARY_COLOUR,
                                    color: "#fff",
                                }}
                                size="small"
                                onClick={() => {
                                    if (this.audioplayer.current?.paused) {
                                        this.audioplayer.current?.play()
                                    } else {
                                        this.audioplayer.current?.pause()
                                    }
                                }}
                            >
                                {this.state.playing ? <PauseOutlined /> : <CaretRightOutlined />}
                            </Button>

                            <div style={{ marginLeft: "20px", marginRight: "4px" }}>
                                <ZoomOutOutlined onClick={this.zoomOut} />
                            </div>

                            <Slider
                                style={{ display: "inline-block", minWidth: "200px" }}
                                min={minZoom}
                                reverse={true}
                                value={zoom}
                                max={this.state.duration}
                                onChange={(value: number) => this.zoom(Number(value))}
                            />
                            <div style={{ marginLeft: "4px", marginRight: "4px" }}>
                                <ZoomInOutlined onClick={this.zoomIn} />
                            </div>
                            <div style={{ marginLeft: "20px", marginRight: "4px" }}>
                                <SoundOutlined />
                            </div>

                            <Slider
                                style={{ display: "inline-block", minWidth: "200px", marginRight: "4px" }}
                                min={0}
                                defaultValue={0.9}
                                max={1}
                                step={0.01}
                                onChange={(value: number) =>
                                    this.audioplayer.current!.volume = Number(value)
                                }
                            />

                            <DashboardOutlined style={{ display: "inline-block", marginLeft: "4px", }} />
                            <Slider
                                style={{ display: "inline-block", minWidth: "200px", marginRight: "4px" }}
                                min={0.25}
                                defaultValue={1}
                                max={3}
                                step={0.25}
                                onChange={(value: number) => {
                                    if (this.audioplayer.current) {
                                        this.audioplayer.current.playbackRate = value
                                    }
                                }}
                            />
                            <div style={{ flexGrow: 1 }}></div>
                            <div style={{ paddingLeft: "20px", paddingRight: "20px", display: "inline", fontWeight: "bold" }}>
                                {TimeHelper.fancyTimeFormat(this.state.currentTime)}
                            </div>

                            <audio
                                ref={this.audioplayer}
                                id="audioplayer"
                                onDurationChange={(e) => { this.setState({ duration: e.currentTarget.duration }) }}
                                onPause={() => this.setState({ playing: false })}
                                onPlay={() => this.setState({ playing: true })}
                            >
                                <source type="audio/mpeg" />
                            </audio>

                        </div>
                    </div >
                </div >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        background: "rgba(255, 255, 255, 0.7)",
                        zIndex: 999
                    }}
                    hidden={!this.state.isBufferingAudio}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            color: "rgba(101, 2, 188, 1)",
                        }}
                    >
                        {/* <div
                            style={{ padding: "10px" }}
                        >
                            <Spin indicator={antIcon} />
                        </div> */}
                        {Strings.strings.loading}
                    </div>
                </div>
            </div >
        );
    }
}

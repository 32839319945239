import React from "react";

import { Layout } from "antd";
import Sidebar from "../components/layout/Sidebar";

import { RouteComponentProps, withRouter, Redirect } from "react-router";

import AuthenticatedRoute from "../routing/AuthenticatedRoute";

import { Switch, Route } from "react-router-dom";
import "./App.css";
import TranscriptionReview from "../views/TranscriptionReview";
import LoginComponent from "../views/LoginComponent";
import VerifyUserEmailComponent from "../views/VerifyUserEmailComponent";
import RecoverPasswordComponent from "../views/RecoverPasswordComponent";
import { UserProfile } from "../types/index";
import API from "../api/API";
import UserDashboardComponent from "../components/UserDashboard";
import UserManual from "../views/UserManual";
import Documentation from "../views/Documentation";

import AdminDashboardComponent from "../components/AdminDashboard";
import { TranscriptionUsersComponent } from "../components/TranscriptionUsersComponent";
import { ProjectUsersComponent } from "../components/ProjectUsersComponent";
import RecentBatchJobs from "../views/RecentBatchJobs";
import TeamworkComponent from "../views/TeamworkComponent";
import AccountConfiguration from "../views/AccountConfiguration";
import ClientDetailsComponent from "../components/ClientDetailComponent";

import { sessionService } from "../services";
import EditUserComponent from "../components/EditUserComponent";

import { store } from './store'
import { LoginStep } from "../types/enums"

import { updateStatusForBatchJob, updateStatusForMultipleBatchJobs } from "../features/UserTranscriptionsListSlice";
import { updateUserProfile } from "../features/UserProfileSlice"
import CreateRealtimeTranscription from "../views/CreateRealtimeTranscription";

interface AppProps extends RouteComponentProps<any>, React.Props<any> {
    history: any;
    location: any;
}

type AppState = {
    isAuthenticated: boolean;
    userProfile: UserProfile | null;
    token: string | undefined;
    emailIsValid: boolean;
    email: string | undefined;
};

class App extends React.Component<AppProps, AppState> {

    timeout: number = 250;
    websocket: WebSocket | undefined = undefined;

    pathsWithoutSideBar = [
        "/manual"
    ]

    constructor(props: AppProps) {
        super(props);

        let token = undefined;

        this.state = {
            isAuthenticated: sessionService.appSessionExists(),
            userProfile: null,
            token: token,
            emailIsValid: false,
            email: undefined
        };
    }

    componentDidMount() {
        if (this.state.isAuthenticated) {
            let token = sessionService.getSessionToken();
            this.doGetMe(token);
        }
    }


    websocketConnect = () => {

        var connectInterval: NodeJS.Timeout;

        if (this.websocket) {
            this.websocket.onopen = () => {
                console.log("ws ok")

                this.timeout = 250; // reset timer to 250 on open of websocket connection
                clearTimeout(connectInterval); // clear Interval on on open of websocket connection
            };

            this.websocket.onmessage = evt => {
                let jsonData = JSON.parse(evt.data);
                if (jsonData["msgtype"] === "BATCHJOBPROGRESS" && jsonData["batchjobstatus"]) {
                    store.dispatch(updateStatusForBatchJob(jsonData["batchjobstatus"]))
                } else if (jsonData["msgtype"] === "MULTIPLEBATCHJOBSPROGRESS" && jsonData["batchjobstatus"]) {
                    store.dispatch(updateStatusForMultipleBatchJobs(jsonData["batchjobstatus"]))
                }
            };

            this.websocket.onclose = e => {
                console.log(
                    `Socket is closed. Reconnect will be attempted in ${Math.min(
                        10000 / 1000,
                        (this.timeout + this.timeout) / 1000
                    )} second.`,
                    e.reason
                );

                this.timeout = this.timeout + this.timeout; //increment retry interval
                connectInterval = setTimeout(this.checkWebsocketConnection, Math.min(10000, this.timeout)); //call check function after timeout
            };

            this.websocket.onerror = err => {
                console.error("Socket encountered error: ", err.type, "Closing socket");
                this.websocket?.close();
            };
        } else {
            console.log("no websocket")
        }

    };

    doGetMe = (token: string) => {
        API.getCurrentUsersProfile(token).then((userProfile: UserProfile | null) => {
            if (userProfile) {
                store.dispatch(updateUserProfile(userProfile))
                this.setState({
                    isAuthenticated: true,
                    userProfile: userProfile
                });
                this.configureWebsocket()

            } else {
                this.setState({
                    isAuthenticated: false
                });
            }
        });
    };

    configureWebsocket = () => {
        let token = sessionService.getSessionToken()
        let wsEndpoint = `${process.env.REACT_APP_WS_API_PROTOCOL}${process.env.REACT_APP_API_HOST}/ws/app/${token}`;
        this.websocket = new WebSocket(wsEndpoint);
        this.websocketConnect()
    }

    checkWebsocketConnection = () => {
        console.log("WS CHECK");
        if (!this.websocket || this.websocket.readyState === WebSocket.CLOSED) {
            this.websocketConnect();
        }
    };

    handleLogin = (token: string) => {
        sessionService.startInAppSession(token);
        this.setState({
            isAuthenticated: true
        }, () => {
            this.doGetMe(token);
        });
    };

    handleRedirectVerify = (emailIsValid: boolean, email: string) => {
        this.setState({
            emailIsValid: emailIsValid,
            email: email
        });
    };

    logout = () => {
        sessionService.finishAppSession();
        this.setState(
            {
                isAuthenticated: false
            },
            () => {
                this.props.history.push("/");
            }
        );
    };

    handleRedirectToLogin = () => {
        this.props.history.push("/");
    }

    render() {

        let isAdmin = this.state.userProfile?.admin ?? false;

        let email = ""
        if (this.props.location.state) {
            if (this.props.location.state.email) {
                email = this.props.location.state.email;
            }
        }

        return (
            <div
                style={{
                    marginBottom: "-30px"
                }}
            >
                <Layout
                    style={{
                        height: "100vh",
                        overflow: 'hidden',
                    }}
                >

                    {this.state.isAuthenticated && !this.pathsWithoutSideBar.includes(this.props.location.pathname) && (
                        <Sidebar
                            logoutHandler={this.logout}
                            isAdmin={isAdmin}
                            username={this.state.userProfile?.username ?? ""}
                            canRT={(this.state.userProfile?.admin || this.state.userProfile?.can_realtime) ?? false}
                        />
                    )}

                    <div
                        style={{
                            marginLeft: this.state.isAuthenticated ? "40px" : "0px",
                            width: "100%"
                        }}
                    >

                        <Switch>

                            <AuthenticatedRoute
                                path={"/info/batch/recent"}
                                isAuthenticated={this.state.isAuthenticated}
                                //@ts-ignore
                                userProfile={this.state.userProfile}
                                //@ts-ignore
                                token={sessionService.getSessionToken()}
                                component={RecentBatchJobs}
                            />
                            <AuthenticatedRoute
                                path={"/account-config"}
                                isAuthenticated={this.state.isAuthenticated}
                                //@ts-ignore
                                userProfile={this.state.userProfile}
                                //@ts-ignore
                                component={AccountConfiguration}
                            />
                            <AuthenticatedRoute
                                path="/docs"
                                component={Documentation}
                                //@ts-ignore
                                isAuthenticated={this.state.isAuthenticated}
                            />
                            {isAdmin &&
                                <AuthenticatedRoute
                                    path="/users/profile/:id"
                                    component={EditUserComponent}
                                    //@ts-ignore
                                    isAuthenticated={this.state.isAuthenticated}
                                />
                            }
                            {isAdmin &&
                                <AuthenticatedRoute
                                    path="/users/transcription/:id"
                                    component={TranscriptionUsersComponent}
                                    //@ts-ignore
                                    isAuthenticated={this.state.isAuthenticated}
                                />
                            }
                            {isAdmin &&
                                <AuthenticatedRoute
                                    path="/project/:id/users"
                                    component={ProjectUsersComponent}
                                    //@ts-ignore
                                    isAuthenticated={this.state.isAuthenticated}
                                />
                            }
                            <AuthenticatedRoute
                                component={TranscriptionReview}
                                isAuthenticated={this.state.isAuthenticated}
                                //@ts-ignore
                                userProfile={this.state.userProfile}
                                path="/transcription/:id"
                            />
                            {isAdmin &&
                                <AuthenticatedRoute
                                    component={AdminDashboardComponent}
                                    isAuthenticated={this.state.isAuthenticated}
                                    path="/admin/:tab?"
                                />
                            }
                            {isAdmin &&
                                <AuthenticatedRoute
                                    component={ClientDetailsComponent}
                                    isAuthenticated={this.state.isAuthenticated}
                                    path="/clients/profile/:id"
                                />
                            }
                            {isAdmin &&
                                <AuthenticatedRoute
                                    component={CreateRealtimeTranscription}
                                    isAuthenticated={this.state.isAuthenticated}
                                    path="/realtime"
                                />
                            }
                            {isAdmin &&
                                <AuthenticatedRoute
                                    component={TeamworkComponent}
                                    isAuthenticated={this.state.isAuthenticated}
                                    path="/teamwork"
                                />
                            }
                            <Route
                                render={() => (
                                    <VerifyUserEmailComponent
                                        isAuthenticated={this.state.isAuthenticated}
                                        handleLogin={this.handleLogin}
                                        emailIsValid={this.state.emailIsValid}
                                        email={this.state.email ?? ""}
                                    />
                                )}
                                path="/verify/email"
                                exact={true}
                            />

                            <Route
                                path="/manual"
                                exact={true}
                                render={() => {
                                    return (
                                        <UserManual />
                                    )
                                }}
                            />
                            <Route
                                path="/user/password/recover/:token"
                                exact={true}
                                render={(p) => {
                                    return (
                                        <RecoverPasswordComponent
                                            handleRedirectToLogin={this.handleRedirectToLogin}
                                            recoverPasswordToken={p.match.params.token}
                                        />
                                    )
                                }}
                            />
                            <Route
                                path="/"
                                exact={true}
                                render={() => {
                                    if (this.state.isAuthenticated && this.state.userProfile) {
                                        return <UserDashboardComponent
                                            userProfile={this.state.userProfile}
                                            token={sessionService.getSessionToken()}
                                            handleCheckStatusForBatchJobs={ids => {
                                                let data = JSON.stringify({
                                                    "msgtype": "batchJobsStatus",
                                                    "batchJobIds": ids
                                                })
                                                if (this.websocket) {
                                                    if (this.websocket.readyState === WebSocket.OPEN) {
                                                        // console.log("websocket:", this.websocket?.readyState, " - ok",)
                                                        this.websocket.send(data)
                                                    } else if (this.websocket.readyState !== WebSocket.CONNECTING) {
                                                        console.log("websocket:", this.websocket?.readyState, " - will attempt refresh",)
                                                        this.websocketConnect()
                                                    } else {
                                                        console.log("websocket: weird state", this.websocket?.readyState, " - connecting",)
                                                    }
                                                } else {
                                                    console.log("websocket connecting")
                                                    this.configureWebsocket()
                                                }
                                            }}

                                        />
                                    } else {
                                        return (
                                            <LoginComponent
                                                handleLogin={this.handleLogin}
                                                isAuthenticated={this.state.isAuthenticated}
                                                email={email}
                                                recoverPasswordToken={undefined}
                                                loginStep={LoginStep.email}
                                                handleRedirectVerify={this.handleRedirectVerify}
                                                handleRedirectToLogin={this.handleRedirectToLogin}
                                            />
                                        )
                                    }
                                }}
                            />

                            <Route
                                path="/*"
                                exact={true}
                                render={() => {
                                    return (
                                        <Redirect from="*" to="/" />
                                    )
                                }}
                            />
                        </Switch>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default withRouter(App);
